app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('zh-hant', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',
      'languages.id': 'bahasa Indonesia',

      'recaptcha.language': 'zh-TW',
      'paypal.language': 'zh_TW',
      'form.action.delete': '刪除',
      close: '關閉',
      back: '返回',

      'form.validation.required': '{{field_name}} 是必須的',
      'form.validation.maxlength': '{{field_name}} 太長',
      'form.validation.maxlength.with.number':
        '{{field_name}}最多{{ max_length }}個字元',
      'form.validation.minlength': '{{field_name}} 太短',
      'form.validation.invalid': '{{field_name}} 無效',
      'form.validation.pattern': '{{field_name}} 無效',
      'form.validation.pattern.recipient-name':
        '不允許輸入空白與符號，或超過 {{limit_length}} 個字',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} 限制輸入10碼數字',
      'form.validation.pattern.recipient-phone.fix_size':
        '收件人電話號碼 限制輸入 {{ max_count }} 碼數字',
      'form.validation.pattern.recipient-phone.above_size':
        '收件人電話號碼 限制輸入 {{ max_count }} 碼（含）以上數字',
      'form.validation.pattern.recipient-phone.beginning_stint':
        '收件人電話號碼 限制開頭須為 {{ stint_charts.split(",").join(" 或 ") }}',
      'form.validation.pattern.711_cross_border.delivery_address':
        '由於送貨方式為跨境服務，必須以英文填寫。',
      'form.validation.email': '{{field_name}} 無效',
      'form.validation.id_no': '提醒：若未填寫身分證等實名資訊，可能影響報關',
      'form.validation.passport':
        '提醒：若未填寫護照號碼等實名資訊，可能影響報關',
      'form.validation.unique': '{{field_name}} 已被選用，請重新輸入。',
      'form.validation.confirmation': '密碼不一樣',
      'form.validation.policy': '你必須同意細則和私隱條款',
      'form.validation.delivery_option.outlying':
        '您所選擇的送貨方式無法運送至離島地區，請更換台灣本島地址或選擇其他送貨方式。',
      'form.validation.delivery_option.local':
        '您所選擇的送貨方式無法運送至台灣本島地區，請更換成離島地址或選擇其他送貨方式。',
      'form.validation.delivery_option.country':
        '您所選擇的送貨方式無法運送至該國家/地區，請更換送貨地址或選擇其他送貨方式。',
      'form.validation.delivery_option.region':
        '您所選擇的送貨方式無法運送至該地區，請更換地址或選擇其他送貨方式。',
      'form.validation.delivery_option.cross_border.MY':
        '您所選擇的送貨方式無法運送至{{ area == "west" ? "西" : "東" }}馬，請更換成{{ area == "west" ? "東" : "西" }}馬地址或選擇其他送貨方式。',
      'form.validation.birthday.required': '生日日期 是必須的',
      'form.validation.birthday.invalid': '生日日期 無效',
      'form.validation.birthday.invalid.minimum_age_limit':
        '生日日期無效，需大於 {{minimumAgeLimit}} 歲',
      'form.response.load.more.error':
        'Unable to load the data, please try again.',
      'form.validation.email.duplicate': '電郵 已被選用，請重新輸入。',
      'form.validation.email.hint': '請輸入正確的電郵地址',
      'form.validation.card.bin.error':
        '您所選的付款方式不適用於此信用卡，如有需要請聯絡店主。',
      'form.validation.card.bin.error.unknow_scheme':
        '我們無法判斷你的信用卡類別，如有需要請聯絡店主。',
      'form.validation.calling_code': '國碼 是必須的',
      'form.validation.phone.required': '{{field_name}} 是必須的',
      'form.validation.phone.error': '{{field_name}} 無效，請重新確認',
      'form.validation.mobile.error': '{{field_name}} 無效，請輸入手機號碼',
      'dropdown.hint': '請選擇',
      'redirect.go_home_in_seconds': '{{seconds}}秒後回到首頁',

      'trial_order_dialog.title':
        '歡迎體驗結帳流程，全店最多能成立 10 張測試訂單',
      'trial_order_dialog.content':
        '流程提供：加入會員、訂閱優惠通知、購物車加購品等強大功能。達訂單上限後，商家可以透過升級開通訂單限制。',
      'trial_order_dialog.checkout': '開始結帳',
      'trial_order_dialog.reach_limit.title': '已達試營運下單限制',
      'trial_order_dialog.reach_limit.content':
        '待店家正式營運即可正式營運即可繼續購物與結帳！<br/>若對商店營運有任何疑問，歡迎聯絡店家。',
      'trial_order_dialog.reach_limit.check_plan': '回首頁繼續逛逛',
      'trial_order_dialog.reach_limit.continue': '聯絡店家',

      'session.signup.title': '新用戶註冊',
      'session.terms.facebook': '使用 Facebook 註冊',
      'session.signup.facebook': 'Facebook 登記',
      'sessions.or': '或',
      'session.terms': '登記／註冊表示你同意我們的私隱政策，使用條款及細則。',
      'session.signin.submit': '登入',
      'session.signin.title': '登入',
      'session.signin.facebook': 'Facebook 登入',
      'session.forgot_password': '忘記密碼',
      'session.mobile_signup.go_back': '上一頁',
      'session.mobile_signup.check_mobile.title':
        '請確認您的手機號碼是否輸入正確，我們將傳送簡訊到以下手機號碼',
      'session.mobile_signup.check_mobile.your_number': '您的手機號碼',
      'session.mobile_signup.check_mobile.your_number.hint':
        '請輸入台灣手機號碼 (09開頭)',
      'session.mobile_signup.check_mobile.send_code': '寄發驗證碼',
      'session.mobile_signup.check_mobile.go_back.1': '若手機號碼有誤，請回',
      'session.mobile_signup.check_mobile.go_back.2': '上一步',
      'session.mobile_signup.check_mobile.go_back.3': '修改',
      'session.mobile_signup.check_mobile.go_back.3.simplified': '修改',
      'session.mobile_signup.input_code.hint': '請輸入收到的簡訊驗證碼',
      'session.mobile_signup.input_code.hint.simplified':
        '請輸入您收到的驗證碼',
      'session.mobile_signup.input_code.placeholder': '驗證碼',
      'session.mobile_signup.input_code.submit': '送出',
      'session.mobile_signup.input_code.resend.countdown':
        '重發驗證碼 ({{resendCountdown}}秒)',
      'session.mobile_signup.input_code.resend.countdown.simplified':
        '重新發送({{resendCountdown}}秒）',
      'session.mobile_signup.input_code.resend': '重發驗證碼',
      'session.mobile_signup.input_code.resend.simplified': '重新發送',
      'session.mobile_signup.recaptcha.load.failure':
        'Google reCAPTCHA 載入失敗，請確認網路連線穩定後重新啟動應用程式。',
      'session.mobile_signup.recaptcha.token.failure':
        '系統發生錯誤，請確認網路連線穩定後重新啟動應用程式。',
      'session.forget_passowrd.check_phone.error': '手機號碼無效，請重新確認',
      'session.mobile_center.check_phone.error': '此手機號碼已註冊過',
      'user.sign_up.email_verification.dialog.title': '驗證信已寄至 {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        '請立即完成收信驗證，即可更新電郵！若沒收到，請再次更改電郵並儲存。',
      'user.sign_up.email_verification.dialog.confirm': '確定',
      'user.sign_up.check_phone.error':
        '此為現有手機號碼，請重新填寫需要更換的號碼。',

      'user.quick_signup.title': '加入會員',
      'user.quick_signup.title.content': '不錯過專屬優惠！',
      'user.quick_signup.promotion': '我想收到最新資訊及優惠方案',
      'user.quick_signup.policy': '我同意網站',
      'user.quick_signup.policy.url_content': '服務條款及隱私政策',
      'user.quick_signup.policy_with_age':
        '我同意網站<a href="{{ link }}">服務條款及隱私政策</a>，且我已滿 {{ age }} 歲',
      'user.quick_signup.submit': '立即加入!',
      'user.quick_signup.error.format': '手機格式錯誤',
      'user.quick_signup.error.confirmed': '此手機號碼已註冊過，',
      'user.quick_signup.error.confirmed.url': '前往登入',
      'user.quick_signup.validate.title': '繼續註冊',
      'user.quick_signup.validate.success':
        '已加入會員！<br/>您可以接著完成註冊，<br/>或',
      'user.quick_signup.validate.success.shop': '先去逛逛',
      'user.quick_signup.validate.finish_hint':
        '完成註冊即可登入帳戶查看或使用購物金',
      'user.quick_signup.validate.phone': '驗證',
      'user.quick_signup.validate.send.sms': '寄發驗證簡訊',
      'user.quick_signup.validate.wrong.number': '若手機號碼有誤，請回',
      'user.quick_signup.validate.previous': '上一步',
      'user.quick_signup.validate.modify': '修改',
      'user.quick_signup.validate.hint.password': '至少8個字元',
      'user.quick_signup.validate.hint.send':
        '簡訊已寄出，{{count}}秒後可重發驗證碼',
      'user.quick_signup.validate.hint.resend': '沒收到簡訊？點擊重發驗證碼',
      'user.quick_signup.validate.submit': '完成註冊',
      'user.quick_signup.validate.placeholder.sms': '輸入驗證碼',
      'user.quick_signup.validate.placeholder.name': '姓名',
      'user.quick_signup.validate.placeholder.password': '設定密碼',
      'user.delivery_data.recipient_phone': '收件人電話號碼',
      'user.add_credit_card': '新增信用卡',
      'user.add_credit_card.success': '卡片新增成功',
      'user.add_credit_card.fail': '卡片新增失敗，請重新嘗試',
      'user.add_credit_card.cancel': '取消',
      'user.add_credit_card.remind':
        '提醒：新增信用卡時，SHOPLINE Payments 將會取得一次 {{ label }} 元的授權紀錄，此筆授權紀錄不會請款。',
      'user.add_credit_card.submit': '提交',
      'user.credit_card.system.error': '系統發生異常，請稍後再試',
      'user.delete_credit_card': '您確定要刪除此張卡片？',
      'user.delete_credit_card.description':
        '刪除此信用卡後將無法使用此卡快速支付',
      'user.delete_credit_card.description.with_subscription_product':
        '刪除此信用卡後將無法使用此卡快速支付。若您曾用此張卡片購買定期購商品，產生下期訂單時可能導致付款失敗，屆時請至會員中心 > 訂單頁重新付款。',
      'user.delete_credit_card.success': '卡片刪除成功',
      'user.delete_credit_card.fail': '刪除失敗，請重新嘗試',
      'user.delete_credit_card.cancel': '取消',
      'user.delete_credit_card.delete': '確定刪除',
      'user.credit_card.expired': '卡片已過期',
      'user.credit_card_list.delete': '刪除',
      'user.credit_card_list.processing': '新增中',

      'product.out_of_stock': '現在缺貨',
      'product.addon_products.label': '加購品',
      'product.bundled_products.label': '任選優惠',
      'product.bundle_group_products.label': 'A+B 組合',
      'product.buyandget.label': '買 X 享 Y',
      'product.gift.label': '贈品',
      'product.subscription_product.label': '定期購',
      'product.addon_products.errors.main_product_out_of_stock':
        '主商品庫存量不足',
      'product.addon_products.errors.reached_max_purchase_quantity':
        '商品購買上限為 {{ message }} 件',
      'product.addon_products.errors.addon_product_out_of_stock':
        '加購品庫存量不足',
      'product.addon_products.errors.larger_addon_product':
        '購買的加購品數量不可大於主商品',
      'product.addon_products.errors.out_of_stock': '{{title}} 庫存量不足',
      'product.addon_products.tips.limit_exceed':
        '調整商品數量時，你所選購的加購品數量不可大於主商品數量。',
      'product.addon_products.add_to_cart.hint': '已經成功加入到購物車',

      'product.available_time_info.start.1': '商品將於 ',
      'product.available_time_info.start.2': ' 開始販售',
      'product.available_time_info.end.1': '商品將於 ',
      'product.available_time_info.end.2': ' 結束販售',

      'product.view_more': '查看更多',
      'product.view_less': '收起内容',

      'product.add_to_cart': '加入購物車',
      'product.add_to_cart_fail': '您的購物車已滿！',
      'product.buy_now': '立即購買',
      'product.buy_now.preorder.hint': '商品預購中',
      'product.buy_together': '一同購買',
      'product.preorder_limit.hint': '購買數量包含現貨及預購商品',
      'product.coming_soon': '即將開賣',
      'product.available_time_over': '販售結束',
      'product.store_stock.check_stock': '查看可取貨門市',
      'product.set.open_variation': '查看商品詳情',

      'product.amazon_product_review.show_more': '查看更多',

      'product_review_comments.orders.back_to_my_account': '< 返回會員中心',
      'product_review_comments.orders.comment': '內容',
      'product_review_comments.orders.comment_placeholder':
        '（選填）滿意商品？跟我們分享你的評價！',
      'product_review_comments.orders.orders_to_view': '待評價訂單',
      'product_review_comments.orders.order_id': '訂單編號:',
      'product_review_comments.orders.purchased_date': '購買日期:',
      'product_review_comments.orders.rate_other_order': '評價其他訂單',
      'product_review_comments.orders.review': '評價',
      'product_review_comments.orders.send': '發佈',
      'product_review_comments.orders.empty.title': '沒有可評價商品',
      'product_review_comments.orders.finished_review.title': '感謝您的評價！',
      'product_review_comments.orders.finished_review.desc.user_credit':
        '恭喜你，稍候即會獲得評價獎賞 {{ value }} 購物金。',
      'product_review_comments.orders.finished_review.desc.member_point':
        '恭喜你，稍候即會獲得評價獎賞 {{ value }} 點數。',
      'product_review_comments.orders.max_length_of_characters': '2000字以內',
      'product_review_comments.orders.write_a_review': '給予評價',
      'product_review_comments.orders.upload_image': '上傳圖片（最多 9 張）',
      'product_review_comments.orders.completed_all_reviews':
        '恭喜！已完成所有評價！',
      'product_review_comments.orders.back_to_the_store': '前往購物',

      'product.product_review.out_of_5_stars': '分',
      'product.product_review.rating_source_amazon': '評價來源：Amazon',
      'product.product_review.rating_source_shopline': '評價來源：此店',
      'product.product_review.reviews': '個評價',
      'product.product_review.stars': '分',
      'product.product_review.no_review': '尚未有任何評價',
      'product_review_entry.review_description_title': '我們在等待你的評價！',
      'product_review_entry.review_description_title.user_credit':
        '我們在等待你的評價！評價後立刻獲得 {{ value }} 購物金！',
      'product_review_entry.review_description_title.member_point':
        '我們在等待你的評價！評價後立刻獲得 {{ value }} 點數！',
      'product_review_entry.review_description_content':
        '喜歡之前購買的商品嗎？給我們一個好評吧！',
      'product_review_entry.review_button_text': '評價',

      'member.center.load.more': '載入更多',
      'member.center.load.more.error': '未能載入資料，請重新再試。',

      'wishlist.signin_register': '立即登入/註冊',
      'wishlist.messages.remove_error': '無法成功刪除追蹤商品。',
      'back_in_stock.subscibe.success.message': '當商品重新上架時，會發送通知',
      'back_in_stock.added': '已訂閱貨到通知',
      'back_in_stock.avaiable': '貨到通知我',

      orders: '訂單',
      order: '訂單',
      'orders.fields.billing_address': '賬單地址',
      'orders.fields.order_number': '訂單號碼',
      'orders.fields.order_date': '訂單日期',
      'orders.fields.order_status': '訂單狀態',
      'orders.fields.order_remarks': '顧客訂單備註',
      'orders.fields.customer_name': '顧客名稱',
      'orders.fields.product_subscription_period': '第{{period}}期',
      'orders.fields.status.temp': '訂單處理中',
      'orders.fields.status.pending': '訂單處理中',
      'orders.fields.status.confirmed': '已確認',
      'orders.fields.status.shipped': '已發貨',
      'orders.fields.status.completed': '已完成',
      'orders.fields.status.received': '已收貨',
      'orders.fields.status.cancelled': '已取消',
      'orders.fields.status.returning': '退貨中',
      'orders.fields.status.returned': '已退貨',
      'orders.fields.customer': '顧客',
      'orders.fields.preorder_item': '預購商品',
      'orders.fields.customer.phone': '顧客電話號碼',
      'orders.fields.billto': '賬單',
      'orders.fields.payment_type': '付款方式',
      'orders.fields.payment_instructions': '付款指示',

      'orders.fields.order_delivery.pending': '備貨中',
      'orders.fields.order_delivery.shipping': '發貨中',
      'orders.fields.order_delivery.shipped': '已發貨',
      'orders.fields.order_delivery.arrived': '已到達',
      'orders.fields.order_delivery.collected': '已取貨',
      'orders.fields.order_delivery.returning': '退貨中',
      'orders.fields.order_delivery.returned': '已退貨',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        '交易失敗：超商代碼的最高收款金額為NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        '交易失敗：超商代碼的最低收款金額為NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        '交易失敗：超商條碼的最高收款金額為NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        '交易失敗：超商條碼的最低收款金額為NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        '超商代碼單筆收款上限為30,000新台幣',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        '超商條碼單筆收款上限為20,000新台幣',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        '網路ATM單筆收款上限為30,000新台幣',
      'orders.fields.delivery_method': '送貨方式',
      'orders.fields.delivery_description': '送貨方式指示',
      'orders.fields.delivery_fee': '運費',
      'orders.fields.delivery_address': '送貨地址',
      'orders.fields.delivery_address.remarks': '送貨備註',
      'orders.fields.options.blacklist.error':
        '您的商品不適用於此送貨方式或付款方式，請再查看商品資訊，選擇其他選項或分開下單。',
      'address.fields.address': '地址',
      'address.fields.city': '城市',
      'address.fields.postcode': '郵政編號',
      'address.fields.address.state': '地區/州/省份',
      'address.fields.country': '國家/地區',

      'orders.fields.subtotal': '小計',
      'orders.fields.total': '合計',
      'order.billing_address.same': '與送貨地址相同',

      'orders.coupons.label': '優惠券代碼',
      'orders.coupons': '恭喜！你可以享用以下優惠：',
      'orders.coupons.placeholder': '請輸入優惠券代碼',
      'orders.promotion_coupons.placeholder': '請輸入優惠代碼',

      'orders.coupons.apply': '使用',
      'orders.fields.order_discount': '折扣',
      'orders.fields.order_custom_discount': '自訂折扣合計',
      'orders.coupons.invalid': '對不起，你的優惠卷不適用。',
      'orders.coupons.validation.minamount':
        '對不起, 你的訂單未達到最低消費，優惠卷不適用。',
      'orders.coupons.invalid.order': '結帳前請先刪除無效的優惠卷或代碼',

      'orders.actions.title': '管理訂單狀態',
      'orders.action.cancel': '取消訂單',
      'orders.action.confirm': '確認訂單',
      'orders.action.ship': '標示為已發貨',
      'orders.general.title': '訂單資料',
      'orders.payment.title': '付款資料',
      'orders.delivery.title': '送貨／物流資料',
      'orders.products.title': '商品詳情',

      'orders.fields.accept_terms': '我同意細則和私隱條款',
      'orders.fields.accept_terms.validation.required':
        '你必須同意細則和私隱條款',
      'orders.show.message.thankyou.title': '謝謝你的訂單',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': '繼續購物！',
      'orders.show.message.shipped.title': '你的訂單已經寄出',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': '訂單金額',
      'orders.payment_slip.order_info.instructions': '付款說明',
      'orders.payment_slip.order_info.read_more': '查看完整說明',
      'orders.payment_slip.upload_form.title': '完成轉帳後，請上傳轉帳明細',
      'orders.payment_slip.upload_form.hint':
        '確認款項後，我們將盡速安排發貨！',
      'orders.payment_slip.upload_form.notification':
        '想取得最新訂單資訊，請訂閱',
      'orders.payment_slip.upload_form.image_input.title': '上傳轉帳明細',
      'orders.payment_slip.upload_form.image_input.description':
        '請確認圖片清晰，並包含轉帳後五碼、轉帳時間、轉帳金額',
      'orders.payment_slip.upload_form.image_input.change': '點擊更換照片',
      'orders.payment_slip.upload_form.image_input.error': '圖片需小於 10MB',
      'orders.payment_slip.upload_form.paid_time': '選擇付款時間',
      'orders.payment_slip.upload_form.message': '填寫付款備註',
      'orders.payment_slip.upload_form.message.error':
        '長度不可超過 {{ n }} 字',
      'orders.payment_slip.uploaded.alert':
        '謝謝您，已上傳轉帳明細，我們將儘速確認！',
      'orders.payment_slip.uploaded.notification': '訂閱獲得最新訂單資訊',
      'orders.payment_slip.uploaded.title': '以下是您的轉帳明細',
      'orders.payment_slip.uploaded.paid_time': '付款時間:',
      'orders.payment_slip.uploaded.upload_time': '上傳明細時間:',
      'orders.payment_slip.uploaded.order_link': '查看訂單',
      'orders.payment_slip.uploaded_without_login.title':
        '謝謝您！此訂單已上傳付款明細',
      'orders.payment_slip.uploaded_without_login.hint': '您可以登入查看明細',
      'orders.payment_slip.footer':
        '您填寫的付款資訊僅用於訂單對帳並經過加密技術，敬請安心付款。',

      // customer cancel order section
      'orders.show.check_order.title': '查看訂單',
      'orders.show.check_order.description':
        '您可以到 <a href="{{pagePath}}">會員中心 > 訂單</a> 裡查看或取消訂單',
      'orders.show.customer_cancel_order.confirm.title': '您確定要取消訂單嗎？',
      'orders.show.customer_cancel_order.confirm.description.integrated':
        '請告訴我們取消的原因，我們會努力做得更好！<br>成功取消後將會進行退款。期待您再度光臨!',
      'orders.show.customer_cancel_order.confirm.description.non_integrated':
        '請告訴我們取消的原因，我們會努力做得更好！<br>敬請耐心等候，我們將盡快處理。',
      'orders.show.customer_cancel_order.confirm.loading.title': '正在處理',
      'orders.show.customer_cancel_order.confirm.loading.description':
        '請耐心等候，不要關閉頁面或返回上一頁',
      'orders.show.customer_cancel_order.confirm.success.title':
        '取消申請已送出',
      'orders.show.customer_cancel_order.confirm.success.description':
        '取消成功後將寄電郵通知，謝謝您！',
      'orders.show.customer_cancel_order.confirm.failed.title':
        '取消訂單失敗，請再試一次',
      'orders.show.customer_cancel_order.confirm.failed.description':
        '若重試失敗，請於下方的「賣家和顧客訂單通訊」與我們聯絡。',
      'orders.show.customer_cancel_order.button.cancel': '取消訂單',
      'orders.show.customer_cancel_order.button.ok': '確定',
      // customer cancel order reasons
      'orders.show.customer_cancel_order.reasons.default': '請選擇取消原因',
      'orders.show.customer_cancel_order.reasons.change_mind': '改變心意',
      'orders.show.customer_cancel_order.reasons.expensive_price':
        '價格比其他地方貴',
      'orders.show.customer_cancel_order.reasons.repeat_purchase': '重複購買',
      'orders.show.customer_cancel_order.reasons.wait_too_long': '出貨太慢',
      'orders.show.customer_cancel_order.reasons.change_item': '想變更購買項目',
      'orders.show.customer_cancel_order.reasons.incorrect_customer_info':
        '送貨或付款資訊填寫錯誤',
      'orders.show.customer_cancel_order.reasons.other': '其他原因',
      'orders.show.customer_cancel_order.reasons.other.placeholder':
        '請告訴我們原因，讓我們下次做得更好！',
      'orders.show.customer_cancel_order.reasons.hint': '取消原因',

      'orders.index.no_records.title': '你沒有任何訂單',
      'orders.index.no_records.description': '你在這個商店沒有訂單。',
      'orders.index.no_records.action': '繼續購物！',
      'orders.index.title': '訂單',
      'orders.index.action.view': '查閱',
      'orders.action.checkout': '前往結帳',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        '收件人',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        '7-11 門市名稱',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        '7-11 店號',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        '收件人',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        '全家服務編號 (不是店舖號)',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        '全家門市名稱',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        '7-11',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        '全家',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label': '門市',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        '送貨方式發生錯誤，請重新整理或<a href="/cart" class="alert-highlight">返回上一頁更換送貨方式</a>',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        '地址發生錯誤，請重新選擇',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        '配送編號門市是必須的',

      'checkout_object.detail.close': '關閉',
      'checkout_object.detail.title': '消費金額明細',

      'orders.promotion_type.promotion': '優惠促銷',
      'orders.promotion_type.reward_credit': '滿額送購物金',
      'orders.promotion_type.bundle_pricing': '任選優惠',
      'orders.promotion_type.bundle_percentage': '任選優惠',
      'orders.promotion_type.bundle_amount': '任選優惠',
      'orders.promotion_type.bundle_gift': '任選優惠',
      'orders.promotion_type.bundle_group': 'A+B 組合',
      'orders.promotion_type.bundle_group_percentage': 'A+B 組合',
      'orders.promotion_type.bundle_group_amount': 'A+B 組合',
      'orders.promotion_type.bundle_group_gift': 'A+B 組合',
      'orders.promotion_type.buyandget_free': '買Ｘ享Ｙ',
      'orders.promotion_type.buyandget_pricing': '買Ｘ享Ｙ',
      'orders.promotion_type.buyandget_percentage': '買Ｘ享Ｙ',
      'orders.promotion_type.coupon': '優惠代碼',
      'orders.promotion_type.membership_offer': '會員優惠',
      'orders.promotion_type.custom_discount': '自訂折扣',
      'orders.promotion_type.member_point_redeem_gift': '兌換活動',
      'orders.promotion_type.free_shipping': '免運優惠',
      'orders.promotion_type.credit_reward': '購物金回饋',

      'orders.reward_credit.value': '{{ credit_value }} 購物金',
      'orders.reward_credit.auto_reward':
        '滿 {{ credit_threshold }} 送 {{ credit_value }} 購物金',
      'orders.reward_credit.accumulated_auto_reward':
        '每 {{ credit_threshold }} 送 {{ credit_value }} 購物金',
      'orders.reward_credit.balance': '還差 {{ auto_reward_balance }}',
      'orders.reward_credit.faq':
        '贈送的購物金將在付款完成後獲得，可在下次購物使用！',
      'orders.reward_credit.earned_after_order_completed_faq':
        '提醒您此活動以商品折扣後金額計算回饋，訂單完成後即可獲得購物金，並於下次購物時使用！',

      'orders.fields.applied_user_credits': '折抵購物金',
      'orders.fields.applied_member_point_redeem_to_cash': '使用點數折現',
      'orders.fields.delivery_fee_free': '免運',
      'orders.fields.remaining_member_points_without_balance':
        '本次使用 {{ applied }} 點',
      'orders.fields.user_credits': '購物金',
      'orders.fields.rounding': '進位/捨去',
      'orders.fields.credits_earned_after_paid': '付款後獲得購物金',

      'orders.member_point.fields.redeemed': '點數兌換贈品',
      'orders.member_point.unit': '點',
      'orders.member_point.settled_after_order_completed': '訂單完成後獲得點數',
      'orders.member_point.pos_settled_content':
        '實體店訂單完成後約 {{ pending_days }} 天獲得點數',
      'orders.member_point.settled_after_content':
        '取貨後 {{ pending_days }} 天獲得點數',

      // Checkout page error message field name
      'order.customer_name': '顧客名稱',
      'order.email': '電子信箱',
      'order.customer_email': '顧客電郵',
      'order.customer_phone': '顧客電話號碼',
      'order.delivery_data.recipient_name': '收件人名稱',
      'order.delivery_data.recipient_phone': '收件人電話號碼',
      'order.delivery_data.consignee_id_no': '收件人身份證字號或護照號碼',
      'order.delivery_address.address_1': '地址',
      'order.delivery_address.city': '城市/市鎮',
      'order.delivery_address.postcode': '郵遞區號',
      'order.delivery_address.state': '地區/州/省份',
      'order.delivery_data.location_code': '門市編號',
      'order.delivery_data.location_name': '門市名稱',
      'order.delivery_data.time_slot': '送貨時間',
      'order.delivery_data.scheduled_delivery_date': '選擇指定到貨日期',
      'order.delivery_data.time_slot_key': '選擇指定到貨時段',
      'order.invoice.carrier_number': '條碼',
      'order.invoice.mailing_address': '發票地址',
      'order.invoice.tax_id': '統一編號',
      'order.payment_data.holdername': '持卡人名字 ',
      'order.payment_data.expiry_date': '有效日期',
      'order.payment_data.cvc': '安全碼(CVC)',
      'order.payment_data.credit_card_number': '信用卡號碼',
      'order.billing_address.title': '帳單寄送地址',

      'lock_inventory.locked_quantity_unit': '件',

      'delivery.sfexpress.district': '順豐地區',
      'delivery.sfexpress.store': '順豐地址',
      'delivery.sfexpress.locker_location': '智能櫃地區',
      'delivery.sfexpress.ef_locker': '順便智能櫃',
      'delivery_address.region.hk': '地區',
      'delivery_address.district.hk': '區域',
      'delivery_address.region.tw': '城市/縣',
      'delivery_address.district.tw': '地區',
      'save_fields.customer_info.birthday': '顧客生日日期',
      'delivery_address.province.vn': '省/市',
      'delivery_address.district.vn': '郡/鎮',
      'delivery_address.ward.vn': '社/坊',
      'delivery_address.province.th': '府',
      'delivery_address.district.th': '縣/郡',
      'delivery_address.ward.th': '區',
      'delivery_address.street': '街道',
      'delivery_address.store': '{{ station_name }}門市',

      'delivery_options.fields.delivery_type': '物流類別',
      'delivery_options.fields.delivery_types.pickup': '面交／自取',
      'delivery_options.fields.delivery_types.local': '本地郵寄',
      'delivery_options.fields.delivery_types.email': '電郵',
      'delivery_options.fields.delivery_types.international': '海外郵寄',
      'delivery_options.fields.delivery_types.custom': '自定',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11取貨（只限台灣）',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        '全家取貨（只限台灣）',
      'delivery_options.fields.select_date': '選擇指定到貨日期',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': '搜尋門市',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        '收件門市代號',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        '收件門市名稱',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label': '收件人',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': '店到店編號',

      'delivery_fee.pay.on.delivery': '運費於到貨時向您收取',

      'ezship.error.invalid_input': '內容有誤或欄位短缺',
      'ezship.error.account_not_exist': 'ezShip帳號不存在',
      'ezship.error.no_permission': 'ezShip帳號權限不足',
      'ezship.error.no_easybag': 'ezShip帳號無可用之輕鬆袋或迷你袋',
      'ezship.error.invalid_store': '取件門市有誤',
      'ezship.error.invalid_amount':
        '金額有誤。ezShip單一帳單代收金額上限為新台幣6000元。',
      'ezship.error.invalid_email': '電郵格式有誤',
      'ezship.error.invalid_mobile': '電話號碼格式有誤',
      'ezship.error.invalid_name': '收件人內容有誤或為空值',
      'ezship.error.system_error':
        'ezShip系統錯誤，請選取其他送貨方式或稍後再試。',

      'payments.fields.types.allpay_barcode': '超商條碼（經由歐付寶）',
      'payments.fields.types.allpay_cvs': '超商代碼（經由歐付寶）',
      'payments.fields.types.allpay_credit': '信用卡（經由歐付寶）',
      'payments.fields.types.allpay_webatm': '網絡ATM（經由歐付寶）',
      'payments.fields.types.asiapay': '信用卡（經由Asiapay）',
      'payments.fields.types.paypal': '信用卡或Paypal',
      'payments.fields.types.paypal_express': '信用卡或Paypal',
      'payments.fields.types.credit_card': '信用卡',
      'payments.fields.types.bank_transfer': '銀行轉帳／ATM',
      'payments.fields.types.cash_on_delivery': '貨到付款',
      'payments.fields.types.ezship': '超商貨到付款（經由ezShip）',
      'payments.fields.types.free_checkout': '免費結帳',
      'payments.fields.types.custom': '自定付款方法',
      'payments.fields.types.prizm': '信用咭 Visa / Master',
      'payments.fields.types.esun': '玉山信用卡',

      'payment.cc.expirydate': '有效日期',
      'payment.cc.holdername': '持卡人名字',
      'payment.cc.number': '信用卡號碼 (Visa / Master)',
      'payment.cc.cvc': '安全碼(CVC)',
      'payment.cc.expirydate.placeholder': 'MM/YY',

      'payment.cc.holdername.placeholder': '持卡人名字',
      'payment.cc.holdername.hint': '與信用卡上名稱相同',
      'payment.cc.number.placeholder': '信用卡號碼(不留空格）',
      'payment.cc.cvc.placeholder': '安全碼(CVC)',
      'payment.cc.cvc.hint': '一般信用卡於卡背後顯示3位安全碼',
      'payment.action.confirm': '確定',
      'payment.cc.update.instructions': '請輸入並更新你的信用卡資料。',
      'payment.cc.fields.accept_terms': '我同意細則和私隱條款。',
      'payment.cc.fields.accept_terms.validation.required':
        '你必須同意細則和私隱條款。',
      'payment.cc.cashier.footer': '金流服務由SHOPLINE Payments提供',
      'payment.cc.tw.cashier.footer':
        '本金流服務由 SHOPLINE Payments 提供，通過 PCI-DSS 國際信用卡組織最高等級認證，提供安全的交易服務，支援國內外信用卡刷卡。',

      cart: '購物車',
      'cart.title': '購物車',
      'cart.clean': '手刀結帳去！',
      'cart.over_limit':
        '購物車商品數量超出 <span class="limit-number">{{limit_number}}</span> 件的上限，請先結帳後再加入商品！同時，您也可以登入會員將此商品加入追蹤清單。',
      'cart.over_limit.login':
        '購物車商品數量超出 <span class="limit-number">{{limit_number}}</span> 件的上限，請先結帳後再加入商品！同時，您也可以將此商品加入追蹤清單。',
      'cart.item.remove': '移除',
      'cart.items.remove': '移除',
      'cart.checkout': '訂單結帳',
      'cart.empty': '你的購物車是空的',
      'cart.empty.description': '記得加入商品到你的購物車',
      'cart.empty.continue': '繼續購物！',
      'cart.promotion.checkout': '前往購物車',
      'cart.promotion.has_items_selected': '已選 <b>{{quantity}}</b> 件',
      'cart.promotion.bundle_group.has_items_selected':
        'A 組已選 <b style="color: #f05c53">{{red_quantity}}</b> 件{{red_reminder}}，B 組已選 <b style="color: #1ba462">{{green_quantity}}</b> 件{{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'A 組 <b style="color: #f05c53">{{red_quantity}}</b> 件，B 組 <b style="color: #1ba462">{{green_quantity}}</b> 件',
      'cart.redeem_gift.has_items_selected':
        '兌換贈品 <span>{{total_point}}</span> 點，剩 <span>{{rest_point}}</span> 點可兌換',
      'cart.redeem_gift.has_items_selected.insufficient_point':
        '已超出可兌換點數 <span>{{rest_point}}</span> 點，請移除部分贈品',
      'cart.copy.info.copied': '已複製網址：',
      'cart.copy.info.affiliate_code': '推薦代碼 ({{code}})、',
      'cart.copy.info.product_count': '{{count}} 件商品',

      'cart.promotion_reminder.separator': '，',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">A 組</span> 還差 {{gap}} 件',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">B 組</span> 還差 {{gap}} 件',
      'cart.promotion_reminder.group_gap': ' (還差 {{gap}} 件)',
      'cart.promotion_reminder.item': '再買 {{gap}} 件 ',
      'cart.promotion_reminder.amount': '再買 {{gap}} ',
      'cart.promotion_reminder.multiple_step': '省更多！',
      'cart.promotion_reminder.multiple_step.reward_campaign': '回饋更多！',
      'cart.promotion_reminder.stackable': '買越多省越多！',
      'cart.promotion_reminder.applied.free_shipping': '恭喜享有免運，',
      'cart.promotion_reminder.applied.gift': '恭喜享有贈品，',
      'cart.promotion_reminder.applied.discount_prefix': '，',
      'cart.promotion_reminder.applied.discount': '恭喜享有優惠，',
      'cart.promotion_reminder.applied.reward_campaign': '恭喜享有優惠，',
      'cart.promotion_reminder.applied_highest.free_shipping': '恭喜享有免運！',
      'cart.promotion_reminder.applied_highest.gift': '恭喜享有贈品！',
      'cart.promotion_reminder.applied_highest.discount': '恭喜享有優惠！',
      'cart.promotion_reminder.applied_highest.reward_campaign':
        '恭喜享有優惠！',
      'cart.promotion_reminder.discount_target.free_shipping': '即享有免運！',
      'cart.promotion_reminder.discount_target.gift': '即享有贈品！',
      'cart.promotion_reminder.discount_target.reward_campaign': '即享有優惠！',
      'cart.promotion_reminder.discount_target.discount': '即享有優惠！',
      'cart.share.btn.tooltip': '複製購物車商品給朋友',
      'cart.lock_cart_sc_product.description':
        '此為直播/貼文銷售商品，不可修改購買數量',
      'cart.lock_cart_sc_product.remove_hint':
        '此為直播/貼文銷售商品，不可刪除',
      'cart.coupon_code.apply_success': '已使用優惠券',
      'cart.affiliate_code.apply_success': '推薦人已套用',

      'checkout.instructions': '訂單資料',
      'checkout.fields.email': '你的電郵',
      'checkout.fields.email.hint':
        '請確保正確填入電郵，所有訂單事宜（確認、查詢）都會傳送至您的電郵。',
      'checkout.fields.phone.hint': '請輸入手機號碼，以接收物流短訊通知',
      'checkout.fields.email.placeholder': '電郵',
      'checkout.fields.phone': '你的電話號碼',
      'checkout.fields.phone.placeholder': '電話號碼',
      'checkout.fields.remarks': '訂單備註',
      'checkout.fields.remarks.placeholder': '請填寫備註給店主',
      'checkout.fields.delivery_method': '送貨方式',
      'checkout.delivery_option.title': '送貨地址',
      'checkout.delivery_option.out_of_stock':
        '部分商品庫存不足，無法選擇此門市，請嘗試更換其他門市',
      'checkout.payment_method.title': '付款資料',
      'checkout.fields.out_of_stock':
        '對不起，所選的貨品存貨不足，請先移除然後再下單',
      'checkout.fields.add_new': '新增',
      'checkout.fields.select': '--選擇--',
      'checkout.instalment_amount': '大約為{{amount}} x {{period}}期',
      'checkout.labels.promotion.free_shipping': '免運',
      'checkout.labels.promotion.coupon': '優惠券',
      'checkout.labels.promotion.promotion': '優惠促銷',
      'checkout.labels.promotion.membership': '會員優惠',

      'checkout.discount.applied_promotions': '已享用之優惠',
      'checkout.discount.applied_coupons': '已享用之優惠',

      'checkout.promotion.tags.promotion': '優惠促銷',
      'checkout.promotion.tags.coupon': '優惠券',
      'checkout.promotion.tags.membership': '會員優惠',
      'checkout.promotion.tags.free_shipping': '免運',
      'checkout.promotion.tags.subscription_promotion': '定期購優惠',

      'checkout.payment_condition.title': '還差 {{difference}}！',
      'checkout.payment_condition.description':
        '才能進行結帳，請返回購物車選擇其他付款方式或者繼續購物',
      'checkout.payment_condition.continue_shopping': '繼續購物',
      'checkout.payment_condition.hint': '此訂單須符合最低金額才能進行結帳',
      'checkout.payments.exist.order.tip':
        '稍早已有成立訂單 <a href="{{ duplicateOrderLink }}">{ {{order}} }</a>，如需再次結帳可點擊提交訂單。',

      'checkout.payments.apple_pay.not_supported':
        '無法以 Apple Pay支付：<ul><li>請確認裝置或瀏覽器是否已登入 Apple 帳戶</li><li>請確認 Apple Pay 帳戶內是否已綁定有效卡號</li>' +
        '<li>請確認裝置或瀏覽器是否為最新版本且支援 Apple Pay</li></ul>',
      'checkout.payments.google_pay.not_supported':
        '無法以 Google Pay支付：<ul><li>請確認裝置或瀏覽器是否已登入 Google 帳戶</li><li>請確認 Google Pay 帳戶內是否已綁定有效卡號</li>' +
        '<li>請確認裝置或瀏覽器是否為最新版本且支援 Google Pay</li></ul>',
      'checkout.payment_failed': '付款失敗，請重新再試',
      'checkout.payment_failed.atome.minamount':
        '訂單金額不符付款方式限額，請調整購物車內容或切換其他付款方式',
      'checkout.payment_failed.check_credit_card_info':
        '請先確認卡片資訊，如確認輸入資料正確，請向發卡行確認卡片授權狀況或聯繫店家',
      'checkout.cart_over_limit_error':
        '商品總數的上限為 100 件，請調整數量再點擊購買',
      'checkout.timeout':
        '你的付款發生問題， 再下訂單有可能會發生重覆訂單，請先跟我們聯絡查看付款狀態',
      'checkout.card_info_error': '請確認信用卡資訊正確後再試一次',
      'checkout.station_space_confirm_error':
        '門市選擇失敗，請再試一次或是聯絡我們',
      'checkout.payments_transaction_error':
        '你的交易出現問題，請重新檢查輸入的付款資料，或與發卡行查詢。',
      'checkout.card_type_not_support_error': '暫不支援您使用的信用卡別種類',

      'checkout.auto_fill.popup.title': '輸入手機以進行快速結帳',
      'checkout.auto_fill.popup.continue': '繼續',
      'checkout.auto_fill.popup.error': '請輸入正確的格式',
      'checkout.auto_fill.popup.cancel': '取消',
      'checkout.auto_fill.toast.error':
        '沒有偵測到上一筆訂單的紀錄，將不會帶入快速結帳資料',

      'address.fields.recipient_name': '收件人',
      'address.fields.address.recipient_name': '收件人',
      'address.fields.address.city': '城市',
      'address.fields.address.postcode': '郵政編號（如適用)',
      'address.fields.address.country': '國家/地區',
      'address.fields.address.remarks': '備註',

      'messages.form.send': '發送',
      'action.add_photo': '加入圖片',
      'action.send': '發送',

      'action.load_earlier': '載入之前',
      'action.load_more': '載入更多',
      'users.fields.name': '用戶名',
      'users.fields.email': '電郵',
      'users.fields.mobile': '手機號碼',
      'users.fields.mobile_phone_or_email': '電郵或手機號碼',
      'users.fields.password': '密碼',
      'users.fields.name.placeholder': '用戶名',
      'users.fields.email.placeholder': '電郵',
      'users.fields.mobile.placeholder': '手機號碼',
      'users.fields.password.placeholder': '密碼',
      'users.fields.callingCode': '國碼',
      'users.fields.callingCodeHint': '請選擇國際電話區碼',
      'users.save.success': '變更已儲存',
      'users.save.email_verification.success':
        '變更已儲存, 電郵會在驗證成功後更新',
      'users.save.email_verification.send': '驗證信已寄出',
      'users.add.phone': '新增聯絡電話',
      'users.add.delivery_address': '新增送貨地址',
      'users.add.phone.maximum': '最多只能有五組電話號碼',
      'users.add.delivery_address.maximum': '最多只能有五個送貨地址',
      'users.birthday.faq': '你需達到一定年齡才能於網店註冊及購物。',
      'users.error.phone.format': '電話號碼格式錯誤',
      'users.error.phone.required': '電話號碼必填',
      'users.error.not_save_mobile_phone': '此手機號碼尚未驗證，無法儲存變更',
      'users.error.no_changes': '沒有任何可儲存的變更',
      'users.signin.error.invalid.mobile': '帳號或密碼是無效的',
      'users.signin.error.invalid.email': '帳號或密碼是無效的',
      'users.signup.male': '男',
      'users.signup.female': '女',
      'users.signup.other': '不透露',
      'users.signup.email': '使用Email註冊',
      'users.signup.mobile_phone': '使用手機號碼註冊',
      'users.signup.unconfirmed':
        '歡迎回來！為保護您的帳號安全，即日起需完成帳號驗證才能登入。驗證信已發送至{{email}}，請前往收信驗證，謝謝您！',
      'users.signup.verification.hint.send':
        '驗證信已寄出，{{count}}秒後可重發驗證信',
      'users.einvoice_carrier': '電子發票載具歸戶',
      'users.edit_member_info': '編輯會員資料',
      'users.membership.member_info.title': '會員資料獎賞',
      'users.membership.member_info.hint':
        '填寫以下會員資訊：{{fields}}，填寫完畢可獲得 {{rewards}}',
      'users.membership.member_info.sent':
        '待資料檢查完成，將發放會員資料獎賞！',
      'users.membership.member_info.symbol': '、',
      'users.membership.member_info.name': '姓名',
      'users.membership.member_info.email': '電郵',
      'users.membership.member_info.mobile': '手機號碼',
      'users.membership.member_info.gender': '性別',
      'users.membership.member_info.birthday': '生日',
      'users.membership.member_info.user_credits': '${{count}} 元購物金',
      'users.membership.member_info.member_points': '{{count}} 點會員點數',
      'users.membership.member_info.coupons': '{{count}} 張優惠券',
      'users.membership.forget_password.email_sent':
        '我們已寄出帳號驗證信至{{email}}，\n請收信以繼續重設密碼！',
      'users.member.normal-level': '一般會員',
      'users.membership.expiry_date': '會籍到期日',
      'users.membership.expire.unlimited': '永久有效',
      'users.membership.expire.month_valid': '（效期 {{months}} 個月)',
      'users.membership.tier.expire.date.description':
        '自動更新時間為訂單完成付款後的隔日凌晨，若因取消訂單或退貨未達成升級條件則不會升級。',
      'users.membership.tier.expire.date.auto_downgrade.description':
        '自動更新時間為訂單轉為已完成後的隔日凌晨，若因取消訂單或退貨未達成升級條件則不會升級。',
      'users.membership.tier.extension.date.description':
        '會籍到期時，訂單狀態若因取消訂單或退貨未達成續會條件則不會續會。',
      'users.membership.offer.discount': '專屬優惠',
      'users.membership.upgrade.next_tier': '升級至{{tier_name}}',
      'users.membership.upgrade.next_tier.discount': '，享購物 {{discount}} 折',
      'users.membership.extension.condition': '續會條件',
      'users.membership.extension.status': '續會狀態',
      'users.membership.max_level_tier': '恭喜您已至最高階會員!',
      'users.membership.spending_within_months':
        '{{months}} 個月內累積消費額達 {{spending}} 即可升級',
      'users.membership.spending_single_purchase':
        '單次消費額達 {{spending}} 即可升級',
      'users.membership.all.spending_within_months':
        '{{months}} 個月內累積消費額達 {{spending}}',
      'users.membership.all.spending_single_purchase':
        '單次消費額達 {{spending}}',
      'users.membership.extend_valid_period':
        '會籍效期{{months}}個月內{{ type === "within_interval" ? "累積" : "單次" }}消費額達 {{spending}}',
      'users.membership.all_tiers': '查看所有會員等級規則',
      'users.membership.all_tiers.condition': '會員等級規則',
      'users.membership.all_tiers.description':
        '會籍期效內若沒達成續會條件，將會自動降級',
      'users.membership.tier.shopping_discount': '購物 {{discount}} 折',
      'users.membership.tier.upgrade.condition': '本階升級條件 :',
      'users.membership.tier.upgrade.condition.dollar':
        '目前沒有高於 {{ dollar }} 的訂單，',
      'users.membership.tier.upgrade.condition.continue': '繼續逛逛！',
      'users.membership.tier.extend': '已達 <b>{{tier}}</b> 續會條件？',
      'users.membership.tier.upgrade.to_next_level.single': '已達升級條件？',
      'users.membership.tier.upgrade.to_next_level.within':
        '{{month}} 個月內累積消費額',
      'users.membership.tier.upgrade.completed': '符合升級資格',
      'users.membership.tier.upgrade.gap_dollar': '差 {{ dollar }} 升級',
      'users.membership.tier.expire.date': '有效期限 :',
      'users.membership.tier.extend.condition': '續會條件 :',
      'users.membership.tier.extend.single_purchase':
        '會籍效期 {{month}} 個月內沒有高於 {{dollar}} 的訂單',
      'users.membership.tier.extend.single_purchase.can_extend':
        '會籍效期 {{month}} 個月內有高於 {{dollar}} 的訂單，到期時將自動續會',
      'users.membership.tier.extend.top_level.single_purchase':
        '會籍內沒有高於 {{dollar}} 的訂單，',
      'users.membership.tier.extend.top_level.single_purchase.can_extend':
        '會籍內有高於 {{dollar}} 的訂單，恭喜達成！到期時將自動續會',
      'users.membership.tier.extend.within_interval':
        '會籍效期 {{month}} 個月內累積消費 {{spending}}，差 {{dollar}} 續會',
      'users.membership.tier.extend.within_interval.can_extend':
        '會籍效期 {{month}} 個月內累積消費已達 {{dollar}}，到期時將自動續會',
      'users.membership.tier.extend.top_level.within_interval':
        '差 {{dollar}} 續會',
      'users.membership.tier.extend.top_level.within_interval.can_extend':
        '恭喜達成！到期時將自動續會',
      'users.membership.tier.expire.month': '{{month}} 個月',

      'users.phone.migrate.notification':
        '為配合商店功能更新，顧客電話將在 2019/1/15 後僅保留最後一組。如您有保存多組電話，請確認最後一組電話是正確的',
      'membership.profile.subscriptions.orders.update.status':
        'Updates to my orders',
      'membership.profile.subscriptions.orders.comments.new':
        'New comments from the merchant on my orders',
      'membership.profile.subscriptions.messages.new':
        'New messages from the merchant',
      'membership.profile.subscriptions.marketing.news': '商店資訊及優惠方案',
      'membership.profile.subscriptions.none': 'None',
      'membership.profile.mobile.number.verified':
        '會員手機驗證完成，並將取代已儲存的電話號碼以利辨識',
      'membership.profile.check.code.error':
        '驗證碼有誤，請重新輸入或重發驗證碼',
      'membership.profile.verification_hint':
        '若您更改電郵，儲存後需進行驗證，完成驗證後電郵將會立即更新為 {{unconfirmed_email}}',
      'membership.profile.email_subscription.error':
        '若要訂閱 Email，請先輸入電郵後再訂閱',
      'membership.profile.sms_subscription.error':
        '若要訂閱 SMS，請先輸入手機號碼後再訂閱',

      'member_points.title': '會員點數',
      'member_points.unit': '點',
      'member_points.point_balance': '現有總點數',
      'member_points.point_history': '點數記錄',
      'member_points.fields.date': '日期',
      'member_points.rule': '點數規則說明',
      'member_points.display.expire_point': '點將於',
      'member_points.display.successive_expire_point': '點將於',
      'member_points.fields.expire': '到期',
      'member_points.fields.expired': '點數過期',
      'member_points.fields.successive_expire': '後陸續到期',
      'member_points.fields.remarks': '點數更動原因',
      'member_points.fields.value': '點數',
      'member_points.fields.expiry_date': '到期日',
      'member_points.fields.member_point_expired': '點數過期',
      'member_points.fields.auto_reward': '於訂單',
      'member_points.fields.auto_reward_seconds': '獲得點數',
      'member_points.no_data': '沒有任何記錄',
      'member_points.equal_cash': '等值 {{money}}',
      'member_points.redeem_cash_and_earn_from_order_description':
        '消費 {{costMoney}} 可累計 {{earnPoint}} 點，{{costPoint}} 點可折抵 {{discountMoney}}。',
      'member_points.redeem_cash_description':
        '{{costPoint}} 點可折抵 {{discountMoney}}。',
      'member_points.earn_from_order_description':
        '消費 {{costMoney}} 可累計 {{earnPoint}} 點。',

      'coupon.title': '優惠券',
      'coupon.valid': '可使用',
      'coupon.invalid': '已失效',
      'coupon.valid_until':
        '優惠至 <span class="coupon-period-time"><div class="coupon-period-tip">依據你的當地時區顯示<br/>(GMT{{timeZone}})</div> {{time}} </span> 截止',
      'coupon.start_at':
        '優惠開始日期 <span class="coupon-period-time"><div class="coupon-period-tip">依據你的當地時區顯示<br/>(GMT{{timeZone}})</div> {{time}} </span>',
      'coupon.one_time': '限用 1 次',
      'coupon.first_shoppers': '前 {{number}} 名適用',
      'coupon.reach_limit': '已額滿',
      'coupon.expired': '已過期',
      'coupon.used': '已使用',
      'coupon.no_valid_coupon': '沒有可使用的優惠券',
      'coupon.no_invalid_coupon': '沒有已失效的優惠券',
      'coupon.no_comingSoon_coupon': '沒有即將開始的優惠券',
      'coupon.no_available_coupon': '沒有可領取的優惠券',
      'coupon.select_coupon': '選擇優惠券',
      'coupon.confirm': '確認',
      'coupon.first_purchase_only': '首購限定',
      'coupon.comingSoon': '即將開始',
      'coupon.retail_store_only': '限門市',
      'coupon.online_store_only': '限網店',
      'coupon.online_and_selected_retail_store_only': '限網店/指定門市',
      'coupon.selected_retail_store_only': '限指定門市',
      'coupon.selected_retail_store_list': '指定門市清單',
      'coupon.login_to_receive': '登入領取優惠券',
      'coupon.login_or_register': '請點擊下方按鈕前往登入/註冊頁面',
      'coupon.usage_limit': '優惠已額滿',
      'coupon.already_taken': '您已領取過',
      'coupon.is_expired': '優惠已過期',
      'coupon.not_eligible': '不符合優惠券資格',
      'coupon.unfit_first_purchase': '不符合優惠券的首次購買條件',
      'coupon.login': '登入',
      'coupon.close': '關閉',
      'coupon.success': '領取成功',
      'coupon.congratulation': '恭喜你領取成功！',
      'coupon.view': '查看優惠券',
      'coupon.only_tag': '限定',

      go_coupon_center: '前往領券中心',
      back_member_center: '回到會員中心',
      coupon_center: '領券中心',

      'store_credits.title': '商店購物金',
      'store_credits.credit_balance': '現有購物金',
      'store_credits.credit_history': '購物金紀錄',
      'store_credits.fields.date': '日期',
      'store_credits.fields.remarks': '購物金項目',
      'store_credits.fields.value': '購物金款項',
      'store_credits.fields.expiry_date': '到期日',
      'store_credits.fields.balance': '購物金餘額',
      'store_credits.fields.order_prefix': '已使用購物金在此筆訂單',
      'store_credits.fields.welcome_credit': '新加入會員購物金',
      'store_credits.fields.member_referral_credit': '推薦新顧客進行消費',
      'store_credits.fields.member_info_quick_completion_credit':
        '會員資料獎賞購物金',
      'store_credits.fields.welcome_member_referral_credit':
        '透過推薦連結註冊成功',
      'store_credits.fields.birthday_credit': '生日購物金',
      'store_credits.fields.user_credit_expired': '購物金過期',
      'store_credits.fields.never_expires': '永不過期',
      'store_credits.fields.never_expires_point': '點永不過期',
      'store_credits.fields.display.never_expired': '永不過期',
      'store_credits.fields.auto_reward_prefix': '訂單 ',
      'store_credits.fields.auto_reward': ' 獲得購物金',
      'store_credits.fields.revert_credit': '回補購物金；已取消訂單',
      'store_credits.fields.revert_credit.return': '回補購物金；已退貨訂單',
      'store_credits.fields.revert_credit.order_split': '回補購物金；來自拆單',
      'store_credits.fields.revert_credit.order_edit':
        '回補購物金；來自編輯訂單',
      'store_credits.fields.revert_credit.return_order_revert':
        '回補購物金；來自退貨',
      'store_credits.fields.product_review_reward':
        '完成訂單 <a href="{{link}}">{{ orderNumber }}</a> 的評價',
      'store_credits.no_data': '沒有任何記錄',
      'store_credits.bulk_imported': '手動新增購物金',

      'member_points.fields.revert_member_point': '回補點數；已取消訂單',
      'member_points.fields.revert_member_point.return': '回補點數；已退貨訂單',
      'member_points.fields.revert_member_point.order_split':
        '回補點數；來自拆單',
      'member_points.fields.revert_member_point.order_edit':
        '回補點數；來自編輯訂單',
      'member_points.fields.revert_member_point.return_order_revert':
        '回補點數；來自退貨',
      'member_points.fields.product_review_reward':
        '完成訂單 <a href="{{link}}">{{ orderNumber }}</a> 的評價',
      'member_points.fields.member_info_reward': '會員資料獎賞會員點數',

      'tax.sales_tax': '消費稅',
      'tax.sales_tax_rate': '消費稅率',
      'tax.service_tax': '服務稅',
      'tax.service_tax_rate': '服務稅率',
      'tax.delivery_tax': '運費稅',
      'tax.delivery_tax_rate': '運費稅率',
      'tax.calculate_tax': '計算稅金',
      'tax.calculate_tax.info.1': '請先按',
      'tax.calculate_tax.info.2': '後再結帳',
      'tax.calculation_title': '稅費計算方式：',
      'tax.calculation_content': '(收稅商品總價 - 優惠) * 稅率 + 運費 * 稅率',
      'tax.price_updated': '價格已更新',
      'tax.total': '合計',
      'tax.fee': '稅費',
      'tax.product_price': '商品價格',
      'tax.discounts': '優惠',
      'tax.applied_store_credit': '折抵購物金',

      'dynamic.delivery.fee.no.state.notice':
        '運費將於填寫送貨地址後，於結帳頁顯示。',
      'dynamic.delivery.fee.no.state.info': '將於確認送貨地址後顯示運費',
      'dynamic.delivery.fee.calculate.title': '運費計算方式',
      'dynamic.delivery.fee.calculate.desc':
        '首 1 公斤運費 + (總重 - 1 公斤) * 續重運費',
      'dynamic.delivery.fee.calculate.hint': '總重未滿 1 公斤以 1 公斤計算',
      'dynamic.delivery.fee.updated.notice': '運費已更新',
      'dynamic.delivery.fee.calculate.info': '運費合計：{{totalDeliveryFee}}',

      'error.404.title': '非常抱歉',
      'error.404.description': '網頁不存在',
      'error.401.title': '非常抱歉',
      'error.401.description': '您沒有權限進入此頁面',
      'error.403.title': '非常抱歉',
      'error.403.description': '您沒有權限進入此頁面',
      'error.403.not_signed_in.message': ', 這可能是因為您還沒有',
      'error.403.not_signed_in.anchor': '登入',

      'error.continue': '繼續購物！',

      'orders.comments.title': '店家和顧客訂單通訊',
      'orders.comments.text.name': '留言',
      'orders.comments.text.placeholder': '請輸入留言',

      'orders.invoices.title': '發票',
      'orders.invoices.carrier': '共同性載具',
      'orders.invoices.carrier_type.member':
        '會員載具 (發票資訊會寄到您的電郵)',
      'orders.invoices.carrier_type.mobile_barcode': '手機條碼',
      'orders.invoices.carrier_type.npc_barcode': '自然人憑證條碼',
      'orders.invoices.get_invoice': '我要索取發票',

      'orders.notifications.title': '訂單狀態通知',

      'order.payments.login.confirm': '請登入後再進行重新付款或更新信用卡資訊!',

      'model.credit_card.title': '您的信用卡資訊',
      'model.credit_card.subtitle': '請輸入您的信用卡資訊',
      'model.credit_card.choose_card': '請選擇你需要使用的支付卡',
      'model.credit_card.use_new_card': '使用新卡',
      'model.slpayment.security_agreement':
        '本金流服務由 SHOPLINE Payments 提供，通過 PCI-DSS 國際信用卡組纖最高等級認證，提供安全的交易服務，支援國內外信用卡刷卡。',
      'model.slpayment.recurring_pay_agreement':
        'SHOPLINE將會使用本次交易訊息作為後續定期扣款，點擊提交即同意 <a href="https://shopline.tw/about/product_subscription_agreements" target="_blank" rel="noopener">《定期購信用卡代扣協議》</a>',
      'model.update_credit_card_for_product_subscription.success':
        '已成功更新信用卡資訊',
      'model.update_credit_card_for_product_subscription.fail':
        '定期購付款資訊設定失敗，請重新嘗試',

      'sms_messenger_checkbox.subscribe': '訂閲 SMS',
      'facebook_messenger_checkbox.subscribed': '已訂閲',
      'facebook_messenger_checkbox.turn_off': '關閉',
      'facebook_messenger_checkbox.v2.subscribe': '訂閱 Messenger',
      'facebook_messenger_checkbox.v2.subscribed': '已訂閱 Messenger',
      'facebook_messenger_checkbox.subscribe': '傳送到 Messenger',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        '訂單狀態通知',
      'facebook_messenger_checkbox.topics.MARKETING': '商店資訊及優惠方案',
      'subscribe.facebook.dialog.title': '訂閱 Facebook',
      'subscribe.facebook.dialog.info': `因應 Facebook 平台的訊息規範更新，須至 Messenger 完成訂閱流程。請點按「前往訂閱」，系統將自動開啟 Messenger。<br /> 開啟 Messenger 後，請登入並<span class="facebook-subscription-dialog-hint">點按對話中的「立即開始」按鈕</span>再回到本頁面，即可完成訂閱。（若您曾與此商店的粉絲專頁對話過，開啟對話後即視為訂閱成功。）`,
      'subscribe.facebook.dialog.cancel': '取消',
      'subscribe.facebook.dialog.subscribe': '前往訂閱',

      'line_messenger_checkbox.subscribe': '傳送到 LINE',
      'line_messenger_checkbox.subscribed': '已訂閱 LINE',
      'line_messenger_checkbox.order_notification.subscribed':
        '已訂閱訂單狀態通知',
      'line_messenger_checkbox.button.unsubscribe': '關閉',

      'whatsapp_messenger_checkbox.subscribe': '訂閱 WhatsApp',

      'orders.fields.payment_fee': '附加費',

      'media.validation.file_limit.one': '只可以上載一張圖片',
      'media.validation.type': '只可以上載JPEG或PNG格式的圖片',
      'media.validation.error.file_size': '請上傳小於5MB的圖片',
      'media.validation.error.file_attachment_size': '請上傳小於2MB的文件',
      'media.validation.error': '對不起，上載出現問題，請重試。',

      'attachment.upload.description':
        '檔案不大於 2MB\n格式可為：pdf, doc, docx, xls, xlsx, csv, jpg, jpeg, png, gif',

      'imagepicker.title': '上載圖片',

      'promotions.section.discount_summary.set.on_item':
        '<b>指定商品</b> {{ discount_value }}',
      'promotions.section.discount_summary.set.on_order':
        '{{ criteria_on ? "" : "<b>全店</b>" }} {{ discount_value }}',
      'promotions.section.discount_summary.discount_value':
        '{{ type == "amount" ? "即減" : "即享" }} <b>{{ type == "amount" ? currency : "" }}{{ value }}{{ type == "percentage" ? "折":"" }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>指定商品</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>全單</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        '購買 <b>指定商品</b> <b>滿{{ condition_value }}</b>，',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        '<b>全單</b> 購買 <b>滿{{ condition_value }}</b>，',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }}件',
      'promotions.section.discount_summary.criteria.bundle_group':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任選 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任選 {{ green_count }} 件 共 {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_amount':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任選 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任選 {{ green_count }} 件 即減 {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_percentage':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任選 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任選 {{ green_count }} 件 即享 {{ total }} 折',
      'promotions.section.discount_summary.criteria.bundle_group_gift':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任選 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任選 {{ green_count }} 件 即享 {{ total }} 件贈品 ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_pricing':
        '指定商品：任選 {{ itemCount }} 件 共 {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_percentage':
        '指定商品：任選 {{ itemCount }} 件 即享 {{ discountValue }} 折',
      'promotions.section.discount_summary.criteria.bundle_amount':
        '指定商品：任選 {{ itemCount }} 件 即減 {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_gift':
        '指定商品：任選 {{ itemCount }} 件 即享 {{ discountValue }} 件贈品 ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        '，買越多省越多！',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        '，最多優惠 1 組。',
      'promotions.section.discount_summary.content':
        '{{ criteria }}{{ benefits }}',
      'promotions.section.discount_percentage.caption': '{{ value }}折',

      'promotions.page.term.title': '條款與細則',
      'promotions.page.term.confirm': '確認',
      'promotions.page.term.default':
        '＊商品活動優惠以進入購物車結帳計算為準\n＊結帳時請選擇活動指定付款與送貨方式以取得優惠\n＊若活動僅適用會員，結帳時請登入以取得優惠',
      'promotions.page.start_at':
        '優惠活動開始日期 <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        '優惠至 <b class="local-datetime">{{date}}</b> 截止',
      'promotions.page.redeem_gift.start_at':
        '活動開始日期 <span class="local-datetime">{{date}}</span>',
      'promotions.page.redeem_gift.end_at':
        '活動至 <b class="local-datetime">{{date}}</b> 截止',
      'promotions.page.display_local_timezone':
        '依據你的當地時區顯示<br/>(GMT{{offset}})',

      'promotions.exclude.items.hint.title': '已是最優惠價格，不參與:',
      'promotions.exclude.items.hint.order_discount': '全店折扣優惠',
      'promotions.exclude.items.hint.member_tier': '會員默認優惠',
      'promotions.exclude.items.hint.user_credit': '購物金折抵',
      'promotions.exclude.items.hint.member_point': '點數折現',

      'product.page.title.product-info': '商品',
      'product.page.quantity': '數量',
      'product.page.unit_price': '單件價格',
      'product.page.subtotal': '小計',
      'product.page.add_to_cart_count':
        '已加入 <span class="order-detail-count">{{count}}</span> 項商品',
      'product.member_only': '會員獨享',
      'product.tier_member_only': '{{tierName}} 獨享',

      'products.quick_cart.quantity': '數量',
      'products.quick_cart.preorder_now': '現在預購',
      'products.quick_cart.add_to_cart': '加入購物車',
      'products.quick_cart.quantity_invalid':
        '該數量不適用，請填入有效的數量。',
      'products.quick_cart.out_of_stock': '售完',
      'products.quick_cart.out_of_stock_hint': '商品存貨不足，未能加入購物車',
      'products.quick_cart.out_of_number_hint': '商品數量不足',
      'products.quick_cart.low_stock_hint': '您所填寫的商品數量超過庫存',
      'products.quick_cart.insufficient_point': '會員點數不足，請移除部分贈品',
      'products.quick_cart.reached_max_purchase_quantity':
        '商品購買上限為 {{ message }} 件',
      'products.quick_cart.quantity_of_stock_hint':
        '現庫存只剩下 {{message}} 件',
      'products.quick_cart.messagetobuy': '若想購買，請聯絡我們。',
      'products.quick_cart.message': '聯絡我們',
      'products.quick_cart.show_more': '商品資訊',
      'products.message.title': '確定要移除這個商品嗎？',
      'products.message.button_cancel': '取消',
      'products.message.button_ok': '確定',
      'products.purchase_limit_hint': '每位顧客限購 {{limit}} 件',
      'purchase_limit_hint.purchasable':
        '每位顧客限購 {{limit}} 件，您只能再購買 {{purchasable_qty}} 件',
      'products.purchase_limit_hint_can_not_purchase':
        '每位顧客限購 {{limit}} 件，您已購買達上限',

      'products.category.advance_filter.title': '篩選',
      'products.category.advance_filter.clear': '清除',
      'products.category.advance_filter.clear_all': '清除全部',
      'products.category.advance_filter.apply': '套用篩選',
      'products.category.advance_filter.apply.hint': '請先選擇篩選內容',
      'products.category.advance_filter.show_more': '看更多',
      'products.category.advance_filter.show_less': '收起內容',
      'products.category.advance_filter.type.color': '顏色',
      'products.category.advance_filter.type.size': '尺寸',
      'products.category.advance_filter.type.brand': '品牌',
      'products.category.advance_filter.type.material': '材質',
      'products.category.advance_filter.type.price': '價格 ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': '最小金額',
      'products.category.advance_filter.placeholder.max_price': '最大金額',

      'products.variant_selector.hide': '收起',
      'products.variant_selector.view_other_variations':
        '看其他 {{count}} 個選項',

      'hk_sfplus.region': '地區',
      'hk_sfplus.area': '範圍',
      'hk_sfplus.district': '區域',
      'hk_sfplus.address': '{{region}} {{district}} {{area}}',

      'zeek.station.address': '自取點地址',
      'zeek.station.name': '自取點名稱',
      'zeek.station.provider': '自取服務商',
      'zeek.pickup.region': '自取地區',
      'zeek.pickup.area': '自取區域',

      'tcat.time_slot.title': '選擇指定到貨時段',
      'tcat.time_slot.01': '13:00 以前',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': '任何時段',

      'subscribe.success': '訂閱成功',
      'subscribe.failure': '訂閱失敗，請聯絡店家',
      'subscribe.line.failure': '訂閱失敗，請重新登入 LINE',
      'subscribe.line.failure.already_used':
        '訂閱失敗，此 LINE 帳號已被其他會員註冊使用，請用 LINE 登入該帳號再訂閱',
      'subscribe.line.failure.different_id':
        '訂閱失敗，請用註冊此會員的 LINE 帳號訂閱',
      'subscribe.facebook.failure': '訂閱失敗，請重新登入 FB',
      'subscribe.failure.already_bound':
        '訂閱失敗，此社群帳號已綁定其他顧客，請聯絡店家解除綁定',
      'unsubscribe.success': '取消訂閱成功',
      'unsubscribe.failure': '取消訂閱失敗，請再試一次',

      'return_order.fill_infomation': '填寫退貨資料',
      'return_order.confirmation': '退貨單確認',
      'return_order.select_return_product': '選取退貨商品',
      'return_order.order_number': '訂單編號',
      'return_order.product_name': '商品名稱',
      'return_order.quantity': '數量',
      'return_order.unit_price': '單件價格',
      'return_order.return_quantity': '退貨數量',
      'return_order.prcie_amount': '金額',
      'return_order.return_reason': '退貨原因',
      'return_order.return_reason.not_expected': '商品不如預期',
      'return_order.return_reason.broke_during_delivery':
        '商品在運送過程中有毀損',
      'return_order.return_reason.wrong_variation': '商品尺寸規格不符',
      'return_order.return_reason.wrong_item': '商品寄錯',
      'return_order.return_reason.other': '其他原因',
      'return_order.return_reason.admin_return_order': '由店家成立退貨單',
      'return_order.return_reason.return_remark': '退貨原因描述',
      'return_order.return_reason.return_remark_placeholder':
        '改變心意，限 60 個字元',

      'return_order.return_delivery.info': '退貨資料',
      'return_order.return_delivery.delivery_option': '退貨物流',
      'return_order.return_delivery.status': '退貨狀態',
      'return_order.return_delivery.receiver_name': '退貨人姓名',
      'return_order.return_delivery.receiver_phone': '退貨人電話',
      'return_order.return_delivery.receiver_address': '退貨人地址',
      'return_order.return_delivery.optional': '選填',
      'return_order.return_delivery.destination': '地點',

      'return_order.return_payment.info': '退款資料',
      'return_order.return_payment.payment_option': '退款方式',
      'return_order.return_payment.status': '退款狀態',
      'return_order.return_payment.custom_return':
        '其他（請與商家聯繫退款事宜）',
      'return_order.return_payment.bank_transfer_return': '提供退款帳號',
      'return_order.return_payment.bank_account': '銀行名稱與帳號',
      'return_order.return_payment.bank_transfer_placeholder':
        '812 台新銀行 xxxxxxxxxxxx',
      'return_order.form.agree_return_policy':
        '我同意 <a href="{{ link }}" target="_blank" rel="noopener">退換貨政策</a>',
      'return_order.form.validation': '你必須同意退換貨政策',
      'return_order.form.submit': '提交退貨申請',
      'return_order.back_to_order_detail': '返回訂單資訊',

      'return_order.detail.order_submitted': '您的退貨單已經成立！',
      'return_order.detail.confirmation_emailed':
        '退貨確認信已經發送到您的電郵',
      'return_order.detail.return_order_no': '退貨資訊編號',
      'return_order.detail.apply_date': '申請日期',
      'return_order.confirmation.refund_note':
        '實際退款金額，待商家計算運費或各式優惠條件後，將顯示於您的<a href="{{ link }}">退貨資訊頁面</a>',
      'return_order.confirmation.refund_question':
        '如有任何疑問，歡迎<a id="js-contact-us">聯絡客服</a>',

      'return_order.inspect.status': '驗貨狀態',
      'return_order.inspect.status.pass': '驗貨成功',
      'return_order.inspect.status.fail': '驗貨失敗',
      'return_order.refund.record': '退款紀錄',
      'return_order.refund.amount': '退款金額',
      'return_order.refund.date': '退款日期',
      'return_order.return.total': '退貨合計',
      'return_order.coninue_shopping': '繼續購物',

      'return_order.inspect.status.no_inspect': '不驗貨',
      'return_order.inspect.status.pending': '未驗貨',
      'return_order.delivery_status.return_collected': '已退回',
      'return_order.delivery_status.returned': '已退貨',
      'return_order.delivery_status.returning': '退貨中',
      'return_order.delivery_status.pending': '退貨中',
      'return_order.delivery_status.arrived': '已到達',
      'return_order.delivery_status.no_return': '不退回',
      'return_order.payment_status.pending': '未退款',
      'return_order.payment_status.refunded': '已退款',
      'return_order.payment_record.pending': '待處理',
      'return_order.popup.failed.title': '退貨申請失敗！',
      'return_order.popup.failed.content':
        '此物流方式訂單金額上限為 {{ amount }} 台幣，請重新更換物流方式再次送出訂單退貨的申請。',
      'return_order.popup.failed.confirm': '確定',

      'return_order.status': '退貨單狀態',
      'return_order.status.pending': '退貨審核中',
      'return_order.status.confirmed': '確認退貨',
      'return_order.status.completed': '退貨完成',
      'return_order.status.cancelled': '取消退貨',

      'order_comment_and_message.general_error':
        '系統錯誤，請與店主聯絡或稍後再試',
      'order_comment_and_message.rate_limit_error':
        '已達今日傳送訊息上限，請於明天再試一次',

      'member_referral.discount': '會員推薦優惠',
      'member_referral.rules': '活動規則說明',
      'member_referral.code.copy_and_share':
        '複製並分享連結給朋友，推薦對象和你將獲得購物金。',
      'member_referral.code.remarks': '活動規則說明',
      'member_referral.generate.code': '產生推薦連結',
      'member_referral.generate.code.success': '產生成功',
      'member_referral.copy.link': '複製連結',
      'member_referral.copy.link.success': '複製成功',
      'member_referral.welcome_credit.popup.title':
        '恭喜，你獲得 {{reward_value}} 購物金',
      'member_referral.welcome_credit.popup.content':
        '透過朋友分享，你獲得了 {{reward_value}} 購物金，可在結帳時使用。成為會員的你也可以分享給新朋友，並獲得推薦優惠喔。',
      'member_referral.welcome_credit.popup.continue': '知道並繼續購物',

      'lock_inventory.tooltip':
        '保留至 {{expired_time}}\n({{start_time}}開始的直播:{{event_name}})\n根據你的當地時區顯示（GMT{{timezone}}）',
      'facebook_banner.description':
        '您正在使用 Facebook app內建瀏覽器開啟，如果上傳圖片無法成功，請改用其他瀏覽器；<span>點此複製連結</span>。',
      'in_app_browser_popup.desc':
        '若您需登入會員與購物車結帳，建議開啟預設瀏覽器享有更好的購物體驗！',
      'fb_in_app_browser_popup.desc':
        '您正在使用Facebook app內建的瀏覽器，請改用其他瀏覽器才可順利結帳；',
      'fb_in_app_browser_popup.copy_link': '點此複製連結。',

      'product_set.build': '選取組合商品',
      'product_set.subtitle.unqualified':
        '立即新增下方商品，再加 <span class="highlight-label">{{ quantity }}</span> 件即可加入購物車',
      'product_set.subtitle.qualified': '已享有優惠，立即加入購物車！',
      'product_set.special_offer': '組合商品優惠',
      'product_set.items_needed':
        '還差 <span class="highlight-label">{{ quantity }}</span> 件',
      'product_set.add_to_set': '加入組合',
      'product_set.items_needed.diff':
        '已選 {{ currentQuantity }} 件商品，還差 <span class="highlight-label">{{ stillNeedQuantity }}</span> 件即享有組合優惠！',
      'product_set.add_to_cart': '加入購物車',
      'product_set.items_selected':
        '已選 <span class="highlight-label">{{ quantity }}</span> 件',
      'product_set.items_needed.hint':
        '再加入 <span class="highlight-label">{{ quantity }}</span> 件即可完成',
      'product_set.quantity_label.unqualified': '還差 {{ quantity }} 件',
      'product_set.quantity_label.qualified': '已選 {{ quantity }} 件',
      'product_set.reach_limit': '已達選購上限',
      'product_set.reach_limit.congrats':
        '已選 <span class="highlight-label">{{ quantity }}</span> 件商品，恭喜享有組合優惠！',
      'product_set.no_items_selected': '尚無商品，請至上方選取加入組合',
      'product_set.quick_cart.add_to_set': '加入組合',
      'product_set.exceed_limit': '您所填寫的商品數量超過組合商品選購上限',
      'product_set.set_includes': '組合商品內容：',
      'product_set.quantity': '組合數量',
      'product_set.sold_out': '庫存不足',
      'product_set.please_select': '請選擇商品規格',
      'product_set.not_enough_stock.please_remove':
        '商品數量超過庫存，請刪除此商品',
      'product_set.not_enough_stock': '您所填寫的商品數量超過庫存',
      'store_stock.loading': '確認庫存中',
      'store_stock.take_a_while': '這會需要一些時間...',
      'store_stock.current_availability': '目前門市供貨狀況',
      'store_stock.description':
        '商品販售以現場狀況為準，僅為完成預訂之顧客保留商品庫存，建議您來店前先完成下單並確認店取日期。',
      'store_stock.available': '可於線上購買，門市取貨',
      'store_stock.sold_out': '售完',

      'cookie_consent_popup.claim.heading': '此網站使用 cookies',
      'cookie_consent_popup.claim.description':
        'Hi, 本網站使用必要 cookies 和追蹤型 cookies 以確保網站服務，後者將在獲得你的同意後才會執行追蹤。',
      'cookie_consent_popup.claim.read_more': '了解更多',
      'cookie_consent_popup.buttons.settings': '設定偏好',
      'cookie_consent_popup.buttons.accept': '同意並繼續',
      'cookie_consent_popup.buttons.save_preferences': '儲存偏好設定',
      'cookie_consent_popup.cookie_names.necessary': '絕對必要 cookies',
      'cookie_consent_popup.cookie_names.statistical': '統計 cookies',
      'cookie_consent_popup.cookie_names.marketing': '行銷應用 cookies',
      'cookie_consent_popup.cookie_names.functionality': '功能 cookies',
      'cookie_consent_popup.cookie_descriptions.necessary':
        '網站運作需要這些 Cookies 且您不能將其關閉，如果沒有這些 Cookies，則無法提供網站預期服務。',
      'cookie_consent_popup.cookie_descriptions.statistical':
        '這些 Cookies 專門用於改善網站的性能以提供更好的用戶體驗。 例如，訪問者最常打開哪個網頁，以及某些網頁的錯誤消息。這些 cookies 不保存可以識別用戶的信息，收集的信息是彙總的、匿名的。如果您不允許使用這些 Cookies，我們將不知道您如何使用我們的網站。',
      'cookie_consent_popup.cookie_descriptions.marketing':
        '這些 Cookie 用於傳遞與您的興趣相關的廣告並與您進行商業信息溝通，其內容會與第三方行銷應用分享。',
      'cookie_consent_popup.cookie_descriptions.functionality':
        '這些 Cookies 匿名保存您已輸入的信息（例如用戶名、語言選擇和您的位置），以在您的每次造訪提供適配的預設選項。如果您不允许使用这些 Cookie，則可能無法實現部分功能。',

      'multi_checkout.items.title': '購物車',
      'multi_checkout.promotions.title': '優惠',
      'multi_checkout.change.payment_type.modal.title': '您正更改付款類型',
      'multi_checkout.change.payment_type.modal.description':
        '更改付款類型將需要重新選擇送貨方式及重新填寫送貨資訊',
      'multi_checkout.change.payment_type.modal.confirm': '變更',
      'multi_checkout.change.payment_type.modal.cancel': '取消',
      'multi_checkout.destination_section.title': '填寫目的地',
      'multi_checkout.destination_section.address_type.new': '新地址',
      'multi_checkout.destination_section.delivery_type.label':
        '已選擇的送貨方式：',
      'multi_checkout.destination_section.pick_store.label': '搜尋門市',
      'multi_checkout.destination_section.pick_store.store_code':
        '已選擇門市店號',
      'multi_checkout.destination_section.pick_store.store_name':
        '已選擇門市名稱',
      'multi_checkout.destination_section.pick_store.store_address': '門市地址',
      'multi_checkout.destination_section.dynamic_fee.hint':
        '運費將於填寫送貨地址後顯示',
      'multi_checkout.delivery_section.title': '選擇付款類型與送貨方式',
      'multi_checkout.delivery_section.country.label': '國家/地區',
      'multi_checkout.delivery_section.payment_type.label': '付款類型',
      'multi_checkout.delivery_section.delivery_option.label': '送貨方式',
      'multi_checkout.delivery_section.collapse.hint': '展開送貨提示',
      'multi_checkout.delivery_section.ncod': '非貨到付款',
      'multi_checkout.delivery_section.cod': '貨到付款',
      'multi_checkout.delivery_section.empty_delivery_methods': '沒有適用方式',
      'multi_checkout.summary_section.title': '訂單資訊',
      'multi_checkout.next_step': '下一步',
      'multi_checkout.delivery_attribute': '訂單',
      'multi_checkout.summary_section.delivery_type.label': '送貨方式：',
      'multi_checkout.summary_section.check_payment_instructions':
        '查看付款指示',
      'multi_checkout.summary_section.expand_payment_instructions':
        '展開付款指示',
      'multi_checkout.summary_section.confirm': '確認',
      'multi_checkout.cart_tag_unnamed': '(未命名)',
      'multi_checkout.checkout.destination.title': '目的地',
      'multi_checkout.checkout.delivery_time.description': '到貨時間說明：',

      'birthday_picker.year.placeholder': '年',
      'birthday_picker.month.placeholder': '月',
      'birthday_picker.date.placeholder': '日',

      'coupon_v2.discount_type.discount': '折扣券',
      'coupon_v2.discount_type.free_shipping': '免運券',
      'coupon_v2.discount_type.gift': '贈品券',
      'coupon_v2.period.start_at': '{{ date }} 開始',
      'coupon_v2.period.end_at': '截至 {{ date }}',
      'coupon_v2.copy_code': '複製折扣碼',
      'coupon_v2.copy_code.success': '複製成功',
      'coupon_v2.claim': '立即領取',
      'coupon_v2.claim.success': '恭喜獲得 {{ name }} 優惠券',
      'coupon_v2.claimed': '已領取',
      'coupon_v2.use': '立即使用',

      'user_reminder.member_referral.user_credit':
        '註冊為會員可獲得 {{ value }} 元購物金。',
      'user_reminder.member_referral.member_point':
        '註冊為會員可獲得 {{ value }} 點會員點數。',
      'user_reminder.member_referral.action': '立刻註冊',
      'user_reminder.coupon.member': '您有尚未領取的優惠券，請前往領取！',
      'user_reminder.coupon.guest': '請登入領取優惠券！',
      'user_reminder.coupon.action': '立即領取',

      'coupon_input.placeholder': '輸入優惠券代碼',
      'coupon_input.claim': '領取',
      'coupon_input.claim_succeed': '{{ couponName }} 領取成功！',
      'coupon_input.claim_succeed.redirect':
        '{{ couponName }} 領取成功！點擊前往會員中心查看',
      'coupon_input.claim_failed': '領取失敗',
      'verification.email.trial_merchant.reach_limit.failure':
        '商店試營運中，無法發送驗證郵件，如有需求請聯繫商店。',
      'gift.exceed.limit': '您選擇的贈品數量已超過上限',
      'gift.sold_out': '您選擇的贈品已贈完',
      'gift.variation.confirm': '確認規格',
    });
  },
]);
