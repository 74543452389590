app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('ja', {
      'languages.en': 'English',
      'languages.zh-hant': '中国語',
      'languages.jp': '日本語',
      'languages.id': 'インドネシア語',

      'form.action.delete': '削除',
      'recaptcha.language': 'jp',
      'paypal.language': 'ja_JP',
      close: 'とじる',
      back: 'もどる',

      'form.validation.required': '{{field_name}}は必須です',
      'form.validation.maxlength': '{{field_name}} が長すぎます',
      'form.validation.maxlength.with.number':
        '{{field_name}} は最大 {{ max_length }} 文字まで入力可能です',
      'form.validation.minlength': '{{field_name}} が短すぎます',
      'form.validation.invalid': '{{field_name}} は無効です',
      'form.validation.pattern': '{{field_name}} は無効です',
      'form.validation.pattern.recipient-name':
        '空白と記号は使用できません。{{limit_length}} 文字以内で入力してください',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} は 10 桁以内で入力してください',
      'form.validation.pattern.recipient-phone.fix_size':
        '配送先の電話番号は最大 {{ max_count }} 桁まで入力可能です',
      'form.validation.pattern.recipient-phone.above_size':
        '配送先の電話番号は最大 {{ max_count }} 桁まで入力可能です',
      'form.validation.pattern.recipient-phone.beginning_stint':
        '{{ stint_charts.split(",").join(" 或 ") }}から始まる配送先の電話番号を入力してください',
      'form.validation.pattern.711_cross_border.delivery_address':
        '配送方法は越境サービスとなるため、英語で入力する必要があります。',
      'form.validation.email': '{{field_name}} は無効です',
      'form.validation.id_no':
        '注意: 身分証に記載されている情報を入力してください。税関申告に影響する可能性があります',
      'form.validation.passport':
        '注意: パスポート番号など、実際の情報を入力してください。税関申告に影響する可能性があります',
      'form.validation.unique':
        '{{field_name}} は選択済みです。再入力してください。',
      'form.validation.confirmation': 'パスワードが違います',
      'form.validation.policy':
        '利用規約とプライバシーポリシーに同意してください',
      'form.validation.delivery_option.outlying':
        '選択された配送方法では離島地域に配送できません。台湾の住所を変更するか、別の配送方法を選択してください。',
      'form.validation.delivery_option.local':
        '選択された配送方法では台湾本島には配送できません。海外の住所に変更するか、別の配送方法を選択してください。',
      'form.validation.delivery_option.country':
        '選択した配送方法ではこの国・地域に配送できません。配送先住所を変更するか、別の配送方法を選択してください。',
      'form.validation.delivery_option.region':
        '選択された配送方法ではこの地域に配送できません。住所を変更するか、別の配送方法を選択してください。',
      'form.validation.delivery_option.cross_border.MY':
        '選択された配送方法では{{ area == "west" ? "西" : "東" }}に配送できません。住所を{{ area == "west" ? "東" : "西" }}に変更するか、別の配送方法を選択してください。',
      'form.validation.birthday.required': '生年月日は必須です',
      'form.validation.birthday.invalid': '生年月日が無効です',
      'form.validation.birthday.invalid.minimum_age_limit':
        '生年月日が無効です。{{minimumAgeLimit}} 歳以上である必要があります',
      'form.response.load.more.error':
        'データをロードできません。もう一度お試しください。',
      'form.validation.email.duplicate':
        'メールアドレスは選択済みです。再度入力してください。',
      'form.validation.email.hint': '正しいメールアドレスを入力してください',
      'form.validation.card.bin.error':
        '選択されたお支払い方法はこのクレジットカードに適用されません。必要な場合はストアオーナーまでご連絡ください。',
      'form.validation.card.bin.error.unknow_scheme':
        'クレジットカードの種類を確認できません。必要な場合はストアオーナーまでお問い合わせください。',
      'form.validation.calling_code': '国別コードは必須です',
      'form.validation.phone.required': '{{field_name}}は必須です',
      'form.validation.phone.error':
        '{{field_name}} は無効です。再度ご確認ください',
      'form.validation.mobile.error':
        '{{field_name}} は無効です。携帯電話番号を入力してください',
      'dropdown.hint': '選択してください',
      'redirect.go_home_in_seconds': '{{seconds}} 秒後にホームページに戻ります',

      'trial_order_dialog.title':
        '決済プロセスをご体験ください。全ストアで最大 10 回テスト注文を実行できます。',
      'trial_order_dialog.content':
        'このプロセスでは、メンバー加入、割引通知メールの購読、ショッピングカートへ商品の追加など、便利で豊富な機能を提供しています。注文制限数に達した場合、ビジネスオーナーはプランのアップグレードで注文制限数を変更することができます。',
      'trial_order_dialog.checkout': '支払いを始める',
      'trial_order_dialog.reach_limit.title': '受注制限数に達しました',
      'trial_order_dialog.reach_limit.content':
        'ストア運営が正式にスタートすると、ショッピングおよびお支払いを引き続き実行できます。 <br/>ストア運営に関するご質問は、ストアオーナーまでお問い合わせください。',
      'trial_order_dialog.reach_limit.check_plan':
        'ホームに戻って買い物を続ける',
      'trial_order_dialog.reach_limit.continue': 'ストアにお問い合わせ',

      'session.signup.title': '新規ユーザー登録',
      'session.terms.facebook': 'Facebookで登録',
      'session.signup.facebook': 'Faceboo 登録',
      'sessions.or': 'または',
      'session.terms':
        '登録すると、当社のプライバシー ポリシーおよび利用規約に同意したことになります。',
      'session.signin.submit': 'ログイン',
      'session.signin.title': 'ログイン',
      'session.signin.facebook': 'Facebook ログイン',
      'session.forgot_password': 'パスワードを忘れた',
      'session.mobile_signup.go_back': 'もどる',
      'session.mobile_signup.check_mobile.title':
        '携帯電話番号が正しく入力されているかどうかを確認してください。以下の携帯電話番号にSMSを送信します。',
      'session.mobile_signup.check_mobile.your_number': 'あなたの電話番号',
      'session.mobile_signup.check_mobile.your_number.hint':
        '台湾の携帯電話番号を入力してください (09 から始まる番号)',
      'session.mobile_signup.check_mobile.send_code': '認証コードを送信',
      'session.mobile_signup.check_mobile.go_back.1':
        '携帯電話番号を間違って入力した場合は、戻ってください',
      'session.mobile_signup.check_mobile.go_back.2': 'もどる',
      'session.mobile_signup.check_mobile.go_back.3': '編集',
      'session.mobile_signup.check_mobile.go_back.3.simplified': '編集',
      'session.mobile_signup.input_code.hint':
        '受け取ったSMS認証コードを入力してください',
      'session.mobile_signup.input_code.hint.simplified':
        '受け取った認証コードを入力してください',
      'session.mobile_signup.input_code.placeholder': '認証コード',
      'session.mobile_signup.input_code.submit': '送る',
      'session.mobile_signup.input_code.resend.countdown':
        '認証コードを再送 ({{resendCountdown}} 秒)',
      'session.mobile_signup.input_code.resend.countdown.simplified':
        '再送 ({{resendCountdown}} 秒)',
      'session.mobile_signup.input_code.resend': '認証コードを再送',
      'session.mobile_signup.input_code.resend.simplified': '再送',
      'session.forget_passowrd.check_phone.error':
        '無効な携帯電話番号です。再度確認してください',
      'session.mobile_center.check_phone.error':
        'この携帯番号はすでに登録されています',
      'user.sign_up.email_verification.dialog.title':
        '認証メールがに {{mail}} に送信されました',
      'user.sign_up.email_verification.dialog.description':
        'お早めにメールアドレスの認証を完了し、メールアドレスを更新してください。認証メールが届かない場合は、再度メールアドレスを変更して保存してください。',
      'user.sign_up.email_verification.dialog.confirm': '了解',
      'user.sign_up.check_phone.error':
        'これは既存の携帯電話番号です。変更が必要な番号を再度入力してください。',

      'user.quick_signup.title': 'メンバーになる',
      'user.quick_signup.title.content': '限定特典をお見逃しなく！',
      'user.quick_signup.promotion': '最新情報やお得情報を受け取る',
      'user.quick_signup.policy': 'ウェブサイトに同意する',
      'user.quick_signup.policy.url_content':
        '利用規約およびプライバシーポリシー',
      'user.quick_signup.policy_with_age':
        '私はウェブサイト<a href="{{ link }}">利用規約およびプライバシー ポリシー</a>に同意します。また、私は {{ age }} 歲以上です',
      'user.quick_signup.submit': '今すぐはじめる',
      'user.quick_signup.error.format': '携帯電話の形式に誤りがあります',
      'user.quick_signup.error.confirmed':
        'この携帯番号はすでに登録されています',
      'user.quick_signup.error.confirmed.url': 'ログインする',
      'user.quick_signup.validate.title': '登録を続ける',
      'user.quick_signup.validate.success':
        'すでにメンバーです！<br/>引き続き登録を完了する、<br/>または',
      'user.quick_signup.validate.success.shop': 'まずお買い物をする',
      'user.quick_signup.validate.finish_hint':
        '登録が完了すると、アカウントにログインしてショッピングクレジットを表示、使用できるようになります。',
      'user.quick_signup.validate.phone': '認証',
      'user.quick_signup.validate.send.sms': '認証SMSを送信',
      'user.quick_signup.validate.wrong.number':
        '携帯電話番号を間違って入力した場合は、戻ってください',
      'user.quick_signup.validate.previous': 'もどる',
      'user.quick_signup.validate.modify': '編集',
      'user.quick_signup.validate.hint.password': '8文字以上',
      'user.quick_signup.validate.hint.send':
        'SMSが送信されました。認証コードは {{count}} 秒後に再送できます。',
      'user.quick_signup.validate.hint.resend':
        'SMSが届かない場合、クリックして再送してください',
      'user.quick_signup.validate.submit': '登録完了',
      'user.quick_signup.validate.placeholder.sms': '認証コードを入力',
      'user.quick_signup.validate.placeholder.name': '名前',
      'user.quick_signup.validate.placeholder.password': 'パスワード設定',
      'user.delivery_data.recipient_phone': '配送先電話番号',
      'user.add_credit_card': '新しいクレジットカードを追加',
      'user.add_credit_card.success': 'カードが追加されました',
      'user.add_credit_card.fail':
        'カードを追加できませんでした。もう一度お試しください。',
      'user.add_credit_card.cancel': 'キャンセル',
      'user.add_credit_card.remind':
        'ご注意: クレジットカードを追加すると、SHOPLINE Payments が {{ label }} 円の認証履歴を取得します。この認証履歴に料金は発生しません。',
      'user.add_credit_card.submit': '送信',
      'user.credit_card.system.error':
        'システムにエラーが発生しました。後でもう一度お試しください。',
      'user.delete_credit_card': 'このカードを削除しますか?',
      'user.delete_credit_card.description':
        'このクレジットカードを削除すると、このカードでのお支払いができなくなります',
      'user.delete_credit_card.description.with_subscription_product':
        'このクレジットカードを削除すると、このカードをクイックペイで使用できなくなります。定期購入商品にこのカードを使用している場合、次回注文時に決済ができない場合があります。決済に失敗した場合は、メンバーセンター > 注文ページ　から再度お支払いください。',
      'user.delete_credit_card.success': 'カードが削除されました',
      'user.delete_credit_card.fail':
        '削除に失敗しました。もう一度お試しください',
      'user.delete_credit_card.cancel': 'キャンセル',
      'user.delete_credit_card.delete': '削除する',
      'user.credit_card.expired': 'カードの有効期限が切れています',
      'user.credit_card_list.delete': '削除',
      'user.credit_card_list.processing': '追加',

      'product.out_of_stock': '在庫切れ',
      'product.addon_products.label': '追加商品',
      'product.bundled_products.label': 'オプション特典',
      'product.bundle_group_products.label': 'A+Bの組み合わせ',
      'product.buyandget.label': 'X 購入で Y 獲得',
      'product.gift.label': 'ギフト',
      'product.subscription_product.label': '定期購入',
      'product.addon_products.errors.main_product_out_of_stock':
        'メイン商品の在庫が不足しています',
      'product.addon_products.errors.reached_max_purchase_quantity':
        '商品の購入数は上限 {{ message }} 点です',
      'product.addon_products.errors.addon_product_out_of_stock':
        '追加商品の在庫が不足しています',
      'product.addon_products.errors.larger_addon_product':
        '追加商品の数は、メインの商品の数を超えることはできません',
      'product.addon_products.errors.out_of_stock':
        '{{title}} の在庫が不足しています',
      'product.addon_products.tips.limit_exceed':
        '商品の数量を調整する場合、追加商品の数はメインの商品の数を超えることはできません',
      'product.addon_products.add_to_cart.hint':
        'ショッピングカートに追加されました',
      'product.available_time_info.start.1': '商品は',
      'product.available_time_info.start.2': '販売中',
      'product.available_time_info.end.1': '商品は',
      'product.available_time_info.end.2': '販売終了',
      'product.view_more': 'もっと見る',
      'product.view_less': '内容をとじる',
      'product.add_to_cart': 'カートに追加',
      'product.add_to_cart_fail': 'ショッピングカートがいっぱいです!',
      'product.buy_now': '今すぐ購入',
      'product.buy_now.preorder.hint': '商品は予約注文中です',
      'product.buy_together': 'まとめて購入',
      'product.preorder_limit.hint':
        '在庫が不足しています。購入数量に予約商品が含まれています',
      'product.coming_soon': 'まもなく販売',
      'product.available_time_over': '販売終了',
      'product.store_stock.check_stock': '受け取り可能店舗を確認',
      'product.set.open_variation': '商品の詳細見る',

      'product.amazon_product_review.show_more': 'もっと見る',

      'product_review_comments.orders.back_to_my_account':
        '< メンバーセンターに戻る',
      'product_review_comments.orders.comment': 'コンテンツ',
      'product_review_comments.orders.comment_placeholder':
        '（オプション）商品にはご満足いただけましたか？レビューで感想をお聞かせください！',
      'product_review_comments.orders.orders_to_view': 'レビュー待ちの注文',
      'product_review_comments.orders.order_id': '注文番号：',
      'product_review_comments.orders.purchased_date': '購入日：',
      'product_review_comments.orders.rate_other_order': '他の注文をレビュー',
      'product_review_comments.orders.review': 'レビューする',
      'product_review_comments.orders.send': '公開',
      'product_review_comments.orders.empty.title':
        'レビューできる商品はありません',
      'product_review_comments.orders.finished_review.title':
        'レビューありがとうございます！',
      'product_review_comments.orders.finished_review.desc.user_credit':
        'ありがとうございます！レビュー特典 {{ value }} のショッピング クレジットを進呈させていただきます。',
      'product_review_comments.orders.finished_review.desc.member_point':
        'ありがとうございます！レビュー特典 {{ value }} ポイントを進呈させていただきます。',
      'product_review_comments.orders.max_length_of_characters': '2000文字以内',
      'product_review_comments.orders.write_a_review': 'レビューを投稿',
      'product_review_comments.orders.upload_image':
        '画像をアップロード（最大9枚）',
      'product_review_comments.orders.completed_all_reviews':
        'ありがとうございます！すべてのレビューが完了しました！',
      'product_review_comments.orders.back_to_the_store': '買い物にすすむ',

      'product.product_review.out_of_5_stars': '点',
      'product.product_review.rating_source_amazon': 'レビュー元：Amazon',
      'product.product_review.rating_source_shopline': 'レビュー元：当ストア',
      'product.product_review.reviews': 'レビュー',
      'product.product_review.stars': 'ポイント',
      'product.product_review.no_review': 'まだレビューはありません',
      'product_review_entry.review_description_title':
        '皆様のレビューをお待ちしております！',
      'product_review_entry.review_description_title.user_credit':
        '皆様のレビューをお待ちしております！評価後すぐに {{ value }} ショッピングクレジットを進呈！',
      'product_review_entry.review_description_title.member_point':
        '皆様のレビューをお待ちしております！評価後すぐに {{ value }} ポイントを進呈！',
      'product_review_entry.review_description_content':
        'お買い上げいただいた商品はいかがでしたでしょうか？ぜひ感想レビューをお願いします！',
      'product_review_entry.review_button_text': 'レビュー',

      'member.center.load.more': 'もっと読み込む',
      'member.center.load.more.error':
        'データのロードに失敗しました。もう一度お試しください。',

      'wishlist.signin_register': 'ログイン・今すぐ登録',
      'wishlist.messages.remove_error': 'お気に入り商品を削除できません。',
      'back_in_stock.subscibe.success.message':
        '商品が公開されると通知を送信する',
      'back_in_stock.added': '配送通知を登録しました',
      'back_in_stock.avaiable': '商品が到着したら通知',

      orders: '注文',
      order: '注文',
      'orders.fields.billing_address': '請求先住所',
      'orders.fields.order_number': '注文番号',
      'orders.fields.order_date': '注文日',
      'orders.fields.order_status': '注文ステータス',
      'orders.fields.order_remarks': '顧客の注文に関する備考',
      'orders.fields.customer_name': '顧客名',
      'orders.fields.product_subscription_period': '{{period}}回目',
      'orders.fields.status.temp': '注文処理中',
      'orders.fields.status.pending': '注文処理中',
      'orders.fields.status.confirmed': '確認済み',
      'orders.fields.status.shipped': '出荷済み',
      'orders.fields.status.completed': '到着',
      'orders.fields.status.received': '受取済み',
      'orders.fields.status.cancelled': 'キャンセル済み',
      'orders.fields.status.returning': '返品中',
      'orders.fields.status.returned': '返品済み',
      'orders.fields.customer': '顧客',
      'orders.fields.preorder_item': '予約商品',
      'orders.fields.customer.phone': '顧客電話番号',
      'orders.fields.billto': '請求書',
      'orders.fields.payment_type': '支払い方法',
      'orders.fields.payment_instructions': '支払い手順',

      'orders.fields.order_delivery.pending': '在庫あり',
      'orders.fields.order_delivery.shipping': '配送中',
      'orders.fields.order_delivery.shipped': '出荷済み',
      'orders.fields.order_delivery.arrived': '到着済み',
      'orders.fields.order_delivery.collected': '受取済み',
      'orders.fields.order_delivery.returning': '返品中',
      'orders.fields.order_delivery.returned': '返品済み',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        '取引に失敗しました: コンビニコードの支払上限額は NT$20000 です',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        '取引に失敗しました: コンビニコードの最低支払額は NT$30 です',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        '取引に失敗しました: コンビニコードの支払上限額は NT$20000 です',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        '取引に失敗しました: コンビニコードの最低支払額は NT$30 です',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'コンビニ払いの上限は1回NT$30,000です',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'コンビニ払いの上限は1回NT$20,000です',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'オンラインATM払いの限度額は1回NT$30,000です',
      'orders.fields.delivery_method': '配送方法',
      'orders.fields.delivery_description': '配送方法について',
      'orders.fields.delivery_fee': '配送先',
      'orders.fields.delivery_address': '配送先住所',
      'orders.fields.delivery_address.remarks': '配送に関する備考',
      'orders.fields.options.blacklist.error':
        'あなたの商品はこの配送方法または支払い方法に適応していません。再度商品情報をご確認の上、他のオプションをご選択いただくか、別途ご注文ください。',
      'address.fields.address': '住所',
      'address.fields.city': '市',
      'address.fields.postcode': '郵便番号',
      'address.fields.address.state': '地区・州・県',
      'address.fields.country': '国・地域',

      'orders.fields.subtotal': '小計',
      'orders.fields.total': '合計',
      'order.billing_address.same': '配送先住所に同じ',

      'orders.coupons.label': 'クーポンコード',
      'orders.coupons':
        'おめでとうございます！以下の特典をお楽しみいただけます。',
      'orders.coupons.placeholder': 'クーポンコードを入力してください',
      'orders.promotion_coupons.placeholder': '割引コードを入力してください',

      'orders.coupons.apply': '使用',
      'orders.fields.order_discount': '割引',
      'orders.fields.order_custom_discount': 'カスタム割引合計',
      'orders.coupons.invalid': 'あなたのクーポンは適用されません。',
      'orders.coupons.validation.minamount':
        'ご注文金額が最低購入金額を満たしていないため、クーポンは適用されません。',
      'orders.coupons.invalid.order':
        '決済前に無効なクーポンまたはコードを削除してください',

      'orders.actions.title': '注文ステータス管理',
      'orders.action.cancel': '注文をキャンセルする',
      'orders.action.confirm': '注文確認',
      'orders.action.ship': '出荷済みとしてマーク',
      'orders.general.title': '注文情報',
      'orders.payment.title': '支払い情報',
      'orders.delivery.title': '発送・配送情報',
      'orders.products.title': '商品の詳細',

      'orders.fields.accept_terms':
        '利用規約およびプライバシーポリシーに同意します。',
      'orders.fields.accept_terms.validation.required':
        '利用規約とプライバシーポリシーに同意してください',
      'orders.show.message.thankyou.title':
        'ご注文いただきありがとうございます',
      'orders.show.thankyou.continue': 'お買い物を続ける',
      'orders.show.message.shipped.title': 'ご注文は出荷されました',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': '注文金額',
      'orders.payment_slip.order_info.instructions': '支払いについて',
      'orders.payment_slip.order_info.read_more': '詳しく見る',
      'orders.payment_slip.upload_form.title':
        '振込完了後、振込明細をアップロードしてください',
      'orders.payment_slip.upload_form.hint':
        '入金確認後、早急に発送手配させていただきます！',
      'orders.payment_slip.upload_form.notification':
        '最新の注文情報は、登録後ご確認いただけます',
      'orders.payment_slip.upload_form.image_input.title':
        '振込明細をアップロード',
      'orders.payment_slip.upload_form.image_input.description':
        '画像が鮮明であり、振込後の5桁の番号、振込時間、入金額が記載されていることをご確認ください。',
      'orders.payment_slip.upload_form.image_input.change':
        'クリックして画像を変更',
      'orders.payment_slip.upload_form.image_input.error':
        '画像は 10MB 以下にしてください',
      'orders.payment_slip.upload_form.paid_time': '支払い時間を選択',
      'orders.payment_slip.upload_form.message': '支払い関する備考を入力',
      'orders.payment_slip.upload_form.message.error':
        '{{ n }} 字以内で入力してください',
      'orders.payment_slip.uploaded.alert':
        '振込明細がアップロードされました。早急にご確認させていただきます。',
      'orders.payment_slip.uploaded.notification':
        '登録して最新の注文情報を確認',
      'orders.payment_slip.uploaded.title': '以下が振込の明細です',
      'orders.payment_slip.uploaded.paid_time': 'お支払い時間:',
      'orders.payment_slip.uploaded.upload_time': 'アップロード時間:',
      'orders.payment_slip.uploaded.order_link': '注文を確認',
      'orders.payment_slip.uploaded_without_login.title':
        'この注文のお支払い明細がアップロードされました',
      'orders.payment_slip.uploaded_without_login.hint':
        'ログインして詳細を表示できます',
      'orders.payment_slip.footer':
        '入力いただいた情報は注文内容の確認および照合にのみ使用され、暗号化されます。ご安心して支払い手続きをおすすめください。',

      // customer cancel order section
      'orders.show.check_order.title': '注文を確認',
      'orders.show.check_order.description':
        '<a href="{{pagePath}}">メンバー センター > 注文</a> から注文を表示またはキャンセルができます。',
      'orders.show.customer_cancel_order.confirm.title':
        '注文をキャンセルしますか?',
      'orders.show.customer_cancel_order.confirm.description.integrated':
        'キャンセルの理由を教えてください。改善に努めさせていただきます。 <br>キャンセル完了後に返金させていただきます。またのご来店を心よりお待ちしております！',
      'orders.show.customer_cancel_order.confirm.description.non_integrated':
        'キャンセルの理由を教えてください。改善に努めさせていただきます。 <br>できるだけ迅速に処理させていただきますので、しばらくお待ちください。',
      'orders.show.customer_cancel_order.confirm.loading.title': '処理中',
      'orders.show.customer_cancel_order.confirm.loading.description':
        'ページを閉じたり、前のページに戻らず、しばらくお待ちください。',
      'orders.show.customer_cancel_order.confirm.success.title':
        'キャンセルリクエストが送信されました',
      'orders.show.customer_cancel_order.confirm.success.description':
        'キャンセルが完了するとメールで通知が送信されます。',
      'orders.show.customer_cancel_order.confirm.failed.title':
        '注文をキャンセルできませんでした。もう一度お試しください。',
      'orders.show.customer_cancel_order.confirm.failed.description':
        '再試行に失敗した場合は、以下の「販売者と顧客の注文に関する情報」にもとづいて、当社までご連絡ください。',
      'orders.show.customer_cancel_order.button.cancel': '注文をキャンセルする',
      'orders.show.customer_cancel_order.button.ok': '了解',
      // customer cancel order reasons
      'orders.show.customer_cancel_order.reasons.default':
        'キャンセル理由を選択してください',
      'orders.show.customer_cancel_order.reasons.change_mind':
        'キャンセルをやめる',
      'orders.show.customer_cancel_order.reasons.expensive_price':
        '価格が他より高い',
      'orders.show.customer_cancel_order.reasons.repeat_purchase':
        '重複購入してしまった',
      'orders.show.customer_cancel_order.reasons.wait_too_long':
        '発送が遅すぎる',
      'orders.show.customer_cancel_order.reasons.change_item':
        '購入商品を変更したい',
      'orders.show.customer_cancel_order.reasons.incorrect_customer_info':
        '配送先または支払い情報が間違っている',
      'orders.show.customer_cancel_order.reasons.other': 'その他の理由',
      'orders.show.customer_cancel_order.reasons.other.placeholder':
        '今後の改善のため、理由をお聞かせください。',
      'orders.show.customer_cancel_order.reasons.hint': 'キャンセル理由',

      'orders.index.no_records.title': '注文はありません',
      'orders.index.no_records.description': 'このストアの注文はありません。',
      'orders.index.no_records.action': 'お買い物を続ける',
      'orders.index.title': '注文',
      'orders.index.action.view': '確認',
      'orders.action.checkout': '支払いに進む',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        '配送先',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        'セブンイレブン店舗名',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        'セブンイレブン店舗番号',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        '配送先',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'ファミリーマートサービス番号（店舗番号ではありません）',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'ファミリーマート店舗名',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        'セブンイレブン',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        'ファミリーマート',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label': '店舗',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        '配送方法に誤りがあります。更新するか、<a href="/cart" class="alert-highlight">前のページに戻って配送方法を変更してください</a>',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        '住所に誤りがあります。再度選択してください。',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        '店舗配送番号は必須です',

      'checkout_object.detail.close': 'とじる',
      'checkout_object.detail.title': '消費金額明細',

      'orders.promotion_type.promotion': '割引プロモーション',
      'orders.promotion_type.reward_credit': '満額ショッピングクレジット',
      'orders.promotion_type.bundle_pricing': 'オプション特典',
      'orders.promotion_type.bundle_percentage': 'オプション特典',
      'orders.promotion_type.bundle_amount': 'オプション特典',
      'orders.promotion_type.bundle_gift': 'オプション特典',
      'orders.promotion_type.bundle_group': 'A+Bの組み合わせ',
      'orders.promotion_type.bundle_group_percentage': 'A+Bの組み合わせ',
      'orders.promotion_type.bundle_group_amount': 'A+Bの組み合わせ',
      'orders.promotion_type.bundle_group_gift': 'A+Bの組み合わせ',
      'orders.promotion_type.buyandget_free': 'X 購入で Y 獲得',
      'orders.promotion_type.buyandget_pricing': 'X 購入で Y 獲得',
      'orders.promotion_type.buyandget_percentage': 'X 購入で Y 獲得',
      'orders.promotion_type.coupon': '割引コード',
      'orders.promotion_type.membership_offer': '会員割引',
      'orders.promotion_type.custom_discount': 'カスタム割引',
      'orders.promotion_type.member_point_redeem_gift': '引き換えキャンペーン',
      'orders.promotion_type.free_shipping': '送料無料キャンペーン',
      'orders.promotion_type.credit_reward': 'ショッピングボーナス',

      'orders.reward_credit.value': '{{ credit_value }} ショッピングクレジット',
      'orders.reward_credit.auto_reward':
        '{{ Credit_threshold }} で{{ Credit_value }} のショッピング クレジットを獲得',
      'orders.reward_credit.accumulated_auto_reward':
        '{{ Credit_threshold }} ごとに {{ Credit_value }} のショッピング クレジットを獲得',
      'orders.reward_credit.balance':
        'あと{{ auto_reward_balance }} 足りません',
      'orders.reward_credit.faq':
        'お支払い完了後にショッピングクレジットが進呈されます。次回のお買い物にご利用いただけます。',
      'orders.reward_credit.earned_after_order_completed_faq':
        'このキャンペーンは、商品割引後の金額にもとづいてクレジットが計算されます。注文完了後ショッピングクレジットが進呈されし、次回のお買い物にご利用ただけます！',

      'orders.fields.applied_user_credits': 'ショッピングクレジット割引',
      'orders.fields.applied_member_point_redeem_to_cash': 'ポイントで現金割引',
      'orders.fields.delivery_fee_free': '送料無料',
      'orders.fields.remaining_member_points_without_balance':
        '今回の使用ポイント： {{ applied }} ポイント',
      'orders.fields.user_credits': 'ショッピングクレジット',
      'orders.fields.rounding': '四捨五入 / 切り捨て',
      'orders.fields.credits_earned_after_paid':
        '支払い後にショッピングクレジット獲得',

      'orders.member_point.fields.redeemed': 'ポイントでギフト交換',
      'orders.member_point.unit': 'ポイント',
      'orders.member_point.settled_after_order_completed':
        '注文完了後ポイント獲得',
      'orders.member_point.pos_settled_content':
        '実店舗での注文完了から約 {{ pending_days }} 日後ポイント獲得',
      'orders.member_point.settled_after_content':
        '受取り {{ pending_days }} 日後ポイント獲得',

      // Checkout page error message field name
      'order.customer_name': '顧客名',
      'order.email': 'メールアドレス',
      'order.customer_email': '顧客のメールアドレス',
      'order.customer_phone': '顧客電話番号',
      'order.delivery_data.recipient_name': '配送先氏名',
      'order.delivery_data.recipient_phone': '配送先電話番号',
      'order.delivery_data.consignee_id_no':
        '配送先の身分証番号またはパスポート番号',
      'order.delivery_address.address_1': '住所',
      'order.delivery_address.city': '市・町',
      'order.delivery_address.postcode': '郵便番号',
      'order.delivery_address.state': '地区・州・県',
      'order.delivery_data.location_code': '店舗番号',
      'order.delivery_data.location_name': '店舗名',
      'order.delivery_data.time_slot': '配送日時',
      'order.delivery_data.scheduled_delivery_date': '日付を選択',
      'order.delivery_data.time_slot_key': '時間を選択',
      'order.invoice.carrier_number': 'バーコード',
      'order.invoice.mailing_address': '請求書の住所',
      'order.invoice.tax_id': '登録番号',
      'order.payment_data.holdername': 'クレジットカード名義人名',
      'order.payment_data.expiry_date': '有効期限',
      'order.payment_data.cvc': 'セキュリティコード（CVC）',
      'order.payment_data.credit_card_number': 'クレジットカード番号',
      'order.billing_address.title': '請求先住所',

      'lock_inventory.locked_quantity_unit': '件',

      'delivery.sfexpress.district': 'SF Expressエリア',
      'delivery.sfexpress.store': 'SF Express 住所',
      'delivery.sfexpress.locker_location': 'スマートキャビネットエリア',
      'delivery.sfexpress.ef_locker': 'スマートキャビネット',
      'delivery_address.region.hk': 'エリア',
      'delivery_address.district.hk': 'エリア',
      'delivery_address.region.tw': '市・県',
      'delivery_address.district.tw': 'エリア',
      'save_fields.customer_info.birthday': '顧客の生年月日',
      'delivery_address.province.vn': '県・市',
      'delivery_address.district.vn': '郡・町',
      'delivery_address.ward.vn': '社・坊',
      'delivery_address.province.th': '府',
      'delivery_address.district.th': '県・郡',
      'delivery_address.ward.th': '区',
      'delivery_address.street': '通り',
      'delivery_address.store': '{{ station_name }} 店',

      'delivery_options.fields.delivery_type': '配送カテゴリ',
      'delivery_options.fields.delivery_types.pickup':
        '対面受取り・セルフ受取り',
      'delivery_options.fields.delivery_types.local': '現地郵送',
      'delivery_options.fields.delivery_types.email': 'メールアドレス',
      'delivery_options.fields.delivery_types.international': '海外郵送',
      'delivery_options.fields.delivery_types.custom': 'カスタム',
      'delivery_options.fields.delivery_types.tw_simple_711':
        'セブンイレブンで受取り (台湾のみ)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'ファミリーマートで受取り（台湾のみ）',
      'delivery_options.fields.select_date': '日付を選択',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': '店舗検索',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        '配送先店舗コード',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        '配送先店舗名',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label': '配送先',
      'orders.fields.delivery_data.tw_ezship.sn_id.label':
        'コンビニ発行追跡番号',

      'delivery_fee.pay.on.delivery':
        '送料は受取り時にご請求させていただきます',

      'ezship.error.invalid_input': '内容に誤りがあるか、もしくは空欄です',
      'ezship.error.account_not_exist': 'ezShip アカウントが存在しません',
      'ezship.error.no_permission': 'ezShip アカウント権限がありません',
      'ezship.error.no_easybag':
        'ezShip アカウントで利用できるイージーバッグまたはミニバッグはありません',
      'ezship.error.invalid_store': '受取り店舗が間違っています',
      'ezship.error.invalid_amount':
        '金額が間違っています。 ezShip の上限金額は 1 回の請求で NT$6,000 です。',
      'ezship.error.invalid_email': 'メールの形式が間違っています',
      'ezship.error.invalid_mobile': '電話番号の形式が間違っています',
      'ezship.error.invalid_name':
        '配送先の内容が間違っているか、または空欄です',
      'ezship.error.system_error':
        'ezShip システム エラーです。別の配送方法を選択するか、後でもう一度お試しください。',

      'payments.fields.types.allpay_barcode': "コンビニバーコード (O'Pay 経由)",
      'payments.fields.types.allpay_cvs': "コンビニコード (O'Pay 経由)",
      'payments.fields.types.allpay_credit': "クレジットカード（O'Pay経由）",
      'payments.fields.types.allpay_webatm': 'オンラインATM（Oppay経由）',
      'payments.fields.types.asiapay': "クレジットカード（O'Pay経由）",
      'payments.fields.types.paypal': 'クレジットカードまたはPaypal',
      'payments.fields.types.paypal_express': 'クレジットカードまたはPaypal',
      'payments.fields.types.credit_card': 'クレジットカード',
      'payments.fields.types.bank_transfer': '銀行振込・ATM',
      'payments.fields.types.cash_on_delivery': '代金引換',
      'payments.fields.types.ezship': 'コンビニ代金引換（ezShip経由）',
      'payments.fields.types.free_checkout': '無料決済',
      'payments.fields.types.custom': 'カスタム支払い方法',
      'payments.fields.types.prizm': 'クレジットカード Visa・Master',
      'payments.fields.types.esun': '玉山クレジットカード',

      'payment.cc.expirydate': '有効期限',
      'payment.cc.holdername': 'クレジットカード名義人名',
      'payment.cc.number': 'クレジットカード番号（Visa ・ Master）',
      'payment.cc.cvc': 'セキュリティコード（CVC）',
      'payment.cc.expirydate.placeholder': 'MM/YY',
      'payment.cc.holdername.placeholder': 'クレジットカード名義人名',
      'payment.cc.holdername.hint': 'クレジットカード上の名前と同じ',
      'payment.cc.number.placeholder':
        'クレジットカード番号 (スペースを含まない)',

      'payment.cc.cvc.placeholder': 'セキュリティコード（CVC）',
      'payment.cc.cvc.hint':
        '通常、クレジットカード裏面に記載されている3桁のコードです',
      'payment.action.confirm': '了解',
      'payment.cc.update.instructions':
        'クレジットカード情報を入力して更新してください。',
      'payment.cc.fields.accept_terms':
        '利用規約およびプライバシーポリシーに同意します。',
      'payment.cc.fields.accept_terms.validation.required':
        '利用規約とプライバシーポリシーに同意してください',
      'payment.cc.cashier.footer':
        'キャッシュフローサービスはSHOPLINE Paymentsにより提供されています',
      'payment.cc.tw.cashier.footer':
        '決済サービスは、PCI-DSS 国際クレジットカード機関における最高レベルの認証を取得したSHOPLINE Payments により提供されています。安全な取引サービスおよび国内外でのクレジットカード利用をサポートしています。',

      cart: 'ショッピングカート',
      'cart.title': 'ショッピングカート',
      'cart.clean': '今すぐ支払う',
      'cart.over_limit':
        'ショッピング カート内の商品数が上限 <span class="limit-number">{{limit_number}}</span> を超えています。お支払いを完了してから再度追加してください。またメンバーログイン後、この商品をリストに追加することもできます。',
      'cart.over_limit.login':
        'ショッピング カート内の商品数が上限 <span class="limit-number">{{limit_number}}</span> を超えています。お支払いを完了してから再度追加してください。またこの商品をリストに追加することもできます。',
      'cart.item.remove': '削除',
      'cart.items.remove': '削除',
      'cart.checkout': '注文支払い',
      'cart.empty': 'ショッピングカートは空です',
      'cart.empty.description': 'ショッピングカートに商品を追加してください',
      'cart.empty.continue': 'お買い物を続ける',
      'cart.promotion.checkout': 'ショッピングカートへ',
      'cart.promotion.has_items_selected': '<b>{{quantity}}</b> 点選択済み',
      'cart.promotion.bundle_group.has_items_selected':
        'A セット <b style="color: #f05c53">{{red_quantity}}</b> 件{{red_reminder}}，B セット <b style="color: #1ba462">{{green_quantity}}</b> 件{{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'A セット <b style="color: #f05c53">{{red_quantity}}</b> 件，B セット <b style="color: #1ba462">{{green_quantity}}</b> 件',
      'cart.redeem_gift.has_items_selected':
        'ギフトは <span>{{total_point}}</span> ポイントで引き換え可能です。あと <span>{{rest_point}}</span> ポイントです',
      'cart.redeem_gift.has_items_selected.insufficient_point':
        '引き換え可能ポイントが <span>{{rest_point}}</span> ポイントをオーバーしています。ギフトを削除して調整してください。',
      'cart.copy.info.copied': 'コピー済み URL:',
      'cart.copy.info.affiliate_code': '紹介コード ({{code}})、',
      'cart.copy.info.product_count': '{{count}} 点の商品',

      'cart.promotion_reminder.separator': '、',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">セットAには </span> あと {{gap}} 点必要です',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">セットBには </span> あと {{gap}} 点必要です',
      'cart.promotion_reminder.group_gap': '(あと {{gap}} 点)',
      'cart.promotion_reminder.item': 'あと {{gap}} 点購入で',
      'cart.promotion_reminder.amount': 'あと{{gap}} 点購入で',
      'cart.promotion_reminder.multiple_step': 'さらにお得！',
      'cart.promotion_reminder.multiple_step.reward_campaign': 'さらに還元！',
      'cart.promotion_reminder.stackable': '買えば買うほどお得！',
      'cart.promotion_reminder.applied.free_shipping': '送料無料！',
      'cart.promotion_reminder.applied.gift': 'ギフト獲得！',
      'cart.promotion_reminder.applied.discount_prefix': '、',
      'cart.promotion_reminder.applied.discount': '割引をご利用いただけます',
      'cart.promotion_reminder.applied.reward_campaign':
        '割引をご利用いただけます',
      'cart.promotion_reminder.applied_highest.free_shipping': '送料無料です！',
      'cart.promotion_reminder.applied_highest.gift': 'ギフト獲得！',
      'cart.promotion_reminder.applied_highest.discount':
        '割引をご利用いただけます！',
      'cart.promotion_reminder.applied_highest.reward_campaign':
        '割引をご利用いただけます！',
      'cart.promotion_reminder.discount_target.free_shipping':
        '今なら送料無料！',
      'cart.promotion_reminder.discount_target.gift': '今すぐギフト獲得！',
      'cart.promotion_reminder.discount_target.reward_campaign': '今すぐ割引！',
      'cart.promotion_reminder.discount_target.discount': '今すぐ割引！',
      'cart.share.btn.tooltip': 'カート内のアイテムをコピーしてシェア',
      'cart.lock_cart_sc_product.description':
        'これはライブ配信・投稿販売の商品のため、購入数量を変更することはできません。',
      'cart.lock_cart_sc_product.remove_hint':
        'ライブ配信・投稿販売の商品のため削除できません。',
      'cart.coupon_code.apply_success': 'クーポン使用済み',
      'cart.affiliate_code.apply_success':
        'アフィリエイトコードが適用されました',

      'checkout.instructions': '注文情報',
      'checkout.fields.email': 'メールアドレス',
      'checkout.fields.email.hint':
        'メールアドレスを正しく入力してください。注文に関するすべての情報（確認、お問い合わせなど）はメールで送信されます。',
      'checkout.fields.phone.hint':
        'SMSで配送に関するお知らせを受信します。携帯電話番号を入力してください',
      'checkout.fields.email.placeholder': 'メールアドレス',
      'checkout.fields.phone': '電話番号',
      'checkout.fields.phone.placeholder': '電話番号',
      'checkout.fields.remarks': '注文に関する備考',
      'checkout.fields.remarks.placeholder':
        'ストアへの連絡事項を入力してください',
      'checkout.fields.delivery_method': '配送方法',
      'checkout.delivery_option.title': '配送先住所',
      'checkout.delivery_option.out_of_stock':
        '在庫切れの商品があるため、当店ではお選びいただくことができません。別の店舗に変更してください。',
      'checkout.payment_method.title': '支払情報',
      'checkout.fields.out_of_stock':
        '選択した商品の在庫がありません。削除して調整してから再度ご注文ください。',
      'checkout.fields.add_new': '追加',
      'checkout.fields.select': '--選択--',
      'checkout.instalment_amount': '約{{amount}} x {{period}}回',
      'checkout.labels.promotion.free_shipping': '送料無料',
      'checkout.labels.promotion.coupon': 'クーポン',
      'checkout.labels.promotion.promotion': '割引プロモーション',
      'checkout.labels.promotion.membership': '会員割引',

      'checkout.discount.applied_promotions': 'すでに割引を利用済みです',
      'checkout.discount.applied_coupons': 'すでに割引を利用済みです',

      'checkout.promotion.tags.promotion': '割引プロモーション',
      'checkout.promotion.tags.coupon': 'クーポン',
      'checkout.promotion.tags.membership': '会員割引',
      'checkout.promotion.tags.free_shipping': '送料無料',
      'checkout.promotion.tags.subscription_promotion': '定期購入割引',

      'checkout.payment_condition.title': 'あと {{difference}}点',
      'checkout.payment_condition.description':
        'ショッピングカートに戻って他の支払い方法を選択するか、または引き続きお買い物をしていただくと、お支払いに進むことができます。',
      'checkout.payment_condition.continue_shopping': 'お買い物を続ける',
      'checkout.payment_condition.hint':
        'この注文は最低金額以上からお支払いすることができます',
      'checkout.payments.exist.order.tip':
        'You have already placed order <a href="{{ duplicateOrderLink }}">{ {{order}} }</a>. If you wish to complete the checkout, please click “Place Order”.',

      'checkout.payments.apple_pay.not_supported':
        'Apple Payでのお支払いをご利用いただけません：<ul><li>デバイスおよびブラウザがApple アカウントにログインしているか確認してください。</li><li> Apple Pay アカウントと連携しているカードの有効期限を確認してください。</li> <li>デバイスおよびブラウザが最新バージョンにアップデートされているか、また Apple Pay をサポートしているか確認してください。</li></ul>',
      'checkout.payments.google_pay.not_supported':
        'Google Payでのお支払いをご利用いただけません：<ul><li>デバイスおよびブラウザがGoogle アカウントにログインしているか確認してください。</li><li> Google Pay アカウントと連携しているカードの有効期限を確認してください。</li> <li>デバイスおよびブラウザが最新バージョンにアップデートされているか、また Google Pay をサポートしているか確認してください。</li></ul>',
      'checkout.payment_failed':
        'お支払いに失敗しました。もう一度お試しください',
      'checkout.payment_failed.atome.minamount':
        '注文金額がお支払いの限度を満たしていません。ショッピングカートの内容を調整していただくか、他のお支払い方法に変更してください。',
      'checkout.payment_failed.check_credit_card_info':
        'カード情報をご確認ください。ご入力内容に間違いがない場合は、カード発行会社にご確認いただくか、またはストアまでお問い合わせください。',
      'checkout.cart_over_limit_error':
        '商品合計数は上限 100 個に制限されています。数量を調整してからご購入ください。',
      'checkout.timeout':
        'お支払いにエラーが発生しました。再注文されると、注文が重複する可能性があります。まずお支払い状況をご確認の上、お問い合わせください。',
      'checkout.card_info_error':
        'クレジットカード情報を確認後、もう一度お試しください',
      'checkout.station_space_confirm_error':
        '店舗の選択に失敗しました。もう一度お試しいただくか、お問い合わせください。',
      'checkout.payments_transaction_error':
        'この取引に問題があります。入力したお支払い情報を再度ご確認いただくか、カード発行会社にご確認ください。',
      'checkout.card_type_not_support_error':
        'この種類のクレジットカードは現在利用できません。',

      'checkout.auto_fill.popup.title':
        'エクスプレスチェックアウト用の携帯電話番号を入力してください',
      'checkout.auto_fill.popup.continue': '続ける',
      'checkout.auto_fill.popup.error': '正しい形式で入力してください',
      'checkout.auto_fill.popup.cancel': 'キャンセル',
      'checkout.auto_fill.toast.error':
        '以前の注文履歴が検出されない場合、エクスプレスチェックアウトのデータは取り込まれません。',

      'address.fields.recipient_name': '配送先',
      'address.fields.address.recipient_name': '配送先',
      'address.fields.address.city': '都市',
      'address.fields.address.postcode': '郵便番号 (該当する場合)',
      'address.fields.address.country': '国・地域',
      'address.fields.address.remarks': '備考',

      'messages.form.send': '送信',
      'action.add_photo': '画像を追加',
      'action.send': '送信',

      'action.load_earlier': '読み込む前',
      'action.load_more': 'もっと読み込む',
      'users.fields.name': 'ユーザー名',
      'users.fields.email': 'メールアドレス',
      'users.fields.mobile': '携帯電話番号',
      'users.fields.mobile_phone_or_email': 'メールアドレスまたは携帯電話番号',
      'users.fields.password': 'パスワード',
      'users.fields.name.placeholder': 'ユーザー名',
      'users.fields.email.placeholder': 'メールアドレス',
      'users.fields.mobile.placeholder': '携帯電話番号',
      'users.fields.password.placeholder': 'パスワード',
      'users.fields.callingCode': '国別コード',
      'users.fields.callingCodeHint': '国際電話国別コードを選択してください',
      'users.save.success': '認証メールが送信されました',
      'users.save.email_verification.success':
        '変更が保存されました。認証が完了するとメールアドレスが更新されます',
      'users.save.email_verification.send': '認証メールが送信されました',
      'users.add.phone': '連絡先番号を追加',
      'users.add.delivery_address': '配送先住所を追加',
      'users.add.phone.maximum': '最大 5 つまで電話番号を追加できます',
      'users.add.delivery_address.maximum':
        '最大 5 つまで配送先住所を追加できます',
      'users.birthday.faq':
        'オンラインストアの登録と購入には指定の年齢に達している必要があります。',
      'users.error.phone.format': '電話番号の形式に誤りがあります',
      'users.error.phone.required': '電話番号は必須です',
      'users.error.not_save_mobile_phone':
        'この携帯電話番号は認証されていないため、変更を保存できません',
      'users.error.no_changes': '保存できる変更はありません',
      'users.signin.error.invalid.mobile':
        'アカウントまたはパスワードが無効です',
      'users.signin.error.invalid.email':
        'アカウントまたはパスワードが無効です',
      'users.signup.male': '男性',
      'users.signup.female': '女性',
      'users.signup.other': '回答なし',
      'users.signup.email': 'メールで登録',
      'users.signup.mobile_phone': '携帯電話番号で登録',
      'users.signup.unconfirmed':
        'おかえりなさい！アカウントのセキュリティ保護のため、ログイン前にアカウント認証を完了する必要があります。認証メールが {{email}} に送信されました。確認して認証を実行してください。',
      'users.signup.verification.hint.send':
        '認証メールが送信されました。 {{count}} 秒以内に再送できます。',
      'users.einvoice_carrier': '電子レシート連携',
      'users.edit_member_info': 'メンバープロフィールを編集',
      'users.membership.member_info.title': 'メンバープロフィール特典',
      'users.membership.member_info.hint':
        '以下のメンバープロフィールを入力: {{fields}}。入力完了で {{rewards}} を獲得！',
      'users.membership.member_info.sent':
        'メンバープロフィール確認完了後、特典が進呈されます！',
      'users.membership.member_info.symbol': '、',
      'users.membership.member_info.name': '名前',
      'users.membership.member_info.email': 'メールアドレス',
      'users.membership.member_info.mobile': '電話番号',
      'users.membership.member_info.gender': '性別',
      'users.membership.member_info.birthday': '生年月日',
      'users.membership.member_info.user_credits':
        '{{count}} 円のショッピングクレジット',
      'users.membership.member_info.member_points':
        '{{count}} メンバーポイント',
      'users.membership.member_info.coupons': '{{count}} クーポン',
      'users.membership.forget_password.email_sent':
        'アカウント認証メールを {{email}} に送信しました。\nメールを受信して​​パスワードのリセットを行ってください。',
      'users.member.normal-level': '一般会員',
      'users.membership.expiry_date': '会員有効期限',
      'users.membership.expire.unlimited': '有効期限なし',
      'users.membership.expire.month_valid': '(有効期間 {{months}} ヶ月)',
      'users.membership.tier.expire.date.description':
        '自動更新は注文決済完了日の翌日早朝に実行されます。注文のキャンセルや返品等によりアップグレード条件を満たしていない場合、アップグレードは実行されません。',
      'users.membership.tier.expire.date.auto_downgrade.description':
        '自動更新は注文ステータスが完了になった翌日早朝に実行されます。注文のキャンセルや返品等によりアップグレード条件を満たしていない場合、アップグレードは実行されません。',
      'users.membership.tier.extension.date.description':
        'メンバーの有効期限が切れた際、注文のキャンセル・返品等により注文状況が更新条件を達成しなかった場合、メンバー資格は更新されません。',
      'users.membership.offer.discount': '特別割引',
      'users.membership.upgrade.next_tier': '{{tier_name}} にアップグレードで',
      'users.membership.upgrade.next_tier.discount':
        '、 {{discount}} 割引をご利用いただけます',
      'users.membership.extension.condition': 'メンバーシップ更新条件',
      'users.membership.extension.status': '更新ステータス',
      'users.membership.max_level_tier':
        'おめでとうございます！メンバーグレードがハイレベルに到達しました！',
      'users.membership.spending_within_months':
        '{{months}} ヶ月以内の累計消費額が {{spending}} に達するとアップグレードできます',
      'users.membership.spending_single_purchase':
        '1 度の消費額が {{spending}} に達するとアップグレードできます',
      'users.membership.all.spending_within_months':
        '{{months}} か月以内の累計消費額が {{spending}} に達しました',
      'users.membership.all.spending_single_purchase':
        '1 回の購入金額が {{spending}}に達しました',
      'users.membership.extend_valid_period':
        'メンバーシップ期間{{months}}ヶ月以内の{{ type === "within_interval" ? "累計" : "1回の" }}消費額が {{spending}}に達しました',
      'users.membership.all_tiers': 'すべてのメンバーレベルルールを表示',
      'users.membership.all_tiers.condition': 'メンバーレベルルール',
      'users.membership.all_tiers.description':
        'メンバーの有効期間内に更新条件を達成しなかった場合、自動でダウングレードします。',
      'users.membership.tier.shopping_discount':
        'ショッピング {{discount}}  割引',
      'users.membership.tier.upgrade.condition':
        'このレベルのアップグレード条件:',
      'users.membership.tier.upgrade.condition.dollar':
        '現在、 {{ dollar }} 以上の注文はありません。',
      'users.membership.tier.upgrade.condition.continue':
        '引き続きお買い物をお楽しみください！',
      'users.membership.tier.extend':
        '<b>{{tier}}</b> の更新条件を満たしていますか?',
      'users.membership.tier.upgrade.to_next_level.single':
        'アップグレード条件を満たしていますか?',
      'users.membership.tier.upgrade.to_next_level.within':
        '{{month}} ヶ月内の累計消費金額',
      'users.membership.tier.upgrade.completed': 'アップグレード対象',
      'users.membership.tier.upgrade.gap_dollar':
        'あと {{ dollar }}でアップグレード',
      'users.membership.tier.expire.date': '有効期間 ：',
      'users.membership.tier.extend.condition': 'メンバーシップ更新条件',
      'users.membership.tier.extend.single_purchase':
        'メンバーシップ期間{{month}} ヶ月内で {{dollar}} を超える注文はありません',
      'users.membership.tier.extend.single_purchase.can_extend':
        '{{month}} ヶ月以内に {{dollar}} を超える注文がある場合、有効期限になるとメンバーシップが自動で更新されます。',
      'users.membership.tier.extend.top_level.single_purchase':
        'メンバーシップ期間内に {{dollar}} を超える注文はありません。',
      'users.membership.tier.extend.top_level.single_purchase.can_extend':
        'メンバーシップ期間内の注文が {{dollar}} を超えました。有効期限になるとメンバーシップが自動で更新されます',
      'users.membership.tier.extend.within_interval':
        'メンバーシップ有効期間は {{month}} ヶ月間で、現在の累計消費額は {{spending}}です。あと {{dollar}} で更新できます',
      'users.membership.tier.extend.within_interval.can_extend':
        'メンバーシップ有効期限は {{month}} ヶ月で、累計消費額が{{dollar}}に達しました。有効期限が過ぎるとメンバーシップが自動で更新されます。',
      'users.membership.tier.extend.top_level.within_interval':
        'あと {{dollar}}で 更新',
      'users.membership.tier.extend.top_level.within_interval.can_extend':
        'おめでとうございます！有効期限になると自動で更新されます',
      'users.membership.tier.expire.month': '{{month}} ヶ月',

      'users.phone.migrate.notification':
        'ストア機能アップデートにより、2019/1/15以降お客様の電話番号は最後の1つのみ保存させていただきます。複数の電話番号を登録されている場合は、最後に登録した電話番号が正しいことを確認してください。',
      'membership.profile.subscriptions.orders.update.status': '注文の更新',
      'membership.profile.subscriptions.orders.comments.new':
        '注文に対する販売者からの新コメント',
      'membership.profile.subscriptions.messages.new':
        '販売者からの新着メッセージ',
      'membership.profile.subscriptions.marketing.news':
        'ストア情報と割引プラン',
      'membership.profile.subscriptions.none': 'なし',
      'membership.profile.mobile.number.verified':
        'メンバーの携帯電話認証が完了すると、識別のため保存済みの電話番号が上書きされます。',
      'membership.profile.check.code.error':
        '認証コードが間違っています。コードを再入力または再送してください。',
      'membership.profile.verification_hint':
        'メールアドレスを変更される場合は、保存後に認証する必要があります。認証が完了すると {{unconfirmed_email}} に更新されます。',
      'membership.profile.email_subscription.error':
        'メールを購読するには、まずメールアドレスを入力してください',
      'membership.profile.sms_subscription.error':
        'SMS を購読するには、携帯電話番号を入力してください',

      'member_points.title': '会員ポイント',
      'member_points.unit': 'ポイント',
      'member_points.point_balance': '現在の合計ポイント',
      'member_points.point_history': 'ポイント履歴',
      'member_points.fields.date': '日時',
      'member_points.rule': 'ポイントルール説明',
      'member_points.display.expire_point': 'クリック',
      'member_points.display.successive_expire_point': 'クリック',
      'member_points.fields.expire': '期限切れ',
      'member_points.fields.expired': 'ポイント有効期限切れ',
      'member_points.fields.successive_expire': 'まもなく期限切れ',
      'member_points.fields.remarks': 'ポイント変更理由',
      'member_points.fields.value': 'ポイント',
      'member_points.fields.expiry_date': '有効期限',
      'member_points.fields.member_point_expired': 'ポイント有効期限切れ',
      'member_points.fields.auto_reward': '注文中',
      'member_points.fields.auto_reward_seconds': 'ポイント獲得',
      'member_points.no_data': '履歴なし',
      'member_points.equal_cash': '{{money}}相当',
      'member_points.redeem_cash_and_earn_from_order_description':
        '{{costMoney}} 購入で {{earnPoint}} ポイント獲得、{{costPoint}} ポイントで {{discountMoney}} 割引。',
      'member_points.redeem_cash_description':
        '{{costPoint}} ポイントで {{discountMoney}} 割引。',
      'member_points.earn_from_order_description':
        '{{costMoney}} 購入で {{earnPoint}} ポイント獲得',

      'coupon.title': 'クーポン',
      'coupon.valid': '利用可能',
      'coupon.invalid': '期限切れ',
      'coupon.valid_until':
        '割引は <span class="coupon-period-time"><div class="coupon-period-tip">現地のタイムゾーンにもとづいて表示<br/>(GMT{{timeZone}})</div> {{time}} </span> まで',
      'coupon.start_at':
        '割引開始日 <span class="coupon-period-time"><div class="coupon-period-tip">現地のタイムゾーンにもとづいて表示<br/>(GMT{{timeZone}})</div> {{time}} </span>',
      'coupon.one_time': '1回の利用のみ',
      'coupon.first_shoppers': '最初の {{number}} 名に適用されます',
      'coupon.reach_limit': '割引はすべて利用済みです',
      'coupon.expired': '期限切れ',
      'coupon.used': '使用済み',
      'coupon.no_valid_coupon': '利用可能なクーポンはありません',
      'coupon.no_invalid_coupon': '期限切れのクーポンはありません',
      'coupon.no_comingSoon_coupon': '獲得予定クーポンはありません',
      'coupon.no_available_coupon': '利用可能なクーポンはありません',
      'coupon.select_coupon': 'クーポンを選択',
      'coupon.confirm': '確認',
      'coupon.first_purchase_only': '初回購入限定',
      'coupon.comingSoon': 'まもなく開始',
      'coupon.retail_store_only': '店舗限定',
      'coupon.online_store_only': 'オンラインストア限定',
      'coupon.online_and_selected_retail_store_only':
        'オンライン・指定店舗限定',
      'coupon.selected_retail_store_only': '指定店舗限定',
      'coupon.selected_retail_store_list': '指定店舗一覧',
      'coupon.login_to_receive': 'ログインしてクーポンを獲得',
      'coupon.login_or_register':
        '下のボタンをクリックしてログイン・登録ページに移動してください',
      'coupon.usage_limit': '割引はすべて利用済みです',
      'coupon.already_taken': 'すでに受取済みです',
      'coupon.is_expired': 'クーポン有効期限切れ',
      'coupon.not_eligible': 'クーポン対象外です',
      'coupon.unfit_first_purchase': '初回購入はクーポン対象外',
      'coupon.login': 'ログイン',
      'coupon.close': 'とじる',
      'coupon.success': '獲得完了',
      'coupon.congratulation': '獲得しました！',
      'coupon.view': 'クーポンを見る',
      'coupon.only_tag': '限定',

      go_coupon_center: 'クーポンセンターへ',
      back_member_center: 'メンバーセンターにもどる',
      coupon_center: 'クーポンセンター',

      'store_credits.title': 'ストアクレジット',
      'store_credits.credit_balance': '保有のショッピングクレジット',
      'store_credits.credit_history': 'ショッピングクレジット履歴',
      'store_credits.fields.date': '日付',
      'store_credits.fields.remarks': 'ショッピングクレジット項目',
      'store_credits.fields.value': 'ショッピングクレジット',
      'store_credits.fields.expiry_date': '有効期限',
      'store_credits.fields.balance': 'ショッピングクレジット残高',
      'store_credits.fields.order_prefix':
        'この注文でショッピング クレジットが使用されました',
      'store_credits.fields.welcome_credit':
        '新規会員向けショッピングクレジット',
      'store_credits.fields.member_referral_credit':
        '新規顧客に購入をおすすめする',
      'store_credits.fields.member_info_quick_completion_credit':
        'メンバープロフィール特典　ショッピングクレジット',
      'store_credits.fields.welcome_member_referral_credit':
        '紹介リンク経由での登録が完了しました',
      'store_credits.fields.birthday_credit': '誕生日ショッピングクレジット',
      'store_credits.fields.user_credit_expired':
        'ショッピングクレジットの有効期限が切れました',
      'store_credits.fields.never_expires': '期限なし',
      'store_credits.fields.never_expires_point': 'ポイント期限なし',
      'store_credits.fields.display.never_expired': '期限なし',
      'store_credits.fields.auto_reward_prefix': '注文',
      'store_credits.fields.auto_reward': 'ショッピングクレジット獲得',
      'store_credits.fields.revert_credit':
        'ショッピングクレジット補充：キャンセル済みの注文',
      'store_credits.fields.revert_credit.return':
        'ショッピングクレジット補充：返品済みの注文',
      'store_credits.fields.revert_credit.order_split':
        'ショッピングクレジット補充：分割注文',
      'store_credits.fields.revert_credit.order_edit':
        'ショッピングクレジット補充：注文の編集',
      'store_credits.fields.revert_credit.return_order_revert':
        'ショッピングクレジット補充：返品',
      'store_credits.fields.product_review_reward':
        '完了した注文<a href="{{link}}">{{ orderNumber }}</a>のレビュー',
      'store_credits.no_data': '履歴なし',
      'store_credits.bulk_imported': 'ショッピングクレジットを手動で追加',

      'member_points.fields.revert_member_point':
        '補充ポイント：注文キャンセル済み',
      'member_points.fields.revert_member_point.return':
        '補充ポイント：注文返品済み',
      'member_points.fields.revert_member_point.order_split':
        '補充ポイント：分割注文',
      'member_points.fields.revert_member_point.order_edit':
        '補充ポイント：注文編集',
      'member_points.fields.revert_member_point.return_order_revert':
        '補充ポイント：返品',
      'member_points.fields.product_review_reward':
        '完了した注文<a href="{{link}}">{{ orderNumber }}</a>のレビュー',
      'member_points.fields.member_info_reward':
        'メンバープロフィール特典　メンバーポイント',

      'tax.sales_tax': '消費税',
      'tax.sales_tax_rate': '消費税率',
      'tax.service_tax': 'サービス税',
      'tax.service_tax_rate': 'サービス税率',
      'tax.delivery_tax': '送料税',
      'tax.delivery_tax_rate': '送料税率',
      'tax.calculate_tax': '税金を計算',
      'tax.calculate_tax.info.1': 'クリックして',
      'tax.calculate_tax.info.2': '支払いを実行してください',
      'tax.calculation_title': '税額計算方法：',
      'tax.calculation_content':
        '(課税商品の合計金額 - 割引額) × 税率 + 送料 × 税率',
      'tax.price_updated': '価格が更新されました',
      'tax.total': '合計',
      'tax.fee': '税金',
      'tax.product_price': '商品価格',
      'tax.discounts': '割引',
      'tax.applied_store_credit': 'ショッピングクレジット割引',

      'dynamic.delivery.fee.no.state.notice':
        '配送先住所を入力後、送料が支払いページに表示されます。',
      'dynamic.delivery.fee.no.state.info':
        '送料は配送先住所確認後に表示されます。',
      'dynamic.delivery.fee.calculate.title': '送料の計算方法',
      'dynamic.delivery.fee.calculate.desc':
        '最初の1kgの送料＋（総重量－1kg）×追加重量分送料',
      'dynamic.delivery.fee.calculate.hint': '総重量1kg未満は1kgとして計算',
      'dynamic.delivery.fee.updated.notice': '送料が更新されました',
      'dynamic.delivery.fee.calculate.info': '送料合計: {{totaldeliveryFee}}',

      'error.404.title': '申し訳ありません',
      'error.404.description': 'Webページが存在しません',
      'error.401.title': '申し訳ありません',
      'error.401.description': 'このページのアクセス権限がありません',
      'error.403.title': '申し訳ありません',
      'error.403.description': 'このページのアクセス権限がありません',
      'error.403.not_signed_in.message':
        'ログインされていない可能性があります。',
      'error.403.not_signed_in.anchor': 'ログイン',

      'error.continue': 'お買い物を続ける',

      'orders.comments.title': 'ストアと顧客のメッセージ',
      'orders.comments.text.name': 'メッセージ',
      'orders.comments.text.placeholder': 'メッセージを入力してください',

      'orders.invoices.title': '請求書',
      'orders.invoices.carrier': '共通型クラウド電子請求書管理ツール',
      'orders.invoices.carrier_type.member':
        'メンバー用請求書管理ツール（請求書情報はメールで送信されます）',
      'orders.invoices.carrier_type.mobile_barcode': 'モバイルバーコード',
      'orders.invoices.carrier_type.npc_barcode':
        '自然人証明バーコード（デジタル身分証明）',
      'orders.invoices.get_invoice': '請求書をリクエスト',

      'orders.notifications.title': '注文ステータス通知',

      'order.payments.login.confirm': '支払い前にログインしてください。',

      'model.credit_card.title': 'クレジットカード情報',
      'model.credit_card.subtitle': 'クレジットカード情報を入力してください',
      'model.credit_card.choose_card':
        '使用したい支払いカードを選択してください',
      'model.credit_card.use_new_card': '新しいカードを使用する',
      'model.slpayment.security_agreement':
        'キャッシュ フロー サービスは、最高レベルの PCI-DSS 国際クレジット カード認証に合格した SHOPLINE Payments によって提供され、安全な取引サービスを提供し、国内外のクレジット カード スワイプをサポートします。',
      'model.slpayment.recurring_pay_agreement':
        'SHOPLINE は、この取引情報を今後の定期的な控除に使用します。<a href="https://shopline.tw/about/product_subscription_agreements" target="_blank" rel="noopener">「定期購入クレジット カード源泉徴収同意書」に同意するには、[送信] をクリックしてください。</a>',
      'model.update_credit_card_for_product_subscription.success':
        'クレジットカード情報が正常に更新されました',
      'model.update_credit_card_for_product_subscription.fail':
        '定期購入と支払い情報を設定できませんでした。もう一度お試しください。',

      'sms_messenger_checkbox.subscribe': 'SMS 登録',
      'facebook_messenger_checkbox.subscribed': '登録済み',
      'facebook_messenger_checkbox.turn_off': 'とじる',
      'facebook_messenger_checkbox.v2.subscribe': 'メッセンジャー登録',
      'facebook_messenger_checkbox.v2.subscribed': 'メッセンジャー登録済み',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        '注文状況の通知',
      'facebook_messenger_checkbox.topics.MARKETING': 'ストア情報と割引プラン',
      'subscribe.facebook.dialog.title': 'Facebook購読',
      'subscribe.facebook.dialog.info': `Facebookの利用規約の更新に応じて、メッセンジャーに移動して購読プロセスを完了する必要があります。 「購読に進む」をクリックすると、自動的にメッセンジャーを開きます。 <br /> メッセンジャーを開いた後、ログインし、<span class="facebook-subscription-dialog-hint">会話内の「今すぐ開始」ボタンをクリックし</span>、このページに戻って購読を完了してください。 （このストアのFacebookページと会話したことがある場合は、会話を開いた時点で購読が成功したとみなされます。）`,
      'subscribe.facebook.dialog.cancel': 'キャンセル',
      'subscribe.facebook.dialog.subscribe': '購読に進む',

      'line_messenger_checkbox.subscribe': 'LINEに送る',
      'line_messenger_checkbox.subscribed': 'LINEに登録済み',
      'line_messenger_checkbox.order_notification.subscribed':
        '注文ステータス通知を登録しました',
      'line_messenger_checkbox.button.unsubscribe': 'とじる',

      'whatsapp_messenger_checkbox.subscribe': 'WhatsApp 登録',

      'orders.fields.payment_fee': '追加料金',

      'media.validation.file_limit.one':
        'アップロードできる画像は 1 つのみです',
      'media.validation.type': 'jpgまたはpng形式の画像のみアップロード可能',
      'media.validation.error.file_size':
        '5MB以下の画像をアップロードしてください',
      'media.validation.error.file_attachment_size':
        '2MB 以下のファイルをアップロードしてください',
      'media.validation.error':
        'アップロード中にエラーが発生しました。もう一度お試しください。',

      'attachment.upload.description':
        '"ファイルは2MB以下、pdf、doc、docx、xls、xlsx、csv、jpg、jpeg、png、gif 形式のファイルがアップロード可能です。',

      'imagepicker.title': '画像ををアップロード',

      'promotions.section.discount_summary.set.on_item':
        '<b>指定の商品</b> {{discount_value }}',
      'promotions.section.discount_summary.set.on_order':
        '{{ criteria_on ? "" : "<b>全ストア</b>" }} {{ discount_value }}',
      'promotions.section.discount_summary.discount_value':
        '{{ type == "amount" ? "即減" : "即享" }} <b>{{ type == "amount" ? currency : "" }}{{ value }}{{ type == "percentage" ? "折":"" }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>指定の商品</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>すべての注文</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        '<b>指定の商品</b> <b>{{condition_value }}</b>のご購入で、',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        '<b>フルオーダー</b>購入<b>{{condition_value }}</b>、',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }}点',
      'promotions.section.discount_summary.criteria.bundle_group':
        '指定の商品：セットA<span class="bundle-group-label bundle-group-red"> </span>  {{ red_count }} 点選択，セットB<span class="bundle-group-label bundle-group-green"> </span>  {{ green_count }} 点選択で 合計 {{ total }}点',
      'promotions.section.discount_summary.criteria.bundle_group_amount':
        '指定商品：セットA<span class="bundle-group-label bundle-group-red"> </span>  {{ red_count }} 点選択，セットB<span class="bundle-group-label bundle-group-green"> </span>  {{ green_count }} 点選択　 割引 {{ total }}点',
      'promotions.section.discount_summary.criteria.bundle_group_percentage':
        '指定の商品：セットA<span class="bundle-group-label bundle-group-red"> </span>  {{ red_count }} 点選択，セットB<span class="bundle-group-label bundle-group-green"> </span>  {{ green_count }} 点選択で合計 {{ total }} 割引',
      'promotions.section.discount_summary.criteria.bundle_group_gift':
        '指定の商品：セットA<span class="bundle-group-label bundle-group-red"> </span>  {{ red_count }} 点選択，セットB<span class="bundle-group-label bundle-group-green"> </span>  {{ green_count }} 点選択 で合計{{ total }} 点 ({{ giftName }})点ギフト獲得',
      'promotions.section.discount_summary.criteria.bundle_pricing':
        '指定の商品： {{ itemCount }} 点選択で合計 {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_percentage':
        '指定の商品： {{ itemCount }} 点獲得で  {{ discountValue }} 割引',
      'promotions.section.discount_summary.criteria.bundle_amount':
        '指定の商品： {{ itemCount }} 点選択で  {{ discountValue }}割引',
      'promotions.section.discount_summary.criteria.bundle_gift':
        '指定の商品： {{ itemCount }} 点選択で  {{ discountValue }} 割引 ({{ giftName }})点獲得',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        '、買えば買うほどお得！',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        '、最大1セット割引',
      'promotions.section.discount_summary.content':
        '{{ criteria }}{{ benefits }}',
      'promotions.section.discount_percentage.caption': '{{ value }}割引',

      'promotions.page.term.title': '利用規約',
      'promotions.page.term.confirm': '確認',
      'promotions.page.term.default':
        '＊商品の割引はショッピングカートに追加後、支払いページで計算されます。\n＊支払い時に指定の支払い方法と配送方法を選択してください\n＊会員限定キャンペーン場合は、ログイン後支払い時に割引が適用されます',
      'promotions.page.start_at':
        '割引プロモーション開始日 <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        '割引は <b class="local-datetime">{{date}}</b> まで',
      'promotions.page.redeem_gift.start_at':
        'キャンペーン開始日 <span class="local-datetime">{{date}}</span>',
      'promotions.page.redeem_gift.end_at':
        'キャンペーンは<b class="local-datetime">{{date}}</b>まで',
      'promotions.page.display_local_timezone':
        '現地のタイムゾーンにもとづいて表示<br/>(GMT{{offset}})',

      'promotions.exclude.items.hint.title':
        'すでに割引済みです。次の割引はご利用になれません：',
      'promotions.exclude.items.hint.order_discount': '全店割引',
      'promotions.exclude.items.hint.member_tier': 'メンバーデフォルト割引',
      'promotions.exclude.items.hint.user_credit': 'ショッピングクレジット割引',
      'promotions.exclude.items.hint.member_point': 'ポイント割引',

      'product.page.title.product-info': '商品',
      'product.page.quantity': '数量',
      'product.page.unit_price': '単価',
      'product.page.subtotal': '小計',
      'product.page.add_to_cart_count':
        '<span class="order-detail-count">{{count}}</span> 点追加済み',
      'product.member_only': '会員限定',
      'product.tier_member_only': '{{tierName}} 限定',

      'products.quick_cart.quantity': '数量',
      'products.quick_cart.preorder_now': '今すぐ予約注文',
      'products.quick_cart.add_to_cart': 'カートに追加',
      'products.quick_cart.quantity_invalid':
        'この数量は適用できません。有効な数量を入力してください。',
      'products.quick_cart.out_of_stock': '完売',
      'products.quick_cart.out_of_stock_hint':
        '商品の在庫不足のため、カートに追加できません',
      'products.quick_cart.out_of_number_hint': '商品数が不足しています',
      'products.quick_cart.low_stock_hint':
        '入力した商品数が在庫を超えています',
      'products.quick_cart.insufficient_point':
        'メンバーポイントが不足しています。ギフト数を調整してください',
      'products.quick_cart.reached_max_purchase_quantity':
        '商品の購入数は上限 {{ message }} 点です',
      'products.quick_cart.quantity_of_stock_hint':
        '在庫はあと {{message}} 点です',
      'products.quick_cart.messagetobuy':
        '購入をご希望の場合は、お問い合わせください。',
      'products.quick_cart.message': 'お問い合わせ',
      'products.quick_cart.show_more': '商品情報',
      'products.message.title': 'この商品を削除しますか?',
      'products.message.button_cancel': 'やめる',
      'products.message.button_ok': '削除する',
      'products.purchase_limit_hint': 'おひとり様あたり購入 {{limit}} 点まで',
      'purchase_limit_hint.purchasable':
        'おひとり様あたり購入 {{limit}} 点まで。残り {{purchasable_qty}} 点購入可能',
      'products.purchase_limit_hint_can_not_purchase':
        'おひとり様あたり購入 {{limit}} 点まで。上限に達しました',

      'products.category.advance_filter.title': 'フィルター',
      'products.category.advance_filter.clear': 'クリア',
      'products.category.advance_filter.clear_all': 'すべてクリア',
      'products.category.advance_filter.apply': 'フィルターを適用',
      'products.category.advance_filter.apply.hint':
        'まずフィルターの内容を選択してください',
      'products.category.advance_filter.show_more': 'もっと見る',
      'products.category.advance_filter.show_less': 'とじる',
      'products.category.advance_filter.type.color': '色',
      'products.category.advance_filter.type.size': 'サイズ',
      'products.category.advance_filter.type.brand': 'ブランド',
      'products.category.advance_filter.type.material': '素材',
      'products.category.advance_filter.type.price': '価格 ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': '最小金額',
      'products.category.advance_filter.placeholder.max_price': '最大金額',

      'products.variant_selector.hide': 'とじる',
      'products.variant_selector.view_other_variations':
        'その他{{count}} 個のオプションをみる',

      'hk_sfplus.region': 'エリア',
      'hk_sfplus.area': '範囲',
      'hk_sfplus.district': 'エリア',
      'hk_sfplus.address': '{{region}} {{district}} {{area}}',

      'zeek.station.address': 'セルフ受取り先住所',
      'zeek.station.name': 'セルフ受取り先名',
      'zeek.station.provider': 'セルフ受取り業者',
      'zeek.pickup.region': 'セルフ受取りエリア',
      'zeek.pickup.area': 'セルフ受取りエリア',

      'tcat.time_slot.title': '時間を選択',
      'tcat.time_slot.01': '13:00まで',
      'tcat.time_slot.02': '14:00～18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': '指定なし',

      'subscribe.success': '登録完了',
      'subscribe.failure': '登録に失敗しました。ストアにお問い合わせください',
      'subscribe.line.failure':
        '登録に失敗しました。再度LINEにログインしてください',
      'subscribe.line.failure.already_used':
        '登録に失敗しました。このLINEアカウントは他のメンバーによってすでに登録されています。 LINEでログインの上、再度登録してください。',
      'subscribe.line.failure.different_id':
        '登録に失敗しました。メンバー登録した LINE アカウントから登録してください。',
      'subscribe.facebook.failure':
        '登録に失敗しました。Facebookに再度ログインしてください',
      'subscribe.failure.already_bound':
        '登録に失敗しました。このソーシャルアカウントは別の顧客と連携されています。連携の解除についてはストアまでご連絡ください。',
      'unsubscribe.success': '登録解除が完了しました',
      'unsubscribe.failure': '登録解除に失敗しました。もう一度お試しください',

      'return_order.fill_infomation': '返品情報を入力',
      'return_order.confirmation': '返品注文確認',
      'return_order.select_return_product': '返品商品を選択',
      'return_order.order_number': '注文番号',
      'return_order.product_name': '商品名',
      'return_order.quantity': '数量',
      'return_order.unit_price': '単価',
      'return_order.return_quantity': '返品数',
      'return_order.prcie_amount': '金額',
      'return_order.return_reason': '返品理由',
      'return_order.return_reason.not_expected': '商品が期待通りでなかった',
      'return_order.return_reason.broke_during_delivery':
        '配送中に商品が破損してしまった',
      'return_order.return_reason.wrong_variation':
        '商品のサイズおよび仕様が一致しない',
      'return_order.return_reason.wrong_item': '間違った商品が送られてきた',
      'return_order.return_reason.other': 'その他',
      'return_order.return_reason.admin_return_order': 'ストアで返品注文を作成',
      'return_order.return_reason.return_remark': '返品理由の説明',
      'return_order.return_reason.return_remark_placeholder':
        '気持ちが変わった。60 文字まで',

      'return_order.return_delivery.info': '返品情報',
      'return_order.return_delivery.delivery_option': '返品配送',
      'return_order.return_delivery.status': '返品ステータス',
      'return_order.return_delivery.receiver_name': '返品者名',
      'return_order.return_delivery.receiver_phone': '返品者の電話番号',
      'return_order.return_delivery.receiver_address': '返送者の住所',
      'return_order.return_delivery.optional': 'オプション',
      'return_order.return_delivery.destination': '場所',

      'return_order.return_payment.info': '返金情報',
      'return_order.return_payment.payment_option': '返金方法',
      'return_order.return_payment.status': '返金ステータス',
      'return_order.return_payment.custom_return':
        'その他（返金に関してはビジネスオーナーまでお問い合わせください）',
      'return_order.return_payment.bank_transfer_return': '返金口座番号を入力',
      'return_order.return_payment.bank_account': '銀行名および口座番号',
      'return_order.return_payment.bank_transfer_placeholder':
        '812 大信銀行 xxxxxxxxxxxx',
      'return_order.form.agree_return_policy':
        '<a href="{{ link }}" target="_blank" rel="noopener">返品および返金ポリシー</a>に同意します',
      'return_order.form.validation':
        '返品および返金ポリシーに同意する必要があります',
      'return_order.form.submit': '返品リクエストを送信',
      'return_order.back_to_order_detail': '注文情報に戻る',

      'return_order.detail.order_submitted': '返品注文が作成されました。',
      'return_order.detail.confirmation_emailed':
        '返品確認メールがメールアドレスに送信されました',
      'return_order.detail.return_order_no': '返品情報番号',
      'return_order.detail.apply_date': '申請日',
      'return_order.confirmation.refund_note':
        '実際の返金金額については、送料および各割引条件の計算後、<a href="{{ link }}">返品情報ページ</a>に表示されます。',
      'return_order.confirmation.refund_question':
        'ご質問がある場合は、<a id="js-contact-us">カスタマー サービスまでお問い合わせください</a>',

      'return_order.inspect.status': '商品確認ステータス',
      'return_order.inspect.status.pass': '商品確認完了',
      'return_order.inspect.status.fail': '商品確認失敗',
      'return_order.refund.record': '返金履歴',
      'return_order.refund.amount': '返金額',
      'return_order.refund.date': '返金日',
      'return_order.return.total': '返品合計',
      'return_order.coninue_shopping': 'お買い物を続ける',

      'return_order.inspect.status.no_inspect': '検査なし',
      'return_order.inspect.status.pending': '未検査',
      'return_order.delivery_status.return_collected': '返品済み',
      'return_order.delivery_status.returned': '返品済み',
      'return_order.delivery_status.returning': '返品中',
      'return_order.delivery_status.pending': '返品中',
      'return_order.delivery_status.arrived': '到着済み',
      'return_order.delivery_status.no_return': '未返品',
      'return_order.payment_status.pending': '未返品',
      'return_order.payment_status.refunded': '返金済み',
      'return_order.payment_record.pending': '保留中',
      'return_order.popup.failed.title': '返品リクエストに失敗しました',
      'return_order.popup.failed.content':
        'この配送方法の最大注文金額は {{ amount }} 台湾ドルです。配送方法を変更して、再度返品申請を行ってください。',
      'return_order.popup.failed.confirm': '了解',

      'return_order.status': '返品注文ステータス',
      'return_order.status.pending': '返品審査中',
      'return_order.status.confirmed': '返品確認',
      'return_order.status.completed': '返品完了',
      'return_order.status.cancelled': '返品をキャンセル',

      'order_comment_and_message.general_error':
        'システムエラーです。ストアオーナーに連絡するか、後でもう一度試してください。',
      'order_comment_and_message.rate_limit_error':
        '今日の送信メッセージ数の上限に達しました。明日もう一度試してください。',

      'member_referral.discount': 'メンバーおすすめ割引',
      'member_referral.rules': 'キャンペーンルール説明',
      'member_referral.code.copy_and_share':
        'リンクをコピーして友達にシェアすると、ショッピング クレジットを獲得できます。',
      'member_referral.code.remarks': 'キャンペーンルール説明',
      'member_referral.generate.code': 'おすすめリンクを生成',
      'member_referral.generate.code.success': '生成完了',
      'member_referral.copy.link': 'リンクをコピー',
      'member_referral.copy.link.success': 'コピー完了',
      'member_referral.welcome_credit.popup.title':
        'おめでとうございます！{{reward_value}} のショッピング クレジットを獲得しました。',
      'member_referral.welcome_credit.popup.content':
        '友達へのシェアで、お支払い時に使用できる {{reward_value}} ショッピング クレジットを獲得できます。メンバー登録で、新たに友達とシェアしたり、紹介割引を利用することもできます。',
      'member_referral.welcome_credit.popup.continue':
        '確認してお買い物を続ける',

      'lock_inventory.tooltip':
        'ライブ販売： {{event_name}} ({{start_time}}から{{expired_time}})まで\n(現地のタイムゾーンにもとづいて表示されます（GMT{{timezone}}）',
      'facebook_banner.description':
        'Facebook アプリの内蔵ブラウザを使用しています。画像をアップロードできない場合は、別のブラウザを使用してください。 <span>クリックしてリンクをコピー</span>',
      'in_app_browser_popup.desc':
        'メンバーログインしてショッピングカートを確認する場合は、より快適なお買い物体験のため、デフォルトのブラウザをご利用いただくことをお勧めします。',
      'fb_in_app_browser_popup.desc':
        'Facebook アプリ内のブラウザを使用しています。お支払いを完了するには別のブラウザを使用してください。',
      'fb_in_app_browser_popup.copy_link': 'クリックしてリンクをコピー',

      'product_set.build': '組み合わせ商品を選択',
      'product_set.subtitle.unqualified':
        '以下の商品に，あと <span class="highlight-label">{{ quantity }}</span> 点追加でショッピングカートに追加できます',
      'product_set.subtitle.qualified':
        '割引適用済みです。カートに追加してください。',
      'product_set.special_offer': '組み合わせ商品割引',
      'product_set.items_needed':
        'あと <span class="highlight-label">{{ quantity }}</span> 点',
      'product_set.add_to_set': '組み合わせに追加',
      'product_set.items_needed.diff':
        '{{ currentQuantity }} 点選択済みです。あと <span class="highlight-label">{{ stillNeedQuantity }}</span> 点で割引が適用されます！',
      'product_set.add_to_cart': 'カートに追加',
      'product_set.items_selected':
        '<span class="highlight-label">{{ quantity }}</span> 点選択済み',
      'product_set.items_needed.hint':
        'あと <span class="highlight-label">{{ quantity }}</span> 点で完了',
      'product_set.quantity_label.unqualified': 'あと {{ quantity }} 点',
      'product_set.quantity_label.qualified': '{{ quantity }} 点選択済み',
      'product_set.reach_limit': '上限に達しました',
      'product_set.reach_limit.congrats':
        '<span class="highlight-label">{{ quantity }}</span> 点選択済みです。組み合わせ割引が適用されました！',
      'product_set.no_items_selected':
        'まだ商品がありません。上部から組み合わせ商品を追加してください',
      'product_set.quick_cart.add_to_set': '組み合わせに追加',
      'product_set.exceed_limit':
        '入力した商品数が組み合わせの購入制限を超えています',
      'product_set.set_includes': '組み合わせ商品内容：',
      'product_set.quantity': '組み合わせ数',
      'product_set.sold_out': '在庫不足',
      'product_set.please_select': '商品の仕様を選択してください',
      'product_set.not_enough_stock.please_remove':
        '商品数が在庫を超えています。この商品を削除してください',
      'product_set.not_enough_stock': '入力した商品数が在庫を超えています',
      'store_stock.loading': '在庫を確認しています',
      'store_stock.take_a_while': '少し時間がかかる場合があります...',
      'store_stock.current_availability': '店舗の在庫状況',
      'store_stock.description':
        '商品の販売数は現場の状況にもとづいています。ご予約が完了されたお客様のみ、在庫の取置きが可能です。来店前に注文を完了し、店舗受け取り日を確認いただくことをおすすめします。',
      'store_stock.available': 'オンラインで購入、店舗受取りが可能',
      'store_stock.sold_out': '完売',

      'cookie_consent_popup.claim.heading':
        'この Web サイトはクッキーを使用しています',
      'cookie_consent_popup.claim.description':
        'この Web サイトは、サービス確保のため必要なクッキーおよび追跡型のクッキー を使用しています。追跡型のクッキーに関しては、同意いただいた後のみ実行されます。',
      'cookie_consent_popup.claim.read_more': '詳しくはこちら',
      'cookie_consent_popup.buttons.settings': '環境設定',
      'cookie_consent_popup.buttons.accept': '同意して続行',
      'cookie_consent_popup.buttons.save_preferences': '設定を保存',
      'cookie_consent_popup.cookie_names.necessary': '厳密に必要なクッキー',
      'cookie_consent_popup.cookie_names.statistical': 'パフォーマンスクッキー',
      'cookie_consent_popup.cookie_names.marketing': 'ターゲティングクッキー',
      'cookie_consent_popup.cookie_names.functionality': '機能クッキー',
      'cookie_consent_popup.cookie_descriptions.necessary':
        'このクッキーはWeb サイトが適切に機能するために必要な情報を保存し、オフにすることはできません。このクッキーがない場合、Webサイトの基本機能が提供されません。',
      'cookie_consent_popup.cookie_descriptions.statistical':
        'このクッキーは、訪問者が頻繁に開くページや、エラーメッセージなど、Web サイトのパフォーマンスを測定し改善するために使用されます。このクッキーにはユーザーを特定する情報は保存されず、収集された情報は集約され、匿名化されます。このクッキーを許可しない場合、あなたが当社のサイトをどのように使用しているか把握することはできません。',
      'cookie_consent_popup.cookie_descriptions.marketing':
        'このクッキーは、あなたの興味に関連した広告を配信されるために使用され、またサードバーティのアプリにおいてターゲット広告を表示するのに使用されます。',
      'cookie_consent_popup.cookie_descriptions.functionality':
        'このクッキーは、ユーザーが入力した情報 (ユーザー名、言語の選択、所在地など) を匿名で保存し、カスタマイズされたオプションを提供します。このクッキーを許可しない場合、一部の機能が利用できなくなる可能性があります。',

      'multi_checkout.items.title': 'ショッピングカート',
      'multi_checkout.promotions.title': '割引',
      'multi_checkout.change.payment_type.modal.title':
        'お支払い方法を変更しています',
      'multi_checkout.change.payment_type.modal.description':
        '支払いのタイプを変更する場合、配送方法を再選択し、配送情報を再入力する必要があります。',
      'multi_checkout.change.payment_type.modal.confirm': '変更',
      'multi_checkout.change.payment_type.modal.cancel': 'キャンセル',
      'multi_checkout.destination_section.title': '目的地を入力',
      'multi_checkout.destination_section.address_type.new': '新しいアドレス',
      'multi_checkout.destination_section.delivery_type.label':
        '選択済みの配送方法：',
      'multi_checkout.destination_section.pick_store.label': '店舗を検索',
      'multi_checkout.destination_section.pick_store.store_code':
        '選択済みの店舗番号',
      'multi_checkout.destination_section.pick_store.store_name':
        '選択済みの店舗名',
      'multi_checkout.destination_section.pick_store.store_address': '店舗住所',
      'multi_checkout.destination_section.dynamic_fee.hint':
        '送料は配送先住所を入力後表示されます。',
      'multi_checkout.delivery_section.title': '支払いタイプと配送方法を選択',
      'multi_checkout.delivery_section.country.label': '国・地域',
      'multi_checkout.delivery_section.payment_type.label': '支払いタイプ',
      'multi_checkout.delivery_section.delivery_option.label': '配送方法',
      'multi_checkout.delivery_section.collapse.hint':
        '配送に関するヒントを見る',
      'multi_checkout.delivery_section.ncod': '代金引換不可',
      'multi_checkout.delivery_section.cod': '代金引換',
      'multi_checkout.delivery_section.empty_delivery_methods':
        '適用できる方法がありません',
      'multi_checkout.summary_section.title': '注文情報',
      'multi_checkout.next_step': '次へ',
      'multi_checkout.delivery_attribute': '注文',
      'multi_checkout.summary_section.delivery_type.label': '配送方法:',
      'multi_checkout.summary_section.check_payment_instructions':
        '支払い手順を表示',
      'multi_checkout.summary_section.expand_payment_instructions':
        '支払い手順を表示',
      'multi_checkout.summary_section.confirm': '確認',
      'multi_checkout.cart_tag_unnamed': '(名前なし)',
      'multi_checkout.checkout.destination.title': '配送先',
      'multi_checkout.checkout.delivery_time.description': '到着時間の説明:',

      'birthday_picker.year.placeholder': '年',
      'birthday_picker.month.placeholder': '月',
      'birthday_picker.date.placeholder': '日',

      'coupon_v2.discount_type.discount': '割引クーポン',
      'coupon_v2.discount_type.free_shipping': '送料無料クーポン',
      'coupon_v2.discount_type.gift': 'ギフトクーポン',
      'coupon_v2.period.start_at': '{{ date }} から',
      'coupon_v2.period.end_at': '{{ date }} まで',
      'coupon_v2.copy_code': '割引コードをコピー',
      'coupon_v2.copy_code.success': 'コピー完了',
      'coupon_v2.claim': '今すぐ受け取る',
      'coupon_v2.claim.success': '{{ name }} クーポンを獲得しました',
      'coupon_v2.claimed': '受取り済み',
      'coupon_v2.use': '今すぐ使用',

      'user_reminder.member_referral.user_credit':
        'メンバー登録で{{ value }} 元のショッピングクレジットを獲得できます。',
      'user_reminder.member_referral.member_point':
        'メンバー登録で {{ value }} ポイント獲得できます',
      'user_reminder.member_referral.action': '今すぐ登録',
      'user_reminder.coupon.member':
        'まだ受け取っていないクーポンがあります。ぜひ獲得してください！',
      'user_reminder.coupon.guest': 'ログインしてクーポンを獲得！',
      'user_reminder.coupon.action': '今すぐ受け取る',

      'coupon_input.placeholder': 'クーポンコードを入力',
      'coupon_input.claim': '受け取る',
      'coupon_input.claim_succeed': '{{ couponName }} を獲得しました！',
      'coupon_input.claim_succeed.redirect':
        '{{ couponName }} を獲得しました！メンバーセンターで確認できます',
      'coupon_input.claim_failed': '獲得できませんでした',
      'verification.email.trial_merchant.reach_limit.failure':
        'ストアはトライアル中のため、確認メールを送信できません。必要な場合はストアまでご連絡ください。',
    });
  },
]);
