import { createDeferredPromise } from '../../utils/promise';

// it's a rough number
const TIMEOUT = 3000;

app.service('RecaptchaService', [
  '$timeout',
  'logger',
  'mainConfig',
  function ($timeout, logger, mainConfig) {
    let recaptchaTimer = null;

    const reset = (elementId) => {
      if (
        typeof grecaptcha !== 'undefined' &&
        document.querySelector('#g-recaptcha-response')
      ) {
        if (document.querySelector('.g-recaptcha#' + elementId) === null) {
          return;
        }
        const widgetId = document.querySelector('.g-recaptcha#' + elementId)
          .dataset.widgetId;
        grecaptcha.reset(widgetId);
      }
    };

    const execute = (elementId) => {
      if (
        typeof grecaptcha !== 'undefined' &&
        document.querySelector('#g-recaptcha-response')
      ) {
        if (document.querySelector('.g-recaptcha#' + elementId) === null) {
          return;
        }
        const widgetId = document.querySelector('.g-recaptcha#' + elementId)
          .dataset.widgetId;
        grecaptcha.execute(widgetId);
      }
    };

    const executeWithPromise = () => {
      if (typeof grecaptcha === 'undefined') {
        return Promise.reject(new Error('grecaptcha not found'));
      }

      const defferredPromise = createDeferredPromise();

      const recaptchaElement = document.createElement('div');
      const onRecaptchaSuccessed = (token) => {
        recaptchaElement.remove();
        document.getElementById('g-recaptcha-response').value = token;
        defferredPromise.resolve(token);
      };
      const onRecaptchaRejected = (error) => {
        recaptchaElement.remove();
        defferredPromise.reject(error);
      };
      const widgetId = grecaptcha.render(recaptchaElement, {
        sitekey: mainConfig.recaptchaSiteKey,
        size: 'invisible',
        badge: 'none',
        callback: onRecaptchaSuccessed,
        'error-callback': onRecaptchaRejected,
      });
      document.body.appendChild(recaptchaElement);

      grecaptcha.execute(widgetId);

      return defferredPromise.promise;
    };

    const cancelRecaptchaTimer = () => $timeout.cancel(recaptchaTimer);

    const checkRecaptchaIsNotReady = () =>
      !window.grecaptcha ||
      !window.grecaptcha?.render ||
      !window.grecaptcha?.reset;

    const waitRecaptchaLoaded = (callback) =>
      window.globalSDKObserver?.grecaptcha?.subscribe?.((sdk) => {
        callback(sdk);
      });

    const resetWithWidgetId = (widgetId) => {
      if (typeof grecaptcha !== 'undefined' && widgetId) {
        grecaptcha.reset(widgetId);
      }
    };

    const renderInvisibleRecaptchaWidget = ({
      element,
      callback,
      clickCallback,
      errorCallback,
      expiredCallback,
      timeoutCallback,
    }) => {
      let widgetId = null;

      const handleCallback = (token) => {
        cancelRecaptchaTimer();
        document.getElementById('g-recaptcha-response').value = token;
        callback?.();
        // reset recaptcha widget
        $timeout(() => {
          resetWithWidgetId(widgetId);
        });
      };
      // triggered by incorrect site key or secret key, causing rendering failure
      const handleErrorCallback = () => {
        cancelRecaptchaTimer();
        resetWithWidgetId(widgetId);
        logger.error('failed to render recaptcha', {
          grecaptha: window.grecaptcha,
          // eslint-disable-next-line compat/compat
          network: window.navigator.connection?.effectiveType,
        });
        errorCallback?.();
      };
      // triggered by token expiration.
      const handleExpiredCallback = () => {
        resetWithWidgetId(widgetId);
        logger.error('the recaptcha token is expired', {
          grecaptha: window.grecaptcha,
          token: document.getElementById('g-recaptcha-response').value,
        });
        expiredCallback?.();
      };
      // manually check if the obtained token has expired.
      const handleClickCallback = () => {
        recaptchaTimer = $timeout(() => {
          logger.error('failed to get recaptcha token', {
            grecaptha: window.grecaptcha,
            // eslint-disable-next-line compat/compat
            network: window.navigator.connection?.effectiveType,
          });
          timeoutCallback?.();
        }, TIMEOUT);
        clickCallback?.();
      };

      widgetId = window.grecaptcha.render(element, {
        badge: 'none',
        callback: handleCallback,
        'error-callback': handleErrorCallback,
        'expired-callback': handleExpiredCallback,
        sitekey: mainConfig.recaptchaSiteKey,
        size: 'invisible',
      });

      $(element).on('click', handleClickCallback);
    };

    return {
      reset,
      execute,
      executeWithPromise,
      cancelRecaptchaTimer,
      checkRecaptchaIsNotReady,
      resetWithWidgetId,
      renderInvisibleRecaptchaWidget,
      waitRecaptchaLoaded,
    };
  },
]);
