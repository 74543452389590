app.controller('ProductsShowController', [
  '$scope',
  'mainConfig',
  '$lightbox',
  'product',
  'cartService',
  'productService',
  '$filter',
  'Analytics',
  'products',
  'trackerService',
  'backInStockService',
  '$window',
  '$timeout',
  '$element',
  'imageService',
  'imageServiceEndpoint',
  '$anchorScroll',
  '$location',
  'featureService',
  'slPixelService',
  'slFeatureService',
  '$rootScope',
  'buyNowService',
  'productReviewCommentService',
  'hiidoTrackerService',
  'fastCheckoutService',
  'variantId',
  'productSetService',
  '$cookies',
  'productSetCacheService',
  'productPreorderService',
  'productStockService',
  function (
    $scope,
    mainConfig,
    $lightbox,
    product,
    cartService,
    productService,
    $filter,
    Analytics,
    products,
    trackerService,
    backInStockService,
    $window,
    $timeout,
    $element,
    imageService,
    imageServiceEndpoint,
    $anchorScroll,
    $location,
    featureService,
    slPixelService,
    slFeatureService,
    $rootScope,
    buyNowService,
    productReviewCommentService,
    hiidoTrackerService,
    fastCheckoutService,
    variantId,
    productSetService,
    $cookies,
    productSetCacheService,
    productPreorderService,
    productStockService,
  ) {
    var currentThemeKey = mainConfig.merchantData.current_theme_key;

    $scope.scrollToProductReviews = function () {
      switch (currentThemeKey) {
        case 'kingsman_v2':
        case 'skya':
          kingsmanScroll();
          break;
        case 'ultra_chic':
        case 'philia':
        case 'varm':
        case 'ferveur':
          ultraChicScroll();
          break;
        case 'bianco':
          biancoScroll();
          break;
        case 'sangria':
          sangriaScroll();
          break;
        default:
          defaultScroll();
      }
    };

    $scope.productReviewEnabled =
      productReviewCommentService.productReviewEnabled;
    $scope.amazonProductReviewEnabled =
      productReviewCommentService.amazonProductReviewEnabled;

    function isSpbEnabled() {
      var blacklistPaymentIds = $scope.product
        ? $scope.product.blacklisted_payment_ids
        : [];
      var currentPaypalPaymentId = $('#paypal-payment-id').val();
      return (
        slFeatureService.hasFeature('paypal_spb_checkout') &&
        $scope.hasStock &&
        $scope.product &&
        !$scope.product.hide_price &&
        currentPaypalPaymentId &&
        !blacklistPaymentIds.includes(currentPaypalPaymentId) &&
        $('.available-time-disabled').length <= 0
      );
    }
    function shouldShowSpbModal() {
      if (!isSpbEnabled()) return;
      if (
        currentThemeKey === 'bianco' &&
        window.matchMedia('(max-width: 991px)').matches
      ) {
        return true;
      }
    }

    function sangriaScroll() {
      var productReviewSection = $('#productCustomerReviews');
      if (productReviewSection.length) {
        $('html, body').animate(
          {
            scrollTop: productReviewSection.offset().top - 148,
          },
          'slow',
        );
      }
    }

    function biancoScroll() {
      var productReviewTab = $('a[href="#product-review-section');
      if (productReviewTab.length) {
        productReviewTab.click();
        $('html, body').animate(
          {
            scrollTop: productReviewTab.offset().top,
          },
          'slow',
        );
      }
    }

    function ultraChicScroll() {
      var productReviewTabId = document.getElementById('product-review-section')
        .parentElement.id;
      if (productReviewTabId) {
        document
          .querySelector("div[href='#" + productReviewTabId + "']")
          .click();
      }
    }

    function kingsmanScroll() {
      var productReviewElement = document.getElementById(
        'product-review-section',
      );
      if (!productReviewElement) {
        return;
      }
      if (productReviewElement.className.indexOf('active') == -1) {
        productReviewElement.firstElementChild.click();
      }
      defaultScroll(100);
    }

    function defaultScroll(yOffset) {
      if ($location.hash() !== 'product-review-section') {
        $location.hash('product-review-section');
      } else {
        $anchorScroll.yOffset = yOffset || 0;
        $anchorScroll();
      }
    }

    initRatingSource();
    function initRatingSource() {
      if ($scope.productReviewEnabled) {
        $scope.ratingSource = 'shopline';
      } else if ($scope.amazonProductReviewEnabled) {
        $scope.ratingSource = 'amazon';
      }
    }

    $scope.setRatingSource = function (ratingSource) {
      $scope.ratingSource = ratingSource;
    };

    $scope.shouldShowNoReviewsMsg = function () {
      return (
        ($scope.ratingSource == 'shopline' && !$scope.shoplineProductReview) ||
        ($scope.ratingSource == 'amazon' && !$scope.amazonProductReview)
      );
    };

    $scope.shouldShowMessageMeBtn = function () {
      return (
        $scope.productStockInitializedChecked &&
        (!$scope.state.checkoutReady ||
          !$scope.hasStock ||
          $scope.product.hide_price) &&
        !backInStockService.shouldShowBtn(
          $scope.product,
          $scope.variationSelected,
        )
      );
    };

    function getImageSrcFromCssBackground(background) {
      return background.replace(/^url\(["'](.+)["']\)$/, '$1');
    }

    function observeVariationChange($prodMobile) {
      var gallery = document.querySelector('.variant-gallery-slider');
      var observer = new MutationObserver(function () {
        $prodMobile.empty().owlCarousel('destroy');
        galleryImage($prodMobile);
      });

      observer.observe(gallery, { childList: true });
    }

    var galleryImageLoaded = false;
    function galleryImage($prodMobile) {
      $('product-variant-gallery').show();
      const carouselConfig = {
        loop: $('.js-variant-image .image-container').length > 1,
        items: 1,
        margin: 10,
        nav: ['varm', 'philia', 'skya'].includes(currentThemeKey),
        autoplay: false,
        autoHeight: true,
        autoplayHoverPause: false,
        onInitialized: function () {
          $('product-variant-gallery').hide();
        },
      };

      function roundToTwo(number) {
        return Math.round(number * 100) / 100;
      }

      function setCarouselMinHeight(height) {
        const carousel = $('.js-prod-mobile');
        carousel.attr(
          'style',
          `height: ${height}px; display: block; overflow: hidden;`,
        );
      }

      const ratioList = [];
      function onImageLoad(
        isFirst,
        variantImageCount,
        imageWidth,
        imageHeight,
      ) {
        const heightRatio = roundToTwo(imageHeight / imageWidth);
        ratioList.push(heightRatio);

        if (isFirst) $prodMobile.owlCarousel(carouselConfig);
        if (ratioList.length > 1 && ratioList.length === variantImageCount) {
          const uniqueRatioSet = new Set(ratioList);
          if (uniqueRatioSet.size === 1) {
            setCarouselMinHeight(imageHeight);
          }
        }
      }
      const variantImageContainers = $('.js-variant-image .image-container');
      variantImageContainers.each(function (i) {
        var backgroundImage = $(this).css('background-image');
        var imgUrl = getImageSrcFromCssBackground(backgroundImage);
        var imgAlt = $(this).attr('alt');
        var $img = $(
          `<div class="prod-mobile-variant-image-container">
            <img
              class="prod-mobile-variant-image"
              src="${imageService.getLargeImage(imgUrl)}"
              alt="${imgAlt}"
              fetchpriority="${i === 0 ? 'high' : 'auto'}"
            >
          </div>`,
        );
        const isFirst = i === 0;
        const variantImageCount = variantImageContainers.length;
        $img.find('img').on('load', function () {
          onImageLoad(isFirst, variantImageCount, this.width, this.height);
        });

        $prodMobile.append($img);
      });
    }
    function handleGalleryImage() {
      if (galleryImageLoaded) {
        return;
      } else {
        galleryImageLoaded = true;
      }
      var $prodMobile = $('.js-prod-mobile');
      $timeout(function () {
        galleryImage($prodMobile);

        $prodMobile.addClass('owl-carousel owl-theme');

        if (['varm', 'philia', 'kingsman_v2'].includes(currentThemeKey)) {
          $prodMobile.on('changed.owl.carousel', function (e) {
            var currentItemIndex = e.page.index === -1 ? 1 : e.page.index + 1;
            var currentItemCount = e.item.count;
            $prodMobile.attr(
              'data-slide',
              currentItemIndex + ' / ' + currentItemCount,
            );
          });
        }

        observeVariationChange($prodMobile);
      });
    }

    $rootScope.$on('productGallery.load', function () {
      var themes;
      if (window.matchMedia('(max-width: 480px)').matches) {
        themes = [
          'ultra_chic',
          'bianco',
          'doris_bien',
          'varm',
          'philia',
          'skya',
          'sangria',
          'ferveur',
          'kingsman_v2',
          'dusk',
        ];
        if (themes.includes(currentThemeKey)) handleGalleryImage();
      } else if (window.matchMedia('(max-width: 990px)').matches) {
        themes = ['bianco', 'doris_bien', 'sangria'];
        if (themes.includes(currentThemeKey)) handleGalleryImage();
      }
    });

    // product set
    if (productSetService.isProductSetEnabled(product)) {
      $scope.selectedProductSetData = [];
      $scope.$on('product_set.child_product.changed', function (
        event,
        payload,
      ) {
        $scope.hasStock = payload.hasStock;
        $scope.selectedProductSetData = payload.selectedProductSetData;
        if (
          mainConfig.merchantData.enabled_stock_reminder &&
          !$scope.product.unlimited_quantity
        ) {
          productService
            .checkProductSetStock({
              id: $scope.product._id,
              productSetData: productSetService.generateProductSetParams(
                $scope.selectedProductSetData,
              ),
            })
            .then(function (res) {
              var data = res.data;
              var result = cartService.checkStockResult(0, data);
              $scope.quantityOfStock = result.quantityOfStock;
              $scope.orderQuantityStatus = result.orderQuantityStatus;
            });
        }
      });
    }

    if (productSetService.isProductSetRevampEnabled(product)) {
      $scope.$on('addProductSetBasketToCart', () => {
        $scope.addItemToCart();
      });
    }

    $scope.product = product;
    $scope.products = products;
    $scope.variantId = variantId;
    $scope.product.digest_variations = [];
    $scope.loading = false;
    $scope.addItemQuantity = 1;
    $scope.variationSelected = null;
    $scope.state = {
      addonItemEnabled: false,
      checkoutReady: false,
      productSetCheckoutReady: false,
      showStickyButton: !productSetService.isProductSetRevampEnabled(
        $scope.product,
      ),
    };
    $scope.reachedPurchaseLimit = false;
    /** flag: Product inventory initialization check */
    $scope.productStockInitializedChecked = false;

    $scope.incQuantity = function () {
      if (parseInt($scope.addItemQuantity, 10) < 99999) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) + 1;
      }
    };
    $scope.decQuantity = function () {
      if (parseInt($scope.addItemQuantity, 10) > 1) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) - 1;
      }
    };
    $scope.productService = productService;
    $scope.productSetService = productSetService;
    $scope.showOutOfStockHint = function () {
      if ($scope.product.type === 'product_set') {
        return !productSetService.checkMinQuantity(
          $scope.product.child_products,
        );
      }
      const productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      return productStockService.isOutOfStock(productStockInfo);
    };
    $scope.showPreorderLabel = function () {
      if (!$scope.hasStock) {
        return false;
      }
      const productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      return productPreorderService.isPreorderProduct(
        productStockInfo,
        $scope.addItemQuantity,
      );
    };
    $scope.getAddToCartButtonText = function (
      isBuyNowButtonEnabled,
      availableType = '',
    ) {
      const productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      return productPreorderService.getAddToCartButtonText({
        productStockInfo,
        addItemQuantity: $scope.addItemQuantity,
        isBuyNowButtonEnabled,
        availableType,
      });
    };
    function initStockStatus() {
      if ($scope.product.type === 'product_set') {
        // total_orderable_quantity including preorder quantity
        $scope.product.quantity = $scope.product.total_orderable_quantity;
        $scope.productStockInitializedChecked = true;
        return;
      }
      const variation_id = $scope.variationSelected
        ? $scope.variationSelected.key
        : '';
      productStockService
        .checkStockPromise({
          product: $scope.product,
          variationId: variation_id,
          selectedProductSetData: $scope.selectedProductSetData,
        })
        .then(function ({ data }) {
          const result = cartService.checkStockResult(0, data);
          $scope.quantityOfStock = result.quantityOfStock;

          if ($scope.variationSelected) {
            $scope.variationSelected.cart_quantity = data.cart_quantity;
            $scope.variationSelected.quantity = data.quantity;
            $scope.variationSelected.preorder_limit = data.preorder_limit;
            return;
          }
          $scope.product.cart_quantity = data.cart_quantity;
          $scope.product.quantity = data.quantity;
          $scope.product.preorder_limit = data.preorder_limit;
        })
        .finally(() => {
          $scope.productStockInitializedChecked = true;
        });
    }

    $scope.addItemToCart = function (event, isBuyNow, isSPBFastCheckout) {
      const vm = this;

      const regex = /^[1-9]\d{0,4}$/;
      $scope.invalidQuantity = !regex.test($scope.addItemQuantity);

      if ($scope.invalidQuantity) {
        $rootScope.$broadcast('cartItemUpdateFailed');
        return;
      }
      $scope.loading = true;

      const variation_id = $scope.variationSelected
        ? $scope.variationSelected.key
        : '';

      // Turn inputbox value (ng-model=addItemQuantity) String to Number.
      vm.addItemQuantity = parseInt($scope.addItemQuantity, 10);

      const isFastCheckoutCart =
        (isBuyNow && fastCheckoutService.isEnableEc) ||
        (isSPBFastCheckout && fastCheckoutService.isSpbEnabled);

      productStockService
        .checkStockPromise({
          product: $scope.product,
          variationId: variation_id,
          selectedProductSetData: $scope.selectedProductSetData,
          isFastCheckoutCart,
        })
        .then(function ({ data }) {
          $scope.loading = false;
          $scope.product.max_order_quantity = data.max_order_quantity;

          const checkArgs = [vm.addItemQuantity, data, isFastCheckoutCart];
          const result =
            $scope.product.type === 'product_set'
              ? cartService.checkStockResultWithProductSet(...checkArgs)
              : cartService.checkStockResult(...checkArgs);

          const isOutOfStockOrderable = productService.isOutOfStockOrderable(
            $scope.product,
          );
          $scope.reachedPurchaseLimit = result.reachedPurchaseLimit;
          $scope.notEnoughStockQty = result.notEnoughStockQty;
          $scope.quantityOfStock = result.quantityOfStock;
          $scope.orderQuantityStatus = result.orderQuantityStatus;

          if ($scope.variationSelected) {
            $scope.variationSelected.cart_quantity = data.cart_quantity;
          } else {
            $scope.product.cart_quantity = data.cart_quantity;
          }

          // should not add item to cart
          if (
            (result.notEnoughStockQty >= 0 && !isOutOfStockOrderable) ||
            $scope.reachedPurchaseLimit
          ) {
            $rootScope.$broadcast('cartItemUpdateFailed');
            $scope.loading = false;
            return;
          }

          var cartItemData = {
            quantity: vm.addItemQuantity,
            variation: $scope.variationSelected,
            blacklisted_payment_option_ids:
              $scope.product.blacklisted_payment_option_ids,
            blacklisted_delivery_option_ids:
              $scope.product.blacklisted_delivery_option_ids,
          };

          if (productSetService.isProductSetEnabled($scope.product)) {
            cartItemData.type = 'product_set';
            cartItemData.productSetData = productSetService.generateProductSetParams(
              $scope.selectedProductSetData,
            );
          }

          if (productSetService.isProductSetRevampEnabled($scope.product)) {
            cartItemData.type = 'product_set';
            cartItemData.productSetData = $scope.selectedProductSetData;
          }

          if ($scope.product.subscription_enabled) {
            cartItemData['type'] = 'subscription_product';
          }
          //add item to cart
          if (isBuyNow) {
            cartItemData.isFastCheckoutCart = fastCheckoutService.isEnableEc;
          }
          if (isSPBFastCheckout) {
            cartItemData.isFastCheckoutCart = fastCheckoutService.isSpbEnabled;
          }

          var value =
            vm.addItemQuantity *
            cartService.getItemPrice({
              product: product,
              variation: $scope.variationSelected,
            }).dollars;
          cartItemData.value = value;

          cartService
            .addItem($scope.product._id, cartItemData)
            .then(function (data) {
              if (productSetService.isProductSetRevampEnabled($scope.product)) {
                value =
                  vm.addItemQuantity *
                  (data.itemAfterAdded?.price?.dollars || 0);

                productSetCacheService.clearProductCache($scope.product._id);
              }

              if (slFeatureService.hasFeature('data_layer_info')) {
                $rootScope.$emit(
                  'add.item.to.cart',
                  cartService.getGaItemData({
                    product: $scope.product,
                    variant: $scope.variationSelected,
                  }),
                );
              }

              if ($scope.variationSelected) {
                $scope.variationSelected.cart_quantity =
                  $scope.variationSelected.cart_quantity +
                  $scope.addItemQuantity;
              } else {
                $scope.product.cart_quantity =
                  $scope.product.cart_quantity + $scope.addItemQuantity;
              }

              trackerService.track({
                type: trackerService.generalEventType.ADD_TO_CART,
                data: {
                  items: [
                    {
                      product: $scope.product,
                      value,
                      quantity: $scope.addItemQuantity,
                      variationSelected: $scope.variationSelected,
                    },
                  ],
                  eventId: data.event_id,
                },
              });

              if (isBuyNow) {
                $rootScope.$broadcast('clicked_buy_now_button');
              } else if (
                shouldShowSpbModal() ||
                buyNowService.shouldShowBuyNowModal()
              ) {
                buyNowService.openModal();
              } else if (isSPBFastCheckout) {
                // do nothing, avoid to open cart panel
              } else {
                jQuery.sidr('open', 'cart-panel');
                if (mainConfig.merchantData.current_theme_key === 'sangria') {
                  //show mobile cart when add item to cart for sangria theme
                  $('.js-navbar-mobile .icon-bag-mobile').click();
                }
              }

              if (
                productSetService.isProductSetRevampEnabled($scope.product) &&
                $cookies.get('productSetFromPromotionPage') ===
                  $scope.product._id &&
                $cookies.get('productSetFromPromotionPageId')
              ) {
                window.location = `/promotions/${$cookies.get(
                  'productSetFromPromotionPageId',
                )}`;
                $cookies.remove('productSetFromPromotionPage');
                $cookies.remove('productSetFromPromotionPageId');
              }
              $scope.loading = false;
            });
        }); // End of productService.checkStock
    };

    $scope.$on('biancoModalFastCheckout', function () {
      $scope.addItemToCart(undefined, undefined, true);
    });
    function getVariationId() {
      if ($scope.product.variations.length === 0) {
        return '';
      }

      return $scope.variationSelected
        ? $scope.variationSelected.key
        : $scope.product.variations[0].key;
    }

    function updateHasStock() {
      $scope.productStockInitializedChecked = true;
      if ($scope.product.type === 'product_set') {
        $scope.hasStock = productSetService.checkMinQuantity(
          $scope.product.child_products,
        );
        return;
      }
      $scope.hasStock = productStockService.checkHasStock(
        $scope.product,
        $scope.variationSelected,
      );
    }

    // watch after product-variation-dropdown loaded
    $timeout(function () {
      // Skip init stock status when hide price toggle on.
      if ($scope.product.hide_price) {
        // Select in stock variant because scope.variantId will be empty when hide_price toggle on.
        $scope.variationSelected = productStockService.getHasStockVariant(
          $scope.product,
        );
        updateHasStock();
        return;
      }
      initStockStatus();
      $scope.$watch('variationSelected', function checkStockQuantity() {
        updateHasStock();
        if (
          !$scope.product.unlimited_quantity &&
          $scope.product.type !== 'product_set'
        ) {
          const variation_id = getVariationId();

          $scope.orderQuantityStatus =
            cartService.QUANTITY_VERIFY_STATUS.IS_AVAILABLE;

          productService
            .checkStock($scope.product._id, variation_id)
            .then(function (res) {
              // check if variation id changed after request
              if (!variation_id || variation_id !== getVariationId()) {
                return;
              }

              const data = res.data;
              const result = cartService.checkStockResult(0, data);
              $scope.quantityOfStock = result.quantityOfStock;
              $scope.orderQuantityStatus = result.orderQuantityStatus;
              if ($scope.variationSelected) {
                $scope.variationSelected.cart_quantity = data.cart_quantity;
                $scope.variationSelected.quantity = data.quantity;
                $scope.variationSelected.preorder_limit = data.preorder_limit;
              }
            });
        }
      });
    });

    $scope.isDefined = angular.isDefined;

    $scope.isEmpty = function (obj) {
      // eslint-disable-next-line no-prototype-builtins
      for (var i in obj) if (obj.hasOwnProperty(i)) return false;
      return true;
    };

    // var productPrice = cartService.getItemPrice({product: $scope.product});
    // $scope.state.checkoutReady = (mainConfig.merchantData.payment_method_count >0) && (mainConfig.merchantData.delivery_option_count >0) && (productPrice != null && productPrice.cents >= 0) && ($scope.product.hide_price != true);
    $scope.state.checkoutReady = productService.validateCheckoutReady(
      $scope.product,
      $scope.variationSelected,
      cartService.getItemPrice({ product: $scope.product }),
    );

    var sendSlpixelTracking = function (product) {
      var price = product.price ? product.price.dollars : 0;
      var salePrice = product.price_sale ? product.price_sale.dollars : 0;
      var memberPrice = product.member_price ? product.member_price.dollars : 0;
      var currency =
        product.price && product.price.currency_iso
          ? product.price.currency_iso
          : null;
      var trackingProductParams = {
        productID: product._id,
        name: $filter('translateModel')(product.title_translations),
        price: price,
        currency: currency,
        salePrice: salePrice,
        memberPrice: memberPrice,
        variationID: null,
      };

      slPixelService.hdPageView('productDetail', {
        product: trackingProductParams,
        inStock: $scope.hasStock,
      });
    };

    if (
      !$scope.product.variations ||
      $scope.product.variations.length < 1 ||
      $scope.product.hide_price
    ) {
      trackerService.gaSendDetails($scope.product, null, $scope.products);
      $scope.products = null; //clear the related products as they are only for impressions
      var price = cartService.getItemPrice({ product: $scope.product });
      var dollars = _.isObject(price) ? price.dollars : 0;
      trackerService.fbSendViewContent($scope.product, dollars);
      trackerService.track({
        type: trackerService.generalEventType.VIEW_CONTENT,
        data: {
          product: $scope.product,
          value: dollars,
        },
      });
      sendSlpixelTracking(product);
    }

    // Notify when product is loaded (and angualr is ready)
    $timeout(function () {
      $scope.$emit('product.show.loaded');
    }, 100);

    slPixelService.hdTracking('productImpression', null, {
      products: products.map(function (product, index) {
        return {
          position: index,
          productId: product.id,
          name: $filter('translateModel')(product.title_translations),
        };
      }),
    });

    if (slFeatureService.hasFeature('shoplytics_to_hd')) {
      trackerService.pageView({
        data: {
          page_type: 'product_detail',
        },
      });
    } else {
      trackerService.pageView();
    }

    // hiido tracking product detail pageview
    hiidoTrackerService.pdp.pageView({ key16: product._id });

    const sendViewRelatedProducts = (relatedProduct) => {
      trackerService.track('ViewRelatedProducts', {
        product_infos: [
          {
            id: relatedProduct.id,
            status: relatedProduct.sold_out ? 'out of stock' : 'in stock',
            rank: products.indexOf(relatedProduct) + 1,
            based_product_id: product._id,
            ...(relatedProduct.rec_strategy && {
              rec_strategy: relatedProduct.rec_strategy,
            }),
          },
        ],
      });
    };

    const relatedProductElements = document.querySelectorAll(
      '.ProductDetail-relatedProducts-list .Product-item, .ProductDetail-relatedProducts-list product-item',
    );

    const setRelatedProductsIntersectionObserver = (relatedProductElement) => {
      // eslint-disable-next-line compat/compat
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries?.[0]?.isIntersecting) {
            const id = entries[0].target.getAttribute('product-id');
            sendViewRelatedProducts(
              products.find((product) => product.id === id),
            );
            intersectionObserver.disconnect();
          }
        },
        {
          threshold: 0.5,
        },
      );
      intersectionObserver.observe(relatedProductElement);
    };

    relatedProductElements.forEach((element) =>
      setRelatedProductsIntersectionObserver(element),
    );

    if (productSetService.isProductSetRevampEnabled(product)) {
      $scope.selectedProductSetData = [];
      $scope.state.productSetCheckoutReady = false;
      $scope.hasStock = productSetService.checkMinQuantity(
        $scope.product.child_products,
      );
    } else {
      $scope.state.productSetCheckoutReady = true;
    }

    $scope.showStockQuantityHint = function () {
      return productStockService.showStockQuantityHint({
        product: $scope.product,
        variationSelected: $scope.variationSelected,
        checkoutReady: $scope.state.checkoutReady,
        quantityOfStock: $scope.quantityOfStock,
      });
    };
  },
]);
